import { useCallback, useEffect, useState } from 'react';
import {
	activationNotificationJson,
	bottomNotificationText,
	getActivationPopupContent
} from '../../service/lobbyService';
import PopupDrawer from '../PopupDrawer';
import './BottomNotification.scss';
import DOMPurify from 'dompurify';

type PopupData = {
	Columns: {
		ColumnCopy: string;
	}[];
};
const BottomNotification = () => {
	const [showModal, setShowModal] = useState(false);
	const [subDesc, setSubDesc] = useState('');
	const handleClick = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleNegativeButton = useCallback(() => {
		setShowModal(false);
	}, []);

	const getPopupContent = async () => {
		const res: PopupData = await getActivationPopupContent();
		setSubDesc(res?.Columns[0]?.ColumnCopy);
	};

	useEffect(() => {
		getPopupContent();
	}, []);

	return (
		<>
			<div className="bottom-notification" data-testid="bottom-notification" onClick={handleClick}>
				<span className="icon-user-note label-medium">{bottomNotificationText}</span>
				<i className="icon-arrow-head"></i>
			</div>
			{showModal && (
				<PopupDrawer
					errorImage={
						<i
							className={`icon-modal-view `}
							style={{ backgroundImage: `url(${activationNotificationJson.icon})` }}
						/>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">
							{activationNotificationJson.desc_heading}
						</h2>
					}
					data={activationNotificationJson}
					subDesc={
						<div
							className="popup-description-text body-text-medium text-left"
							dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subDesc) }}
						></div>
					}
					handleNegativeButton={handleNegativeButton}
					handlePositiveButton={handleNegativeButton}
					closeOnOverlay={true}
				/>
			)}
		</>
	);
};

export default BottomNotification;
