import './Grid.scss';
import { FC } from 'react';
import IconButton from '../IconButton/IconButton';
import { ILink, IRedirection, LoaderType } from '../../types';
import withSkeltonLoader from '../../hoc/SkeltonLoader';

interface IGrid extends IRedirection {
	variant?: 'secondary';
	icons?: string[];
}
const Grid: FC<IGrid> = ({ links, variant, icons }) => {
	return (
		<div className={`grid ${variant}`} data-testid="button-grid">
			{links?.map((details: ILink | any, index: number) => {
				const k = `icon-button-${index}`;
				const icondetails =
					details?.CTAButtonButtonCopy && icons
						? {
								label: details.CTAButtonButtonCopy,
								icon: icons[index],
								external: true,
								to: details.CTAButtonButtonLink
						  }
						: details;
				return <IconButton className={`grid-item ${variant}`} key={k} {...icondetails} />;
			})}
		</div>
	);
};
export default withSkeltonLoader(Grid, LoaderType.grid);
