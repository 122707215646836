import { HTMLProps } from 'react';
import './InputButton.scss';

interface IInputButton extends HTMLProps<HTMLButtonElement> {
	varient?: 'primary' | 'secondary';
	datatestid?: string;
	type?: 'button' | 'submit' | 'reset';
}
const InputButton = ({
	children,
	onClick,
	varient,
	disabled,
	datatestid,
	className = '',
	type = 'submit'
}: IInputButton) => {
	const getClassName = () => {
		if (varient === 'primary') {
			return 'input-btn_primary';
		} else {
			return 'input-btn_secondary';
		}
	};

	return (
		<button
			data-testid={datatestid}
			type={type}
			className={`label-large input-btn ${getClassName()} ${className}`}
			onClick={onClick}
			disabled={disabled}
		>
			{children}
		</button>
	);
};

export default InputButton;
