import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from '../../hooks';
import './index.scss';

const CountdownTimer = ({ targetDate, openingDetails }: any) => {
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	if (days + hours + minutes + seconds <= 0) {
		return <></>;
	} else {
		return (
			<div className="show-counter">
				<img
					className="opening-img"
					src={openingDetails.SlideImage.FullURL}
					alt={openingDetails.SlideImage.FullURL}
					data-testid="testid-tier-image"
				/>
				<div className="timer-container">
					<div className="timer">
						<DateTimeDisplay value={days} type={'day'} isDanger={days <= 3} />
						<p className="seperator">:</p>
						<DateTimeDisplay value={hours} type={'hrs'} isDanger={false} />
						<p className="seperator">:</p>
						<DateTimeDisplay value={minutes} type={'min'} isDanger={false} />
						<p className="seperator">:</p>
						<DateTimeDisplay value={seconds} type={'sec'} isDanger={false} />
					</div>
					<div className="timer-desc">
						<p className="timer-heading title-large">{openingDetails.SlideHeading}</p>
						<p className="timer-text label-small">{openingDetails.SlideContent}</p>
					</div>
				</div>
			</div>
		);
	}
};

export default CountdownTimer;
