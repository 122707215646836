import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	BiometricStatus,
	CardLessConnectionType,
	CardlessConnectionStatus,
	FaceIdConnectionType,
	FaceIdPayloadType,
	IChildrenHoc,
	Ipopup
} from '../../types';
import { getCookieValue, logoutSession, saveRefreshToken, setSessionItem } from '../../hooks';
import { PLATFORM_COOKIE, ROUTE_CONSTANTS, TOKENS } from '../../constants';
import { RNContext } from '../../context/GlobalContext';
import { useHistory } from 'react-router-dom';
import PopupDrawer from '../../components/PopupDrawer';
import { loginVaribages } from '../../service/loginService';
const {
	REACT_APP_X_API_KEY,
	REACT_APP_NATIVE_LAUNCH_DARKLY_KEY,
	REACT_APP_USER_SERVICE_BASE_URL,
	REACT_APP_UPLOAD_API_KEY,
	REACT_APP_UPLOAD_BASE_URL
} = process.env;
declare const window: any;

const NativeCommChannel = ({ children }: IChildrenHoc) => {
	const { verifyOtp, pinVerification } = loginVaribages;
	const [showModal, setShowModal] = useState<boolean>(false);
	const [errorPopupData, setErrorPopupData] = useState<Ipopup>({
		positiveButtonText: '',
		description: '',
		positiveCallback: () => {
			//
		}
	});

	const postMessagetoNative = (type: FaceIdConnectionType | CardLessConnectionType, data?: any) => {
		let payload: FaceIdPayloadType = {
			type
		};
		if (data) {
			payload = {
				...payload,
				data
			};
		}
		window?.ReactNativeWebView?.postMessage(JSON.stringify(payload));
		console.log('payload sent to Native ', JSON.stringify(payload));
	};

	const [faceIdStatus, setFaceIdStatus] = useState<BiometricStatus>({
		isFaceIdAvailable: false,
		isFaceIdSetup: false,
		globalId: '',
		postMessagetoNative
	});

	const [connectionStatus, setConnectionStatus] = useState<CardlessConnectionStatus>({
		connectionStatus: 'SCANNING'
	});

	const history = useHistory();

	useEffect(() => {
		const currPlatform = getCookieValue(PLATFORM_COOKIE).toLowerCase();
		let target: any = window;
		if (currPlatform === 'android') {
			target = document;
		}
		target.addEventListener('message', handleRNCommunication);

		//SENDING CONFIG TO NATIVE
		const defaultConfig = {
			apiKey: REACT_APP_X_API_KEY,
			launchDarklyKey: REACT_APP_NATIVE_LAUNCH_DARKLY_KEY,
			uploadApiKey: REACT_APP_UPLOAD_API_KEY,
			baseUrl: REACT_APP_USER_SERVICE_BASE_URL,
			uploadBaseUrl: REACT_APP_UPLOAD_BASE_URL
		};
		console.log('Posting config to native', defaultConfig);
		postMessagetoNative('INIT_CONFIG', defaultConfig);
		postMessagetoNative('CHECK_FACEID_STATUS');
		postMessagetoNative('CHECK_CONNECTION_STATUS');

		return () => target.removeEventListener('message', handleRNCommunication);
	}, []);

	const handlePositiveButton = useCallback(async () => {
		// User excluded/blocked > popup closed
		await logoutSession();
		setShowModal(false);
		history.push(ROUTE_CONSTANTS.LOBBY);
	}, [history]);

	const handlePositiveLoginButton = useCallback(async () => {
		console.log('User excluded from scan API!');
		await logoutSession();
		setShowModal(false);
		history.push(ROUTE_CONSTANTS.LOGIN_CONGNITO.LANDING);
	}, [history]);

	const handleError = (errorMessage: any, isNavigateToLogin = false) => {
		setErrorPopupData({
			...errorMessage,
			negativeCallback: isNavigateToLogin ? handlePositiveLoginButton : handlePositiveButton,
			positiveCallback: isNavigateToLogin ? handlePositiveLoginButton : handlePositiveButton
		});
		setShowModal(true);
	};

	const handleFaceIdLogin = (data: any) => {
		if (data?.AuthenticationResult) {
			setSessionItem(TOKENS.ACCESS, data?.AuthenticationResult?.AccessToken);
			setSessionItem(TOKENS.ID, data?.AuthenticationResult?.IdToken);
			setSessionItem(TOKENS.USER_STATUS, 'RegisteredUser');
			saveRefreshToken(data?.AuthenticationResult?.RefreshToken);
			history.replace(ROUTE_CONSTANTS.LOBBY);
		} else if (data?.status == 403) {
			//User Excluded
			handleError(verifyOtp.exclusionPopup);
		} else if (data?.status == 406) {
			//Account blocked
			handleError(pinVerification.errorPopup);
		}
	};

	// Handle postMessage between Native and PWA
	const handleRNCommunication = async (evntMsg: any) => {
		try {
			if (typeof evntMsg?.data == 'string') {
				const message = JSON.parse(evntMsg.data);
				const { type, data } = message as {
					type: FaceIdConnectionType | CardLessConnectionType;
					data: any;
				};
				console.log('Message recieved ', type, data);

				switch (type) {
					case 'CONNECTION_STATUS':
						console.log('TYPE', type, data);
						setConnectionStatus({ connectionStatus: data });
						break;
					case 'FACEID_STATUS':
						setFaceIdStatus({
							...faceIdStatus,
							isFaceIdAvailable: data?.isFaceIdAvailable,
							isFaceIdSetup: data?.isFaceIdSetup,
							globalId: data?.globalId ?? ''
						});
						break;

					case 'FACEID_REGISTRATION_STATUS':
						if (!data?.isSuccess) {
							console.log('Face id registration failed');
						}
						break;

					case 'FACEID_LOGIN_RESPONSE':
						handleFaceIdLogin(data);
						break;

					case 'DUPLICATE_ACCOUNT':
						// If an EXCLUDED user from SCAN API
						if (data?.errorType) {
							console.log('Response from Scan API: ', data);
							handleError(verifyOtp.exclusionPopup, true);
						}
						break;
					default:
						break;
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const setConnectionValue = (value: any) => {
		setConnectionStatus({ connectionStatus: value });
	};
	const value = useMemo(() => {
		return {
			...faceIdStatus,
			...connectionStatus,
			setConnectionValue
		};
	}, [faceIdStatus, connectionStatus]);

	return (
		<RNContext.Provider value={value}>
			{children}
			{showModal && (
				<PopupDrawer
					errorImage={
						<div className="error-icon-box">
							<i
								className={`icon-modal-view`}
								style={{ backgroundImage: `url(${errorPopupData?.icon})` }}
							/>
						</div>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">{errorPopupData?.desc_heading}</h2>
					}
					subDesc={
						errorPopupData?.sub_desc && (
							<div className="contact-container">
								<div className="contact-info">
									<i className="icon-phone" />
									<p className="contact-desc body-text-medium">
										{errorPopupData.sub_desc?.phone_no}
									</p>
								</div>
								<div className="contact-info">
									<i className="icon-location" />
									<p className="body-text-medium">{errorPopupData.sub_desc?.loc}</p>
								</div>
							</div>
						)
					}
					handleNegativeButton={errorPopupData.negativeCallback}
					handlePositiveButton={errorPopupData.positiveCallback}
					data={errorPopupData}
				/>
			)}
		</RNContext.Provider>
	);
};

export default NativeCommChannel;
