import Radio from '../../../components/Radio/Radio';
import './index.scss';
export const RenderQuestionAndOption = (props: any) => {
	const { data, handleSelectAnswer, payload, questionIndex } = props;
	return (
		<>
			<p className="question body-large prominant">{data.prompt}</p>
			{data.answer.map((item: string, index: number) => {
				const k = `radio-${data.type}-${index}`;
				return (
					<Radio
						handleSelectAnswer={handleSelectAnswer}
						key={k}
						label={item}
						name={`${'question' + (questionIndex + 1) + 'Answer'}`}
						checked={payload[`${'question' + (questionIndex + 1) + 'Answer'}`] === item}
						payload={payload}
						datatestid={k}
					/>
				);
			})}
		</>
	);
};
