import { Jurisdictions, Locales } from '../types';
import star from '../assets/star.svg';
import superStar from '../assets/superstar.svg';
import legend from '../assets/legend.svg';

const { REACT_APP_X_API_KEY } = process.env;

export const LOCALES: Locales[] = ['en-UK', 'en-US', 'es-ES', 'fr-CA', 'en-CA'];
export const JURISDICTIONS: Jurisdictions[] = ['UK', 'ES', 'US_NJ', 'US_AZ', 'US_NY', 'CA_ON'];
export const BookRoomLink = 'https://ballyslaketahoe.book.pegsbe.com/';

export const PWA_LOGGEDIN_KEY = 'pwaLoggedIn';
export const PWA_REGISTRATION_KEY = 'pwaRegistrationInfo';
export const BASE_64_REGEX = /data:.*base64,/;
export const NAME_REGEX = /^(?=.*[a-zA-Z -])[- ()a-zA-Z]+$/;
export const IS_SELFIE_VERIFICATION_REQUIRED = false;
export const VERSION_COOKIE = 'appVersion';
export const PLATFORM_COOKIE = 'appPlatform';

export const ONBOARDING = {
	ONBOARDING_SCAN_COMPLETED: 'ONBOARDING_SCAN_COMPLETED',
	ONBOARDING_JOURNEY: 'ONBOARDING_JOURNEY',
	DUPLICATE_ACCOUNT: 'DUPLICATE_ACCOUNT'
};

export const BALLYS_THEME = 'BALLYS_THEME';

export const API_VERSION = 'v1';

export const getNextTierData: any = {
	pro: {
		color: '#84C450',
		icon: star
	},
	star: {
		color: '#00B6DD',
		icon: superStar
	},
	superstar: {
		color: '#EF0502',
		icon: legend
	},
	legend: {
		color: '#EF0502',
		icon: legend
	}
};
export const ROUTE_CONSTANTS = {
	LOBBY: '/',
	REGISTRATION_STEPS: {
		LOGIN: '/login',
		LICENSE_SCAN: '/licensescan',
		LICENSE_VERIFICATION: '/license-verification',
		PROFILE_VERIFICATION: '/profile-verification',
		SELFIE_VERIFICATION: '/selfie-processing',
		REGISTRATION: '/registration',
		VERIFYPIN: '/verify-pin',
		WINBONUS: '/win-bonus',
		UNLOCKREWARD: '/unlock-reward',
		SETPIN: '/set-pin-code'
	},
	PROFILE: {
		LANDING: '/profile',
		EDIT_PROFILE: '/profile/edit',
		OFFERSVIEWALL: '/profile/offers-view-all'
	},
	EXPERIENCE_ROUTES: {
		LANDING: '/experience',
		HOTEL: '/experience/hotel',
		CASINO: '/experience/casino',
		DINING: '/experience/dining'
	},
	OPTIN: '/optin',
	MORE: '/more',
	WIN_BONUS: '/win-bonus',
	REWARDS: '/rewards',
	REGISTER: '/register',
	SILENT_AUTH: '/silent-auth',
	ERROR: '*',
	PARKING: {
		LANDING: '/parking',
		PAYMENT_DETAIL: '/parking/payment-proceed',
		PAYMENT_STATUS: '/parking/payment-status',
		QRSCANNER: '/parking/qr-scanner',
		PAYMENT: '/parking/payment'
	},
	LOGIN_CONGNITO: {
		LANDING: '/login',
		VERIFY_OTP: '/verify-otp',
		VERIFICATION: '/login-new/verify',
		VERIFY_INFO: '/verify-info',
		LOGIN_OPTION: '/login-option',
		VERIFY_SSN: '/verify-ssn',
		KBA_QUESTIONS: '/kba-questions',
		SET_PIN: '/set-pin'
	},
	VERIFY_PIN: '/verify-pin'
};

export const HEADER = {
	HEADER_PRIMARY: 'header_primary',
	HEADER_SECONDARY: 'header_secondary',
	HEADER_TERTIARY: 'header_tertiary'
};

export const API_METHOD = {
	POST: 'POST',
	GET: 'GET',
	DELETE: 'DELETE',
	PUT: 'PUT'
};

export const TOKENS = {
	ACCESS: 'AccessToken',
	ID: 'IdToken',
	REFRESH: 'RefreshToken',
	USER_STATUS: 'UserStatus'
};
export const getRequestOptions = (data: unknown) => {
	return {
		method: API_METHOD.GET,
		headers: {
			'Content-Type': 'application/json',
			'x-api-key': REACT_APP_X_API_KEY ?? ''
		},
		body: data ? JSON.stringify(data) : null
	};
};

export const MinMobileDigit = 10;
export const MaxMobileDigit = 10;

export const countryFlags = {
	US: 'https://d25l49ai1ki248.cloudfront.net/images/phoenix-auth0/US.png',
	IN: 'https://d25l49ai1ki248.cloudfront.net/images/phoenix-auth0/IN.png',
	GB: 'https://d25l49ai1ki248.cloudfront.net/images/phoenix-auth0/GB.png',
	CA: 'https://d25l49ai1ki248.cloudfront.net/images/phoenix-auth0/CA.png'
};
