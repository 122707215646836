import { ROUTE_CONSTANTS } from '../constants';
import { API_ROOT } from '../constants/endPoints';
import { ILink, ImageSize } from '../types';

export const ballyHeaderLogo = '/assets/BallyRewards-logo.png';
export const ballyHeaderLogoWhite = '/assets/BallyRewards-WhiteV1.png';
export const ballyRewardHeaderLogoWhite = '/assets/Bally_reward_white_logo.png';
export const exclusiveOfferImage = '/assets/exclusive-offer.png';
export const navHeaderlogo = '/assets/nav-logo.png';
export const loaderText = 'Hold On ...';
const { REACT_APP_USER_SERVICE_BASE_URL, REACT_APP_X_API_KEY } = process.env;

export const getFooterLinks = (): ILink[] => [
	{
		to: ROUTE_CONSTANTS.LOBBY,
		exact: true,
		icon: 'icon-home',
		label: 'Home'
	},
	{
		to: ROUTE_CONSTANTS.EXPERIENCE_ROUTES.LANDING,
		exact: false,
		icon: 'icon-experiences',
		label: 'Experiences'
	},
	{
		to: ROUTE_CONSTANTS.REWARDS,
		exact: false,
		icon: `icon-coin`,
		label: 'Rewards'
	},
	{
		to: ROUTE_CONSTANTS.MORE,
		exact: false,
		icon: 'icon-more',
		label: 'More'
	}
];

export const getAuthenticationFlowRoute = (): ILink[] => [
	{
		label: 'Pin Verification',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.VERIFY_PIN
	},
	{
		label: '',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.LOGIN_CONGNITO.LANDING
	},
	{
		label: 'Verify Otp',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_OTP
	},
	{
		label: 'Choose Option',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.LOGIN_CONGNITO.LOGIN_OPTION
	},
	{
		label: 'Verify Questions',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.LOGIN_CONGNITO.KBA_QUESTIONS
	},
	{
		label: 'Set PIN',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.LOGIN_CONGNITO.SET_PIN
	},
	{
		label: 'Verify Info',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_INFO
	},
	{
		label: 'Profile',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.PROFILE.LANDING
	},
	{
		label: 'Edit Profile',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.PROFILE.EDIT_PROFILE
	},
	{
		label: '',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.LOGIN
	},
	{
		label: 'Scan ID',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.LICENSE_SCAN
	},
	{
		label: 'PROFILE',
		authenticationFlow: false,
		to: ROUTE_CONSTANTS.PROFILE.EDIT_PROFILE
	},
	{
		label: 'Verify PIN',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.VERIFYPIN
	},
	{
		label: 'Registration',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.REGISTRATION
	},
	{
		label: 'Verification',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.LICENSE_VERIFICATION
	},
	{
		label: 'Profile Selfie',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.PROFILE_VERIFICATION
	},
	{
		label: 'Verification',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.SELFIE_VERIFICATION
	},
	{
		label: 'Win Bonus',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.WINBONUS
	},
	{
		label: 'BallyVerse',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.UNLOCKREWARD
	},
	{
		label: 'Set PIN Code',
		authenticationFlow: true,
		to: ROUTE_CONSTANTS.REGISTRATION_STEPS.SETPIN
	}
];

export const loginLabel = 'LOG IN / REGISTER';
export const backButtonLabel = 'Back';
export const bookARoom = 'Book A Room';

export const getImageOfSize = (recordid: number, url: string, size: ImageSize = 'Small') => {
	const ext = url.split('.').pop();
	return `${API_ROOT}/${recordid}_Image${size}Width.${ext ?? 'jpg'}`;
};

export const errorPageVerbiage = {
	title: 'Something went wrong!',
	linkLabel: 'go back'
};

export const versionErrorVerbiage = {
	title: 'It’s time to elevate Bally Rewards!',
	description: '',
	positiveButtonText: 'Ok',
	negativeButtonText: 'Do it later',
	desc_text_style: 'text-left',
	descriptionAndroid:
		'Our app is craving an update to ensure you get our flawless features. Head over to the Play Store and get the latest updates including patron registration and access to your mailers!',
	descriptionIOS:
		'Our app is craving an update to ensure you get our flawless features. Head over to the App Store and get the latest updates including patron registration and access to your mailers!'
};

export const getPropertyList = async () => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}property/codes?isOneAppProperty=true`, {
		headers: {
			'Content-Type': 'application/json',
			'x-api-key': REACT_APP_X_API_KEY as string
		}
	}).then((res) => {
		if (res?.status !== 200) return { status: res.status, ...res.json() };
		else return res.json();
	});
};

export const getDropDownHeader = 'Select Property';
export const propertyFallbackList = [
	{
		siteCode: 'BC',
		siteName: "Bally's Chicago",
		baseUri: '/chicago',
		customLanding: '',
		mailersApiBaseUrl: 'https://dev.chicago.pcwebserv.com',
		cvpsPropertyId: ''
	}
	// {
	// 	"siteCode": "BLT",
	// 	"siteName": "Bally's Lake Tahoe",
	// 	"baseUri": "/lake-tahoe",
	// 	"customLanding": "",
	// 	"mailersApiBaseUrl": "https://dev.lake-tahoe.pcwebserv.com",
	// 	"cvpsPropertyId": "1000672"
	// },
];
