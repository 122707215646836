import LoginForm from './LoginForm';
import { moreLogoData } from '../../service/moreService';
import './index.scss';
import { PRIVACY_LINK, T_AND_C_LINK, loginVaribages } from '../../service/loginService';
import Layout from '../../hoc/Layout';

const LoginCognito = () => {
	const { login } = loginVaribages;

	return (
		<Layout
			className="page login-cognito"
			dataTestid="login-cognito"
			noHeader={true}
			noFooter={true}
		>
			<div className="logo-section h-center">
				<img
					src={`${process.env.PUBLIC_URL + moreLogoData.logo.url}`}
					className="login-logo"
					alt="login-logo"
					data-testid="login-logo"
				></img>
			</div>
			<LoginForm IsLoginWithEmail={false} />
			<div className="disclaimer-container">
				<p className="login-disclaimer">
					{login.disclaimer}
					<a href={T_AND_C_LINK} className="underline">
						{login.TCtext}
					</a>
					{login.and}
					<a href={PRIVACY_LINK} className="underline">
						{login.privacy}
					</a>
				</p>
			</div>
		</Layout>
	);
};
export default LoginCognito;
