import './More.scss';
import {
	getDisclaimerInformation,
	getDisclaimerImage,
	getHelpLinks,
	getLegalLinks,
	moreLogoData,
	moreHeadingData as moreText,
	getOtherLinks,
	getDisclaimerImage_LightTheme
} from '../../service/moreService';
import GroupListing from '../../components/Listing/GroupListing';
import DisclaimerText from '../../components/Disclaimer/DisclaimerText';
import { useEffect, useState } from 'react';
import { IDisclaimerProps, DisclaimerImg } from '../../types';
import { getCookieValue, useGlobalContext, useLDContext, useThemeContext } from '../../hooks';
import { VERSION_COOKIE } from '../../constants';
import Layout from '../../hoc/Layout';

const More = () => {
	const {
		user,
		global: { isAuthenticated }
	} = useGlobalContext();
	const { theme } = useThemeContext();
	const { isFaceIDFeatureAvailable, isLightThemeFeatureAvailable } = useLDContext();
	const [appVersion, setAppVersion] = useState<string>('');
	const [disclaimerImages, setDisclaimerImages] = useState<{
		light: DisclaimerImg[];
		dark: DisclaimerImg[];
	}>({
		light: [],
		dark: []
	});
	const [disclaimer, setDisclaimer] = useState<IDisclaimerProps>({
		Columns: [
			{
				ColumnResponsiveImage: {}
			}
		],
		RichText: ''
	} as IDisclaimerProps);

	useEffect(() => {
		setAppVersion(getCookieValue(VERSION_COOKIE));
		getDisclaimerDetail();
	}, []);

	useEffect(() => {
		setDisclaimer({
			...disclaimer,
			Columns: [...disclaimerImages[theme]]
		});
	}, [theme, disclaimerImages]);

	const getDisclaimerDetail = async () => {
		try {
			const response: IDisclaimerProps[] = await Promise.all([
				getDisclaimerImage(),
				getDisclaimerImage_LightTheme(),
				getDisclaimerInformation()
			]);

			if (response.length) {
				setDisclaimerImages({ dark: response[0].Columns, light: response[1].Columns });
				const { RichText } = response[2];

				setDisclaimer({
					Columns: response[0].Columns,
					RichText
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Layout className="page-more" dataTestid="testid-more">
			<div className="more-logo more-gap">
				<img
					src={`${process.env.PUBLIC_URL + moreLogoData.logo.url}`}
					className="dummy"
					alt="more-logo"
					data-testid="more-logo"
				></img>
			</div>

			{/* <p className="more-heading headline-medium">{moreText?.help?.label}</p> */}
			<div className="group-list-item more-gap group-list-section" data-testid="group-list-item">
				<GroupListing
					links={getHelpLinks(
						isAuthenticated && !user?.isLoading,
						isFaceIDFeatureAvailable,
						isLightThemeFeatureAvailable
					)}
				/>
			</div>

			<p className="more-heading label-small">{moreText?.legal?.label}</p>
			<div className="group-list-item more-gap group-list-section" data-testid="group-list-item">
				<GroupListing links={getLegalLinks()} />
			</div>

			<div className="group-list-item more-gap group-list-section" data-testid="group-list-item">
				<GroupListing
					links={getOtherLinks(isAuthenticated && !user?.isLoading && !!user?.playerId)}
				/>
			</div>

			{/* bottom content */}
			<div className="disclaimer-content-section">
				<DisclaimerText disclaimerImg={disclaimer.Columns} disclaimertext={disclaimer.RichText} />
			</div>

			{/* App version */}
			<div className="app-version-section" data-testid="app-version">
				<p className="app-version-logo">
					<img
						src={`${process.env.PUBLIC_URL + moreLogoData.appVersionLogo.url}`}
						className="more-bootom-logo"
						alt="more-bootom-logo"
						data-testid="app-version-logo"
					></img>
				</p>

				{appVersion && (
					<p className="app-version small1" data-testid="version-testid">
						{moreLogoData.appVersion.label} {appVersion}
					</p>
				)}
			</div>
			{/* App version end */}
		</Layout>
	);
};

export default More;
