import {
	API_BASE_URL,
	API_ROOT,
	GET_CASINO_BANNER_DESCRIPTION,
	GET_CASINO_BANNER_IMG,
	GET_CASINO_GALLARY,
	GET_DINING_BANNER,
	GET_DINING_INFORMATION,
	GET_EXPERIENCE_DETAILS,
	GET_HOTEL_BANNER_DESCRIPTION,
	GET_HOTEL_BANNER_IMG,
	GET_RESTAURANTS
} from '../constants/endPoints';
import { IExperiencesBannerProps, IImageGallery, ILink, IRestaurant } from '../types';
import jsonp from 'fetch-jsonp';
import { ROUTE_CONSTANTS } from '../constants';

export const RestaurantsText = 'Restaurants';
export const GalleryText = 'Gallery';
export const LearnMoreText = 'Learn More';

export const CasinoText = 'Casino';
export const Community = 'Community';
export const HotelText = 'Hotel';
export const PartnersText = 'Partners';
export const ConnectWithUsText = 'Connect with us';
export const ComingSoonText = 'Coming Soon';

export const getHotelGridItems = (): ILink[] => [
	{
		label: 'Offers',
		to: 'https://casinos.ballys.com/lake-tahoe/offers.htm',
		icon: 'icon-offers',
		external: true
	},
	{
		label: 'Rooms',
		to: 'https://casinos.ballys.com/lake-tahoe/rooms.htm',
		icon: 'icon-hotel',
		external: true
	},
	{
		label: 'Amenities',
		to: 'https://casinos.ballys.com/lake-tahoe/amenities.htm',
		icon: 'icon-ammenities',
		external: true
	},
	{
		label: 'Spa',
		to: 'https://casinos.ballys.com/lake-tahoe/spa.htm',
		icon: 'icon-spa',
		external: true
	}
];

export const getCasinoGridItems = (isLoggedIn = false): ILink[] => [
	{
		label: 'Slots',
		to: 'https://casinos.ballys.com/lake-tahoe/slots.htm',
		icon: 'icon-slot',
		external: true
	},
	{
		label: 'Table Games',
		to: 'https://casinos.ballys.com/lake-tahoe/table-games.htm',
		icon: 'icon-table-games',
		external: true
	},
	{
		label: 'Promos',
		to: 'https://casinos.ballys.com/lake-tahoe/promotions.htm',
		icon: 'icon-promotion',
		external: true
	},
	{
		label: 'Meet our hosts',
		to: 'https://casinos.ballys.com/lake-tahoe/meet-our-hosts.htm',
		icon: 'icon-meets-hosts',
		external: true
	},
	{
		label: 'Casino Credit',
		to: 'https://casinos.ballys.com/lake-tahoe/casino-credit.htm',
		icon: 'icon-casino-chip',
		external: true
	},
	{
		label: 'Rewards',
		to: isLoggedIn ? ROUTE_CONSTANTS.PROFILE.LANDING : ROUTE_CONSTANTS.OPTIN,
		icon: 'icon-coin',
		external: false
	}
];

export const getDiningGridItems = (): ILink[] => [
	{
		label: 'Event Calendar',
		to: 'https://casinos.ballys.com/lake-tahoe/events-calendar.aspx',
		icon: 'icon-calendar',
		external: true
	},
	{
		label: 'Nightlife',
		to: 'https://casinos.ballys.com/lake-tahoe/night-life.htm',
		icon: 'icon-nightlife',
		external: true
	},
	{
		label: 'Puzzle Room',
		to: 'https://casinos.ballys.com/lake-tahoe/puzzle-room.htm',
		icon: 'icon-puzzle-room',
		external: true
	},
	{
		label: 'Shops',
		to: 'https://casinos.ballys.com/lake-tahoe/shops.htm',
		icon: 'icon-shop',
		external: true
	},
	{
		label: 'Bars',
		to: 'https://casinos.ballys.com/lake-tahoe/bars.htm',
		icon: 'icon-bars',
		external: true
	}
];

export const getExperienceResturantItems = () => ({
	partners: 'https://casinos.ballys.com/chicago/partners.htm',
	community: '',
	casino: 'https://casinos.ballys.com/chicago/casino.htm',
	connect: 'https://casinos.ballys.com/chicago/connect-with-us.htm'
});

export const getExperienceGrids = (): {
	partners: ILink[];
	casino: ILink[];
	community: ILink[];
	connect: ILink[];
} => {
	return {
		partners: [
			{
				label: 'Hotel & Parking',
				to: 'https://casinos.ballys.com/chicago/hotel-and-parking.htm',
				icon: 'icon-hotel',
				external: true
			},
			{
				label: 'Construction Partner',
				to: 'https://casinos.ballys.com/chicago/community-partners.htm',
				icon: 'icon-construction',
				external: true
			},
			{
				label: 'Vendors',
				to: 'https://casinos.ballys.com/chicago/vendors.htm',
				icon: 'icon-vendors',
				external: true
			}
		],

		community: [
			{
				label: 'Tribune Location',
				to: '',
				icon: 'icon-star',
				external: true
			},
			{
				label: 'MBE/WBE Certificate',
				to: '',
				icon: 'icon-calendar',
				external: true
			},
			{
				label: 'Community Partnership',
				to: '',
				icon: 'icon-drink',
				external: true
			}
		],
		casino: [
			{
				label: 'Slots',
				to: 'https://casinos.ballys.com/chicago/slots.htm',
				icon: 'icon-diamond',
				external: true
			},
			{
				label: 'Table Games',
				to: 'https://casinos.ballys.com/chicago/table-games.htm',
				icon: 'icon-experiences',
				external: true
			},
			{
				label: 'Dining',
				to: 'https://casinos.ballys.com/chicago/dining.htm',
				icon: 'icon-food',
				external: true
			}
		],
		connect: [
			{
				label: 'Email Signup',
				to: 'https://casinos.ballys.com/chicago/sign-up-for-emails.htm',
				icon: 'icon-email',
				external: true
			},
			{
				label: 'Careers',
				to: 'https://casinos.ballys.com/chicago/careers.htm',
				icon: 'icon-career',
				external: true
			},
			{
				label: 'Contact Us',
				to: 'https://casinos.ballys.com/chicago/connect-with-us.htm',
				icon: 'icon-phone',
				external: true
			}
		]
	};
};

export const getRestaurants = (): Promise<{
	Items: IRestaurant[];
}> => {
	return jsonp(`${API_ROOT}/${GET_RESTAURANTS}.js`, {
		jsonpCallbackFunction: `${GET_RESTAURANTS}DataCallback`
	}).then((response) => {
		return response.json();
	});
};

export const getDiningBanner = (): Promise<IExperiencesBannerProps> => {
	return jsonp(`${API_ROOT}/${GET_DINING_BANNER}.js`, {
		jsonpCallbackFunction: `${GET_DINING_BANNER}DataCallback`
	}).then((response) => {
		return response.json();
	});
};
export const getDiningInformation = (): Promise<IExperiencesBannerProps> => {
	return jsonp(`${API_ROOT}/${GET_DINING_INFORMATION}.js`, {
		jsonpCallbackFunction: `${GET_DINING_INFORMATION}DataCallback`
	}).then((response) => {
		return response.json();
	});
};

export const getHotelGallery = (): Promise<IImageGallery> => {
	return jsonp(`${API_ROOT}/${GET_CASINO_GALLARY}.js`, {
		jsonpCallbackFunction: `${GET_CASINO_GALLARY}DataCallback`
	}).then((response) => {
		return response.json();
	});
};

export const getHotelBannerImg = (): Promise<IExperiencesBannerProps> => {
	return jsonp(`${API_ROOT}/${GET_HOTEL_BANNER_IMG}.js`, {
		jsonpCallbackFunction: `${GET_HOTEL_BANNER_IMG}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IExperiencesBannerProps>;
	});
};

export const getHotelBannerDescription = (): Promise<IExperiencesBannerProps> => {
	return jsonp(`${API_ROOT}/${GET_HOTEL_BANNER_DESCRIPTION}.js`, {
		jsonpCallbackFunction: `${GET_HOTEL_BANNER_DESCRIPTION}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IExperiencesBannerProps>;
	});
};

export const getCasinoBannerImg = (): Promise<IExperiencesBannerProps> => {
	return jsonp(`${API_ROOT}/${GET_CASINO_BANNER_IMG}.js`, {
		jsonpCallbackFunction: `${GET_CASINO_BANNER_IMG}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IExperiencesBannerProps>;
	});
};

export const getCasinoBannerDescription = (): Promise<IExperiencesBannerProps> => {
	return jsonp(`${API_ROOT}/${GET_CASINO_BANNER_DESCRIPTION}.js`, {
		jsonpCallbackFunction: `${GET_CASINO_BANNER_DESCRIPTION}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IExperiencesBannerProps>;
	});
};

export const getExperienceDetails = () => {
	return jsonp(`${API_BASE_URL}/${GET_EXPERIENCE_DETAILS}.js`, {
		jsonpCallbackFunction: `${GET_EXPERIENCE_DETAILS}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IExperiencesBannerProps>;
	});
};
