import DOMPurify from 'dompurify';
import { Disclaimer, DisclaimerImg } from '../../types';

import './DisclaimerText.scss';
const DisclaimerText = ({ disclaimerImg, disclaimertext }: Disclaimer) => {
	return (
		<div className="disclaimer-content">
			<p
				className="disclaimer-discription small1"
				data-testid="banner-subtitle"
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(disclaimertext)
				}}
			></p>

			<div className="disclaimer-logo">
				{disclaimerImg?.map((data: DisclaimerImg, index: number) => {
					const k = `desclaimer-img-${index}`;
					return (
						<img
							key={k}
							src={data?.ColumnResponsiveImage?.FullURL}
							alt={data.ColumnResponsiveImageAltText}
							data-testid={k}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default DisclaimerText;
