import './index.scss';
import OtpInput from '../../components/InputField/OtpInput';
import InputButton from '../../components/InputField/InputButton';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../../hoc/Layout';
import { OTP_ACTION, getOtp, loginVaribages } from '../../service/loginService';
import { ROUTE_CONSTANTS, TOKENS } from '../../constants';
import {
	logoutSession,
	saveRefreshToken,
	setSessionItem,
	useCurrentProperty,
	useGlobalContext
} from '../../hooks';
import PopupDrawer from '../../components/PopupDrawer';
import { Ipopup, UserStatusInCMS } from '../../types';

declare const window: any;

interface IVerifyOtpRes {
	userInfo: { status: string; nextState: string; globalId: string };
	AuthenticationResult: { RefreshToken: string };
}

const VerifyOtp = () => {
	const { verifyOtp, pinVerification } = loginVaribages;
	const [showModal, setShowModal] = useState(false);
	const { user, setUserDataInContext } = useGlobalContext();
	const [errorPopupData, setErrorPopupData] = useState<Ipopup>({
		positiveButtonText: '',
		description: '',
		positiveCallback: () => {
			//
		}
	});
	const currentProperty = useCurrentProperty();
	const history = useHistory();
	const location: {
		state: {
			Session: string;
			ChallengeParameters: { USERNAME: string; email: string };
			mobileNumber: string;
		};
	} = useLocation();
	const [OTP, setOTPState] = useState('');
	const [timer, setTimer] = useState<number>(30);
	const [showTimer, setShowTimer] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [sessionValue, setSessionValue] = useState<string>('');

	useEffect(() => {
		if (location.state?.Session !== sessionValue) {
			setSessionValue(location.state?.Session);
		}
	}, [location.state?.Session]);

	const handleChange = useCallback(
		(otp: string) => {
			setOTPState(otp);
		},
		[OTP]
	);

	useEffect(() => {
		let interval: any;
		if (showTimer) {
			interval = setInterval(() => {
				setTimer(Number(timer - 1));
			}, 1000);
			if (timer == -1) {
				clearInterval(interval);
				setShowTimer(false);
				setTimer(30);
			}
		}
		return () => clearInterval(interval);
	}, [showTimer, timer, setShowTimer]);

	const getMobileNumber = useMemo(() => {
		return location?.state?.mobileNumber.split(' ').splice(0).join('');
	}, [location?.state?.mobileNumber]);

	const handleResendOTP = useCallback(async () => {
		if (!showTimer) {
			setShowTimer(true);
			setOTPState('');
			setErrorMsg('');
			try {
				const res = await getOtp(
					{
						action: OTP_ACTION.SEND_OTP,
						phoneNumber: getMobileNumber
					},
					currentProperty.siteCode
				);
				if (res?.currStatus == 200) {
					setSessionValue(res?.Session);
				} else if (res?.message) {
					setErrorMsg(res?.message);
				}
			} catch (err) {
				console.log(err);
			}
		}
	}, [showTimer, setShowTimer, timer, setTimer]);

	interface CredentialRequestOptions {
		otp: OTPOptions;
		signal: any;
	}

	interface OTPOptions {
		transport: string[];
	}
	useEffect(() => {
		const otpRequest = async () => {
			if ('OTPCredential' in window) {
				const abortController = new AbortController();
				const tid = setTimeout(() => {
					abortController.abort('aborted due to timeout');
					clearTimeout(tid);
				}, 30 * 1000);

				const o: CredentialRequestOptions = {
					otp: { transport: ['sms'] },
					signal: abortController.signal
				};

				const content = await window.navigator['credentials'].get(o);
				console.log(content?.code);
				setOTPState(content?.code);
				//do what ever you want to do with the received code, probably send it to server
			}
		};

		otpRequest().catch((err) => {
			console.log('ERR:', err);
		});
	}, []);

	const handlePositiveButton = useCallback(async () => {
		// User excluded/blocked > popup closed
		setUserDataInContext({ isLoading: false });
		await logoutSession();
		setShowModal(false);
		history.push(ROUTE_CONSTANTS.LOBBY);
	}, [history]);

	const handleVerifyOtpSuccess = async (res: IVerifyOtpRes) => {
		if ((res.userInfo?.status as UserStatusInCMS) == 'NewUser') {
			//>> REGISTRATION FLOW
			history.replace({
				pathname: ROUTE_CONSTANTS.LOGIN_CONGNITO.LOGIN_OPTION,
				state: {
					mobileNumber: getMobileNumber,
					refreshToken: res.AuthenticationResult?.RefreshToken
				}
			});
		} else {
			//>> LOGIN FLOW
			await saveRefreshToken(res.AuthenticationResult?.RefreshToken);
			history.push(ROUTE_CONSTANTS.LOBBY);
		}
	};

	const handleVerifyOtpFailure = (res: any) => {
		switch (res.currStatus) {
			case 403: {
				//User Excluded
				setErrorPopupData({
					...verifyOtp.exclusionPopup,
					negativeCallback: handlePositiveButton,
					positiveCallback: handlePositiveButton
				});
				setShowModal(true);
				break;
			}
			case 406: {
				//Account blocked
				setErrorPopupData({
					...pinVerification.errorPopup,
					negativeCallback: handlePositiveButton,
					positiveCallback: handlePositiveButton
				});
				setShowModal(true);
				break;
			}
			case 401: {
				setErrorMsg(verifyOtp.invalidOtpRetry);
				if (res?.message?.Session) {
					setSessionValue(res?.message?.Session);
				}
				break;
			}
			default:
				setErrorMsg(res?.message || res);
		}
	};

	const handleContinue = useCallback(
		async (event: any) => {
			event.preventDefault();
			if (OTP.length < 4) {
				setErrorMsg(verifyOtp.errorMsg);
				return;
			}
			setIsLoading(true);
			setErrorMsg('');
			try {
				const payload = {
					action: OTP_ACTION.VERIFY_OTP,
					otp: OTP,
					phoneNumber: getMobileNumber
				};

				const res = await getOtp(
					location.state?.Session
						? {
								...payload,
								metaData: {
									ChallengeParameters: location.state.ChallengeParameters,
									Session: sessionValue
								}
						  }
						: {
								...payload
						  },
					currentProperty.siteCode
				);

				if (
					res.currStatus == 200 &&
					res.AuthenticationResult?.AccessToken &&
					res.userInfo?.status
				) {
					setSessionItem(TOKENS.ACCESS, res.AuthenticationResult?.AccessToken);
					setSessionItem(TOKENS.ID, res.AuthenticationResult?.IdToken);
					setSessionItem(TOKENS.USER_STATUS, res.userInfo?.status);
					setUserDataInContext({
						...user,
						isLoading: true
					});
					handleVerifyOtpSuccess(res);
				} else {
					handleVerifyOtpFailure(res);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setIsLoading(false);
			}
		},
		[errorMsg, OTP, setErrorMsg, sessionValue, setSessionValue]
	);

	const handleOnFocus = useCallback(() => {
		setErrorMsg('');
	}, [errorMsg]);

	return (
		<Layout className="page verifyotp-cognito" noFooter={true} dataTestid="testid-verifyOtp">
			<div className="form-section">
				<h2 className="form-heading headline-medium">{verifyOtp.otpVerification}</h2>
				<p className="form-sub-heading body-text-medium">
					{`${verifyOtp.enterFourDigit}`}
					<i>{`${location?.state?.mobileNumber}`}</i> {`${verifyOtp.throughSMS}`}
				</p>
				<form noValidate onSubmit={handleContinue}>
					<div className="otp-container">
						<OtpInput
							onFocus={handleOnFocus}
							autoFocus={true}
							length={4}
							inputClassName={`single-otpInput ${errorMsg ? 'error' : ''}`}
							onChangeOTP={handleChange}
							isInputRef={true}
							otp={OTP}
						/>
					</div>
					{errorMsg && (
						<p data-testid="error-msg" className="error-msg">
							<i className="icon-info"></i>
							{errorMsg}
						</p>
					)}
					<p className="resend-text">
						{`${verifyOtp.didntrecevied} ${showTimer ? verifyOtp.resendIn : ''}`}{' '}
						<span className="resend-text active" data-testid="resend-sms" onClick={handleResendOTP}>
							{showTimer
								? `00:${timer.toLocaleString(undefined, {
										minimumIntegerDigits: 2,
										useGrouping: false
								  })}`
								: verifyOtp.resendOtp}
						</span>
					</p>
					{/* <div className="error-container">
					<i className="icon-info" />
					<p className="error-text">
						You have exceeded the limit for OTP attempts and resend. Please try again later.
					</p>
					</div> */}
					<InputButton disabled={isLoading} varient="primary" datatestid="continue-btn">
						{verifyOtp.btn.Continue}
					</InputButton>
				</form>
			</div>
			{showModal && (
				<PopupDrawer
					errorImage={
						<div className="error-icon-box">
							<i
								className={`icon-modal-view`}
								style={{ backgroundImage: `url(${errorPopupData?.icon})` }}
							/>
						</div>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">{errorPopupData?.desc_heading}</h2>
					}
					subDesc={
						errorPopupData?.sub_desc && (
							<div className="contact-container">
								<div className="contact-info">
									<i className="icon-phone" />
									<p className="contact-desc body-text-medium">
										{errorPopupData.sub_desc?.phone_no}
									</p>
								</div>
								<div className="contact-info">
									<i className="icon-location" />
									<p className="body-text-medium">{errorPopupData.sub_desc?.loc}</p>
								</div>
							</div>
						)
					}
					handleNegativeButton={errorPopupData.negativeCallback}
					handlePositiveButton={errorPopupData.positiveCallback}
					data={errorPopupData}
				/>
			)}
		</Layout>
	);
};
export default VerifyOtp;
