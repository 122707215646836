import { useCallback, useEffect, useRef, useState } from 'react';
import './MobileNumber.scss';
import { MaxMobileDigit } from '../../constants';
import { ICountryCode, IMobileNumber } from '../../types';
export const RE_DIGIT = new RegExp(/^\d+$/);

export const MobileNumber = (props: IMobileNumber) => {
	const { errorMsg, setErrorMsg } = props;
	const [dialCode, updateDailcode] = useState('+1');
	const [countryCode, updateCountryCode] = useState('US');
	const [phoneNo, updatePhoneNo] = useState('');
	const selectOption = useRef<HTMLSelectElement>(null);
	const [countryCodes, setCountryCode] = useState<ICountryCode[]>([]);

	const identifierInput = useRef<HTMLInputElement>(null);

	const onSelectionChange = (evt: any) => {
		updateCountryCode(evt.target.value);
		updateDailcode(countryCodes[evt.target.selectedIndex]?.dial_code);
	};

	const handleInputChange = (evt: any) => {
		if (evt?.target && identifierInput.current) {
			const value = evt.target.value;
			if (value.length > MaxMobileDigit) {
				return;
			}
			if (value.length >= MaxMobileDigit) {
				identifierInput.current?.blur();
			}
			updatePhoneNo(value);
		}
	};

	const handleOnFocus = useCallback(() => {
		setErrorMsg('');
	}, [setErrorMsg]);

	const getCountryCodeData = () => {
		fetch(`${process.env.PUBLIC_URL}/countrycode.json`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			}
		})
			.then(async function (response) {
				const json = await response.json();
				setCountryCode(json);
			})
			.then(function (myJson) {
				console.log(myJson);
			});
	};

	useEffect(() => {
		getCountryCodeData();
	}, []);

	return (
		<div className={`input-container ${errorMsg ? 'error' : ''}`}>
			<div
				className="PhoneSubmit-selection-box"
				data-testid="select-box"
				onClick={() => selectOption?.current?.click()}
			>
				<div
					data-testid="test-flag"
					className="PhoneSubmit-selected-flag"
					style={{
						backgroundImage: `URL('https://d25l49ai1ki248.cloudfront.net/images/phoenix-auth0/${[
							countryCode
						]}.png')`
					}}
				></div>
				<span className="PhoneSubmit-selected-code">
					<div className="PhoneSubmit-selected-country-code label-medium">{dialCode}</div>
					<i className="icon-arrow-head"></i>
				</span>
				<select
					disabled={props.isLoading}
					data-testid="select-code"
					id={dialCode}
					name="countryCode"
					value={countryCode}
					ref={selectOption}
					onChange={(evt) => onSelectionChange(evt)}
				>
					{countryCodes.map((country: ICountryCode, index: number) => {
						const k = `selection_option_${index}`;
						return (
							<option key={k} value={country.code} data-testid={k}>
								{country.name} ({country.dial_code})
							</option>
						);
					})}
				</select>
			</div>
			<input
				disabled={props.isLoading}
				data-testid="mobile-no"
				ref={identifierInput}
				id="identifier"
				name="identifier"
				className="form-control label-large"
				autoComplete="off"
				type="number"
				height={'48px'}
				onFocus={handleOnFocus}
				onKeyUp={handleInputChange}
				onChange={handleInputChange}
				value={phoneNo}
				placeholder={'Enter your Mobile number'}
				required={true}
				pattern="[0-9]*"
				inputMode="numeric"
			/>
		</div>
	);
};
