import { useCallback } from 'react';
import { ReadioButtonInterface } from '../../types';
import './Radio.scss';

const Radio = (props: ReadioButtonInterface) => {
	const { label, datatestid = 'testid-radio', handleSelectAnswer, name, payload, ...rest } = props;

	const handleOnClick = useCallback(() => {
		handleSelectAnswer({ quesId: name, label: label });
	}, [payload]);

	return (
		<div className="radio-box" data-testid="test-radiobuton">
			<input
				id={datatestid}
				className="radio-button"
				type="radio"
				{...rest}
				data-testid={datatestid}
				onChange={handleOnClick}
			/>
			<label htmlFor={datatestid} className="radio-option body-large">
				{label}
			</label>
		</div>
	);
};

export default Radio;
