import { NavLink } from 'react-router-dom';
import './Footer.scss';
import { FC } from 'react';
import { ILink, IRedirection } from '../../types';
import { moreLogoData } from '../../service/moreService';

const Footer: FC<IRedirection> = ({ links }) => {
	return (
		<footer className="footer">
			<nav className="footer_nav">
				{links?.map((link: ILink) =>
					!link.external ? (
						<NavLink
							data-testid="testid-footerLink"
							key={link.label}
							exact={link.exact}
							to={link.to}
							className={`${link.icon} nav__icon nav__link `}
							activeClassName="active"
						>
							{link.label}
						</NavLink>
					) : (
						<a
							data-testid="testid-footerLink"
							key={link.label}
							target="_blank"
							rel="noreferrer"
							href={link.to}
							className={`${link.icon} nav__icon nav__link `}
						>
							{link.label}
						</a>
					)
				)}
			</nav>

			<div className="visibility-hidden">
				<span className="icon-coin"></span>
				<span className="icon-coin active"></span>
				<img loading="lazy" src={`${process.env.PUBLIC_URL + moreLogoData.logo.url}`} />
				<img loading="lazy" src={`${process.env.PUBLIC_URL + moreLogoData.appVersionLogo.url}`} />
			</div>
		</footer>
	);
};

export default Footer;
