import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext, RNContext, localLDContext, ThemeContext } from '../context/GlobalContext';
import { getAuthenticationFlowRoute, propertyFallbackList } from '../service/appService';
import { getLobbyGridItems, getTitleName } from '../service/lobbyService';
import { PAGESTATE, PropertyList, UserStatusInCMS } from '../types';
import * as H from 'history';
import { HEADER, ROUTE_CONSTANTS, TOKENS } from '../constants';
import { getSessionData } from './utility';
import { parseJwt } from '../service/loginService';
export * from './utility';

export const useGlobalContext = () => useContext(GlobalContext);
export const useRNContext = () => useContext(RNContext);
export const useThemeContext = () => useContext(ThemeContext);
export const useLDContext = () => useContext(localLDContext);

export const useCountdown = (targetDate: Date) => {
	const countDownDate = new Date(targetDate).getTime();
	const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

	useEffect(() => {
		if (countDownDate) {
			const interval = setInterval(() => {
				setCountDown(countDownDate - new Date().getTime());
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [countDownDate]);

	if (!countDownDate) {
		return [0, 0, 0, 0];
	}
	return getReturnValues(countDown);
};

export const getReturnValues = (countDown: number) => {
	// calculate time left
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
	return [days, hours, minutes, seconds];
};

export const usePageTitle = () => {
	const [titleData, setTitleData] = useState<{
		label: string | undefined;
		isAuthenticationFlowRoute: boolean;
	}>();
	const location: H.Location = useLocation();

	const getTitle = () => {
		const gridList = [...getLobbyGridItems(), ...getTitleName()];
		const authenticationFlowRoutes = getAuthenticationFlowRoute();
		let isAuthenticationFlowRoute = false;
		let link = gridList.find((item) => location.pathname === item.to);
		const param = new URLSearchParams(location.search);
		if (!link?.label) {
			if (location.pathname === ROUTE_CONSTANTS.PROFILE.OFFERSVIEWALL) {
				return {
					label: param.get('title') as string,
					isAuthenticationFlowRoute: isAuthenticationFlowRoute
				};
			}
			link = authenticationFlowRoutes.find((item) => location.pathname === item.to);
			if (link?.authenticationFlow) isAuthenticationFlowRoute = link.authenticationFlow;
		}
		return { label: link?.label, isAuthenticationFlowRoute: isAuthenticationFlowRoute };
	};

	useEffect(() => {
		setTitleData(getTitle());
	}, [location]);
	return titleData;
};

export const useTransitionState = (duration = 1000) => {
	const [transitonState, setTransitonState] = useState<any>();

	useEffect(() => {
		let timerId: any;
		if (transitonState === PAGESTATE.EXITING) {
			timerId = setTimeout(() => setTransitonState(PAGESTATE.EXITED), duration);
		}

		return () => {
			timerId && clearTimeout(timerId);
		};
	});

	const exit = () => {
		if (transitonState !== PAGESTATE.EXITED) {
			setTransitonState(PAGESTATE.EXITING);
			console.log('hook called', transitonState);
		}
	};

	return [transitonState, exit];
};

export const useCurrentProperty = () => {
	const {
		global: { propertyList }
	} = useGlobalContext();

	const currentProperty = useMemo(() => {
		const _property = {
			...(propertyList?.filter((item: PropertyList) =>
				window.location.pathname.startsWith(item.baseUri)
			)[0] ?? propertyFallbackList[0])
		};

		//>> Remove "Bally's" from siteName as only Property name is going to be used
		_property.siteName = _property.siteName.split(' ').splice(1).join(' ');
		return _property;
	}, [propertyList]);

	return currentProperty;
};

export const useCurrentHeader = (title: string, isAuthenticationFlowRoute: boolean) => {
	const location: H.Location = useLocation();

	const currentHeader = useMemo(() => {
		if (title && !isAuthenticationFlowRoute) {
			return HEADER.HEADER_SECONDARY;
		}
		if (isAuthenticationFlowRoute) {
			return HEADER.HEADER_TERTIARY;
		}
		return HEADER.HEADER_PRIMARY;
	}, [location, title, isAuthenticationFlowRoute]);

	return currentHeader;
};

export const useCongnitoUser = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [accessToken, setAccessToken] = useState('');
	const [idToken, setIdToken] = useState('');
	const [userStatusInSession, setUserStatusInSession] = useState<UserStatusInCMS>();

	const handleStorageChange = useCallback(() => {
		setAccessToken(getSessionData(TOKENS.ACCESS) ?? '');
		setIdToken(getSessionData(TOKENS.ID) ?? '');
		setUserStatusInSession((getSessionData(TOKENS.USER_STATUS) as UserStatusInCMS) ?? '');
		setIsLoading(false);
	}, []);

	useEffect(() => {
		window.addEventListener('storage', handleStorageChange);
		handleStorageChange();
		return () => window.removeEventListener('storage', handleStorageChange);
	}, []);

	const congnitoUserData = useMemo(
		() => ({
			isLoading,
			congnitoIsAuthenticated: !!accessToken && userStatusInSession == 'RegisteredUser',
			congnitoUser: idToken ? parseJwt(idToken) : {},
			accessToken
		}),
		[accessToken, idToken, userStatusInSession, isLoading]
	);

	return congnitoUserData;
};
