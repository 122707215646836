import { useMemo, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Header.scss';
import { IHeaderProps } from '../../types';
import { backButtonLabel } from '../../service/appService';
import { HEADER, ROUTE_CONSTANTS } from '../../constants';
import { useCongnitoUser, useCurrentHeader, useGlobalContext, useLDContext } from '../../hooks';
import LoginStatus from './LoginStatus';
import Heading from '../Heading/Heading';
import PropertyPopup from '../PropertyPopup/PropertyPopup';
import BrandLogo from '../BrandLogo';

const Header = ({ title = '', isAuthenticationFlowRoute = false }: IHeaderProps) => {
	const history = useHistory();
	const { isCountrySwitchFeatureAvailable } = useLDContext();
	const { congnitoIsAuthenticated } = useCongnitoUser();
	const { user } = useGlobalContext();
	const currentHeader = useCurrentHeader(title, isAuthenticationFlowRoute);
	const [userInfo, setUserInfo] = useState('');
	const [showProperty, setShowProperty] = useState<boolean>(false);

	const backClick = useCallback(() => {
		history.goBack();
	}, [history]);

	useEffect(() => {
		if (!user?.isLoading) {
			if (user?.firstName) {
				setUserInfo(`${user?.firstName}!`);
			} else {
				setUserInfo('New User!');
			}
		}
	}, [user, congnitoIsAuthenticated]);

	const handleToggleProperty = useCallback(() => {
		setShowProperty(!showProperty);
	}, [showProperty]);

	const getHeaderDropDown = () => {
		return (
			<div className="header-dropdown" onClick={handleToggleProperty}>
				<BrandLogo isVertical={false} />
				<i className="icon-arrow-head" />
			</div>
		);
	};

	const showHeaderDropdown = useMemo(() => {
		if (isCountrySwitchFeatureAvailable && history?.location?.pathname == ROUTE_CONSTANTS.LOBBY)
			return true;
		return false;
	}, [history?.location?.pathname, isCountrySwitchFeatureAvailable]);

	const headerBlock = useMemo(() => {
		const getBackBtn = () => (
			<a data-testid="button-back" className={`icon-arrow-head`} onClick={backClick}>
				<span className="sr-only">{backButtonLabel}</span>
			</a>
		);

		switch (currentHeader) {
			case HEADER.HEADER_PRIMARY: {
				return (
					<>
						{!showHeaderDropdown ? (
							<Heading
								label={`Hello, ${userInfo}`}
								loading={!!user?.isLoading}
								className="header_title_user headline-medium"
							/>
						) : (
							getHeaderDropDown()
						)}
						<LoginStatus />
					</>
				);
			}
			case HEADER.HEADER_SECONDARY: {
				return (
					<>
						<div className="back-button-container" data-testid="header-back">
							{getBackBtn()}
						</div>
					</>
				);
			}
		}
	}, [currentHeader, title, userInfo, user]);

	return (
		<header className={`header ${currentHeader}`} data-testid="header-component">
			{headerBlock}
			{showProperty && <PropertyPopup handleToggleProperty={handleToggleProperty} />}
		</header>
	);
};

export default Header;
