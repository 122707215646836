import { HTMLProps, useRef, useState } from 'react';
import './EmailInput.scss';

export const EmailInput = ({ className }: HTMLProps<HTMLButtonElement>) => {
	const [userName, setUserName] = useState<string | number>('');
	const textinputRef = useRef<HTMLInputElement>(null);
	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserName(e.target.value);
	};
	return (
		<>
			<input
				ref={textinputRef}
				value={userName}
				onChange={handleOnChange}
				type="email"
				id="email-input"
				className={`email-input ${className}`}
				placeholder={'Enter your Player / Email ID'}
				data-testid="email-input"
				autoCapitalize="none"
				inputMode="email"
			/>
		</>
	);
};
