import './index.scss';
import Layout from '../../hoc/Layout';
import { useHistory, useLocation } from 'react-router-dom';
import TextInput from '../../components/TextInput/TextInput';
import { useCallback, useState } from 'react';
import { VerifySSN, loginVaribages } from '../../service/loginService';
import {
	getSessionData,
	logoutSession,
	saveRefreshToken,
	setSessionItem,
	useCongnitoUser,
	useCurrentProperty,
	useGlobalContext
} from '../../hooks';
import { ROUTE_CONSTANTS, TOKENS } from '../../constants';
import PopupDrawer from '../../components/PopupDrawer';
import { Ipopup, UserStatusInCMS } from '../../types';
import InputButton from '../../components/InputField/InputButton';

const VerifySsn = () => {
	const { accessToken } = useCongnitoUser();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [ssnNumber, setSsnNumber] = useState<string>('');
	const [ssnError, setSsnError] = useState<string>('');
	const [showModal, setShowModal] = useState<boolean>(false);
	const [errorPopupData, setErrorPopupData] = useState<Ipopup>({
		positiveButtonText: '',
		description: '',
		positiveCallback: () => {
			//
		}
	});

	const location: {
		state: {
			globalId: '';
		};
	} = useLocation();
	const history = useHistory();
	const currentProperty = useCurrentProperty();
	const { user, setUserDataInContext } = useGlobalContext();

	const handleChange = useCallback(
		(evt: React.ChangeEvent<HTMLInputElement>) => {
			if (evt.target.value.length > 4) {
				return;
			}
			setSsnNumber(evt.target.value);
		},
		[ssnNumber]
	);

	const handlePositiveButton = useCallback(async () => {
		setUserDataInContext({ isLoading: false });
		await logoutSession();
		setShowModal(false);
		history.push(ROUTE_CONSTANTS.LOBBY);
	}, [history]);

	const handleContinue = useCallback(async () => {
		if (ssnNumber.length == 0) {
			setSsnError(loginVaribages.verifySsn.validation_error.required);
			return;
		}
		if (ssnNumber.length < 4 && ssnNumber.length > 0) {
			setSsnError(loginVaribages.verifySsn.validation_error.invalid);
			return;
		}
		setIsLoading(true);
		const data = {
			globalId: location.state.globalId,
			ssnLast4: ssnNumber,
			siteCode: currentProperty.siteCode
		};
		try {
			const res = await VerifySSN(data, accessToken);
			if (res?.currStatus == 200) {
				setUserDataInContext({
					...user,
					isLoading: true
				});
				//>> Mark current user as Logged in user and move refresh token from SESSION to CACHE
				setSessionItem(TOKENS.USER_STATUS, 'RegisteredUser' as UserStatusInCMS);
				await saveRefreshToken(getSessionData(TOKENS.REFRESH) ?? '');

				history.replace(ROUTE_CONSTANTS.LOBBY);
			} else if (res?.currStatus == 400) {
				setErrorPopupData({
					...loginVaribages.verifySsn.errorPopup,
					negativeCallback: handlePositiveButton,
					positiveCallback: handlePositiveButton
				});
				setShowModal(true);
			} else if (res?.currStatus == 403) {
				setErrorPopupData({
					...loginVaribages.verifyOtp.exclusionPopup,
					negativeCallback: handlePositiveButton,
					positiveCallback: handlePositiveButton
				});
				setShowModal(true);
			} else {
				// For status 409 and other generic error
				setErrorPopupData({
					...{
						...loginVaribages.verifySsn.errorPopup,
						description: 'Some server side error!'
					},
					negativeCallback: handlePositiveButton,
					positiveCallback: handlePositiveButton
				});
				setShowModal(true);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	}, [location.state, ssnNumber]);

	const handleOnFocus = useCallback(() => {
		setSsnError('');
	}, []);

	return (
		<Layout className="verify-ssn" dataTestid="testid-verifyssn" noHeader={true} noFooter={true}>
			<div className="heading-container">
				<h2 className="heading-text headline-medium">{loginVaribages.verifySsn.heading}</h2>
				<label className="secondary-heading-text body-text-medium">
					{loginVaribages.verifySsn.subHeading}
				</label>
			</div>
			<div className="input-box">
				<p className="input-masked-text label-large">{loginVaribages.verifySsn.maskingValue}</p>
				<TextInput
					id="ssnNumber"
					inputMode="numeric"
					autoFocus={true}
					value={ssnNumber}
					onFocus={handleOnFocus}
					type={'number'}
					placeholder={''}
					maxLength={4}
					label={loginVaribages.verifySsn.inputHeading}
					onChange={handleChange}
					required
					datatestid="testinput-ssnnumber"
				/>
			</div>
			<p className="error-msg" data-testid="testid-error">
				{ssnError}
			</p>
			<div className="continue-action">
				<InputButton
					disabled={isLoading}
					varient="primary"
					datatestid="testid-confirm"
					onClick={handleContinue}
				>
					{loginVaribages.verifySsn.btn.Continue}
				</InputButton>
			</div>
			{showModal && (
				<PopupDrawer
					errorImage={
						<div className="error-icon-box">
							<i
								className={`icon-modal-view`}
								style={{
									backgroundImage: `url(${errorPopupData.icon})`
								}}
							></i>
						</div>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">{errorPopupData.desc_heading}</h2>
					}
					data={{ ...errorPopupData }}
					handleNegativeButton={errorPopupData.negativeCallback}
					handlePositiveButton={errorPopupData.positiveCallback}
				/>
			)}
		</Layout>
	);
};

export default VerifySsn;
