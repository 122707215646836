import { createContext } from 'react';
import {
	AppContract,
	BiometricStatus,
	FaceIdConnectionType,
	ILDFeatureList,
	Theme,
	Registraion_Stage,
	CardLessConnectionType,
	CardlessConnectionStatus,
	setConnectionValueType
} from '../types';
import { propertyFallbackList } from '../service/appService';

export const GlobalContext = createContext<AppContract>({
	user: {
		comp: '0',
		userName: '',
		email: '',
		mobileNumber: '',
		points: '',
		tier: '',
		lastUpdated: '',
		playerId: '',
		gender: '',
		globalId: '',
		firstName: '',
		lastName: '',
		pointsToNextTier: '',
		isPlayerActive: false,
		isLoading: true
	},
	global: {
		propertyList: [],
		currentProperty: {
			...propertyFallbackList[0]
		},
		pageVisitCount: {},
		isAuthenticated: false,
		token: ''
	},
	registration: {
		stage: Registraion_Stage.DEFAULT,
		email: '',
		playerID: '',
		globalId: '',
		queryId: '',
		licenseScan: {
			frontImage: '',
			backImage: '',
			formData: {
				firstName: '',
				lastName: '',
				idNumber: '',
				dob: '',
				idExpDate: '',
				address1: '',
				address2: '',
				city: '',
				state: '',
				zip: '',
				idIssDate: ''
			}
		},
		profileScan: {
			profileImage: ''
		},
		bonusState: []
	},
	setUserDataInContext: function () {
		// do nothing.
	}
});

export const RNContext = createContext<
	BiometricStatus & CardlessConnectionStatus & setConnectionValueType
>({
	connectionStatus: 'SCANNING',
	isFaceIdAvailable: false,
	isFaceIdSetup: false,
	globalId: '',
	postMessagetoNative: (type: FaceIdConnectionType | CardLessConnectionType, data?: any) => {
		// emmpty function here
		console.log(type, data);
	},
	setConnectionValue: (value: any) => {
		console.log('set connection value', value);
	}
});

export const ThemeContext = createContext<{ theme: Theme; toggleTheme: (theme?: Theme) => void }>({
	theme: 'dark',
	toggleTheme: (theme?: Theme) => {
		console.log('toggle', theme);
	}
});
export const localLDContext = createContext<ILDFeatureList>({
	isFaceIDFeatureAvailable: false,
	isLightThemeFeatureAvailable: false,
	isCountrySwitchFeatureAvailable: true,
	isCardlessFeatureAvailable: false
});
