import { useState, useCallback } from 'react';
import OtpInput from '../../components/InputField/OtpInput';
import InputButton from '../../components/InputField/InputButton';
import './index.scss';
import { logoutSession, useCongnitoUser, useCurrentProperty } from '../../hooks';
import {
	loginVaribages,
	playerIdMaxlength,
	verifyPinAndLinkAccount
} from '../../service/loginService';
import { ROUTE_CONSTANTS } from '../../constants';
import { useHistory } from 'react-router';
import PopupDrawer from '../../components/PopupDrawer';
import TextInput from '../../components/TextInput/TextInput';
import { Ipopup } from '../../types';

const VerifyPin = ({
	mobileNumber,
	playerData,
	onVerificationSuccess,
	setPlayerData,
	isPlayerIDAvailable
}: {
	mobileNumber: string;
	playerData: {
		playerId: string;
	};
	onVerificationSuccess: () => void;
	setPlayerData: (data: { playerId: string }) => void;
	isPlayerIDAvailable: boolean;
}) => {
	const { pinVerification, verifyOtp } = loginVaribages;
	const [errorMsg, setErrorMsg] = useState('');
	const [PIN, setPINState] = useState('');
	const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
	const [otpAutoFocus, setOtpAutoFocus] = useState<boolean>(false);
	const [errorPopupData, setErrorPopupData] = useState<Ipopup>({
		positiveButtonText: '',
		description: '',
		positiveCallback: () => {
			//
		}
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [playerIderror, setPlayerIdError] = useState<string>('');
	const { accessToken } = useCongnitoUser();
	const history = useHistory();
	const currentProperty = useCurrentProperty();

	const handlePositiveButton = useCallback(async () => {
		// User excluded/blocked > popup closed
		await logoutSession();
		setShowErrorPopup(false);
		history.push(ROUTE_CONSTANTS.LOBBY);
	}, [history]);

	const handleContinue = useCallback(
		async (event: any) => {
			event.preventDefault();
			if (!playerData?.playerId || playerData?.playerId.length === 0) {
				setPlayerIdError(pinVerification.playerIDError);
				return;
			}
			if (PIN.length < 4) {
				setErrorMsg(pinVerification.errorMsg);
				return;
			}
			setIsLoading(true);
			setErrorMsg('');
			try {
				const res = await verifyPinAndLinkAccount(
					{
						phoneNumber: mobileNumber,
						pin: PIN,
						playerId: playerData?.playerId,
						siteCode: currentProperty.siteCode
					},
					accessToken
				);
				if (res.currStatus == 200) {
					onVerificationSuccess();
				} else if (res.currStatus == 403) {
					setPINState('');
					if (res?.attemptRemaining) {
						setErrorMsg(
							`${res?.message}.` // ${res?.attemptRemaining} ${pinVerification.remainingAttemptMsg}`
						);
					} else {
						setErrorPopupData({
							...verifyOtp.exclusionPopup,
							positiveCallback: handlePositiveButton
						});
						setShowErrorPopup(true);
					}
				} else if (res.currStatus == 406) {
					setErrorPopupData({
						...pinVerification.errorPopup,
						positiveCallback: handlePositiveButton
					});
					setShowErrorPopup(true);
				} else if (res.currStatus == 404) {
					setPlayerIdError(res?.message);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setIsLoading(false);
			}
		},
		[errorMsg, PIN, setErrorMsg, playerData?.playerId]
	);

	const handleOnPlayerIdFocus = useCallback(() => {
		setErrorMsg('');
		setPlayerIdError('');
	}, [errorMsg]);

	const handleOnOTPFocus = useCallback(() => {
		setOtpAutoFocus(true);
	}, [errorMsg, handleOnPlayerIdFocus]);

	const handleChange = useCallback(
		(pin: string) => {
			setErrorMsg('');
			setPINState(pin);
		},
		[PIN]
	);

	const handlePlayerIdChnage = useCallback(
		(evt: React.ChangeEvent<HTMLInputElement>) => {
			if (evt.target.value.length > playerIdMaxlength) {
				return;
			}
			console.log(evt.target.value);
			setPlayerData({ ...playerData, playerId: evt.target.value });
		},
		[setPlayerData]
	);

	const togglePlayerIdPopup = useCallback(() => {
		setShowErrorPopup(false);
		setErrorPopupData({
			positiveButtonText: '',
			description: '',
			positiveCallback: () => {
				//
			}
		});
	}, [setShowErrorPopup]);

	const handleGetPlayerID = useCallback(() => {
		setErrorPopupData({
			...pinVerification.getPlayerIdPopup,
			negativeCallback: togglePlayerIdPopup,
			positiveCallback: togglePlayerIdPopup
		});
		setShowErrorPopup(true);
	}, [setErrorPopupData]);

	return (
		<>
			<div className="verify-pin form-section" data-testid="verify-pin">
				<h1 className="headline-medium heading">
					{isPlayerIDAvailable ? pinVerification.verify_heading : pinVerification.heading}
				</h1>
				{isPlayerIDAvailable ? (
					<p className="sub-heading body-text-medium">{`${pinVerification.subHeading} [${playerData?.playerId}] ${pinVerification.subHeadingVerify}`}</p>
				) : (
					<>
						<p className="sub-heading body-text-medium">{pinVerification.multipleIdHeading}</p>
						<p className="sub-heading body-text-medium">
							{pinVerification.dont_know_id}
							<span className="active" data-testid="testid-getid" onClick={handleGetPlayerID}>
								{pinVerification.clickHere}
							</span>
						</p>
					</>
				)}
				<form noValidate onSubmit={handleContinue}>
					{!isPlayerIDAvailable && (
						<>
							<div>
								<TextInput
									id="playerId"
									value={playerData?.playerId}
									autoFocus={true}
									type={'number'}
									placeholder={'Player ID'}
									label={'Player ID'}
									onFocus={handleOnPlayerIdFocus}
									onChange={handlePlayerIdChnage}
									required
									datatestid="testinput-playerId"
								/>
							</div>
							<p className="label-medium">{pinVerification.accountPin}</p>
						</>
					)}
					<div className={`otp-container ${!isPlayerIDAvailable && 'heading'}`}>
						<OtpInput
							onFocus={handleOnOTPFocus}
							length={4}
							autoFocus={otpAutoFocus}
							inputClassName={`single-otpInput ${errorMsg ? 'error' : ''}`}
							onChangeOTP={handleChange}
							isInputRef={true}
							otp={PIN}
							isMasked={true}
						/>
					</div>
					{playerIderror && (
						<div className="error-container">
							<p data-testid="error-msg" className="error-msg body-text-medium">
								{playerIderror}
							</p>
						</div>
					)}
					{errorMsg && (
						<div className="error-container">
							<i className="icon-info" />
							<p data-testid="error-msg" className="error-msg body-text-medium">
								{errorMsg}
							</p>
						</div>
					)}
					<InputButton disabled={isLoading} varient="primary" datatestid="continue-btn">
						{pinVerification.btn.Continue}
					</InputButton>
				</form>
			</div>
			{showErrorPopup && (
				<PopupDrawer
					errorImage={
						<div className="error-icon-box">
							<i
								className={`icon-modal-view`}
								style={{ backgroundImage: `url(${errorPopupData.icon})` }}
							/>
						</div>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">{errorPopupData.desc_heading}</h2>
					}
					subDesc={
						errorPopupData?.sub_desc?.phone_no ? (
							<div className="contact-container">
								<div className="contact-info">
									<i className="icon-phone" />
									<p className="contact-desc body-text-medium">
										{errorPopupData.sub_desc?.phone_no}
									</p>
								</div>
								<div className="contact-info">
									<i className="icon-location" />
									<p className="body-text-medium">{errorPopupData.sub_desc?.loc}</p>
								</div>
							</div>
						) : null
					}
					data={errorPopupData}
					handleNegativeButton={errorPopupData.negativeCallback}
					handlePositiveButton={errorPopupData.positiveCallback}
				/>
			)}
		</>
	);
};

export default VerifyPin;
