export const API_BASE_DOMAIN = 'https://casinos.ballys.com';
export const API_BASE_PATHNAME = '/lake-tahoe/files/6434';
export const API_ROOT = `${API_BASE_DOMAIN}${API_BASE_PATHNAME}`;

export const API_BASE_URL = `https://casinos.ballys.com/chicago/files/6823`;

export const GET_ACCOUNT_ACTIVATION_POPUP = 'widget1427096';
export const GET_PROMOTIONS = 'widget1223030';
export const GET_RESTAURANTS = 'widget1222969';
export const GET_DINING_BANNER = 'widget1222965';
export const GET_DINING_INFORMATION = 'widget1397483';
export const GET_CASINO_GALLARY = 'widget1412952';
export const GET_HOTEL_BANNER_IMG = 'widget1223128';
export const GET_HOTEL_BANNER_DESCRIPTION = 'widget1397482';
export const GET_CASINO_BANNER_IMG = 'widget1222937';
export const GET_CASINO_BANNER_DESCRIPTION = 'widget1397480';
export const GET_DISCLAIMER_LOGO = 'widget1427095';
export const GET_DISCLAIMER_LOGO_LIGHT_THEME = 'widget1434666';
export const GET_DISCLAIMERTEXT = 'widget1427490';
export const GET_REWARD_IMAGE = 'widget1397705';
export const GET_REWARD_DESCRIPTION = 'widget1397704';
export const GET_PROFILE_TIER_CARD = 'widget1398716';

export const GET_HOME_REWARDS = 'widget1440679';
export const GET_HOME_RAILS = 'widget1425820';
export const GET_OPENING_DETAILS = 'widget1425847';
export const GET_EXPERIENCE_DETAILS = 'widget1425796';
