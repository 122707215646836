import { useCallback, useRef, useState } from 'react';
import { IPopupDrawer } from '../../types';
import './PopupDrawer.scss';
import InputButton from '../InputField/InputButton';

const PopupDrawer = (props: IPopupDrawer) => {
	const { handleNegativeButton, data, handlePositiveButton, closeOnOverlay } = props;
	const [isPositiveButtonDisable, setIsPositiveButtonDisable] = useState(false);
	const overlayRef = useRef<HTMLDivElement>(null);

	const handlePositiveButtonClick = useCallback(() => {
		setIsPositiveButtonDisable(true);
		handlePositiveButton();
	}, [handlePositiveButton]);

	const handleOverlayClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			if ((e.target as HTMLDivElement).id === overlayRef.current?.id) {
				if (closeOnOverlay && handleNegativeButton) {
					handleNegativeButton();
					e.preventDefault();
				}
			}
		},
		[handleNegativeButton, closeOnOverlay]
	);

	return (
		<div
			id="myModal"
			className="modal"
			data-testid="popup-drawer"
			ref={overlayRef}
			onClick={handleOverlayClick}
		>
			<div className="modal-content">
				<div className="modal-header">
					<label className="popup-header-text">{data.title}</label>
					<button
						className="icon-plus cross-button"
						data-testid="close-popup-drawer"
						onClick={handleNegativeButton}
					/>
				</div>
				<div className="modal-body">
					{props.errorImage}
					<div>
						{props.descHeading}
						<p className={`popup-description-text ${data.desc_text_style}`}>{data.description}</p>
						{props.subDesc}
					</div>
				</div>
				<div className="modal-footer">
					{data.negativeButtonText && (
						<InputButton
							className="popup-button negative-button"
							varient="secondary"
							onClick={handleNegativeButton}
							datatestid="negativeButton-testid"
						>
							{data.negativeButtonText}
						</InputButton>
					)}
					<InputButton
						className="popup-button positive-button"
						disabled={isPositiveButtonDisable}
						varient="primary"
						onClick={handlePositiveButtonClick}
						datatestid="positiveButton-testid"
					>
						{data.positiveButtonText}
					</InputButton>
				</div>
			</div>
		</div>
	);
};

export default PopupDrawer;
