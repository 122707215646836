import { useCallback, FC } from 'react';
import './Header.scss';
import { useGlobalContext } from '../../hooks';
import { NavLink } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '../../constants';

const LoginStatus: FC = () => {
	const { user } = useGlobalContext();

	const getLoginButtonOrAmount = useCallback(() => {
		if (user?.firstName) {
			return (
				<NavLink
					to={ROUTE_CONSTANTS.PROFILE.LANDING}
					className="profile-container title-large"
					data-testid="profile-container"
				>{`${user?.firstName.charAt(0)}${user?.lastName?.charAt(0)}`}</NavLink>
			);
		} else {
			return (
				<NavLink
					to={ROUTE_CONSTANTS.LOGIN_CONGNITO.LANDING}
					className="profile-container h-v-center"
				>
					<i className="icon-profile" data-testid="profile-icon" />
				</NavLink>
			);
		}
	}, [user]);

	return <>{getLoginButtonOrAmount()}</>;
};

export default LoginStatus;
