import React, { memo, useRef, useLayoutEffect, useEffect } from 'react';

export interface SingleOTPInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	focus?: boolean;
	// isSetPin?: boolean;
	isInputRef?: boolean;
	isMasked?: boolean;
}

export function SingleOTPInputComponent(props: SingleOTPInputProps) {
	const { focus, autoFocus, isInputRef = true, isMasked = false, ...rest } = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const inputconfirmRef = useRef<HTMLInputElement>(null);

	function usePrevious<T>(value?: T) {
		const ref = useRef<T>();

		useEffect(() => {
			ref.current = value;
		}, [value]);

		// Return previous value
		return ref.current;
	}
	const prevFocus = usePrevious(!focus);
	useLayoutEffect(() => {
		if (inputRef.current && focus && autoFocus) {
			inputRef.current.focus();
			if (focus !== prevFocus) {
				inputRef.current.focus();
			}
		} else if (inputconfirmRef.current && focus && !autoFocus) {
			inputconfirmRef.current.focus();
			if (focus !== prevFocus) {
				inputconfirmRef.current.focus();
			}
		}
	}, [autoFocus, focus, prevFocus]);

	return (
		<input
			ref={isInputRef ? inputRef : inputconfirmRef}
			{...rest}
			type={isMasked ? 'password' : 'text'}
			inputMode="numeric"
			placeholder=" "
			autoComplete="one-time-code"
		/>
	);
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
