const { REACT_APP_USER_SERVICE_BASE_URL } = process.env;
import { API_METHOD, getRequestOptions } from '../constants';
import { IIDScanResponse, IIdFormData } from '../types';

export const patronRegVerbiage = {
	loginPageLabel: {
		inputLabel: {
			label: 'Login / Signup'
		},
		loginInputPlaceholder: {
			label: 'Enter your Email or Player ID'
		},
		coin_dice_img: {
			url: '/assets/coin-dice.png'
		},
		cardImg: { url: '/assets/card-2.png' },
		errorPop: {
			img: '/assets/confirm-img.png',
			desc_heading: 'Account Not Found',
			description:
				'We were not able to find an account associated with your Player ID. We recommend you to register with your email id to continue.',
			positiveButtonText: 'OK',
			negativeButtonText: 'CANCEL'
		},
		errorUserMsg: {
			label: 'User not found'
		},
		validationError: {
			requiredField: 'Email field required',
			emailInValid: 'Email is not valid.'
		},
		duplicateEmail: {
			woops: 'Whoops! Your State ID details match an existing Casino Rewards Club account with',
			playHideAndSeek:
				"If your password seems to be playing hide and seek, dont gamble on it. Just tap the 'Forgot Password' button to reset and you'll be back earning rewards in no time!",
			forgotPassword: 'Forgot PASSWORD',
			login: 'Login'
		}
	},
	regiesterPageLabel: {
		heading: {
			label: 'Win up to $100 in Freeplay!'
		},
		subHeading: {
			label:
				'Spin the wheel and win $5 to $100, after completing each step in the Registration Process'
		},
		action: {
			label: 'Register Now',
			link: ''
		}
	},
	regiesterPageImage: {
		LeftCoin: {
			url: '/assets/coin-left.png'
		},
		spinWheel: {
			url_Ring: '/assets/ring.png',
			url_Spin: '/assets/spin.png',
			url_bg: '/assets/bonusbg.png'
		},
		rightdice: {
			url: '/assets/dice-right.png'
		}
	},
	verifyPinLabel: {
		heading: {
			label: 'Enter your 4-digit PIN Code'
		},
		subheading: {
			label: 'Enter your PIN to login to this application'
		},
		inputLabel: {
			label: 'Enter PIN Code'
		},
		errorHeading: {
			label: 'Invalid PIN'
		},
		errorSubheading: {
			label: 'more attempt(s) remaining before the account is locked'
		}
	},
	errorPageLabel: {
		heading: {
			label: 'Incorrect PIN'
		},
		subheading: {
			label:
				'Your account has temporarily been Locked. Please visit the Players Club to reset your PIN.'
		},
		actionLink: {
			label: 'Unlock Account'
		}
	},
	winBonusPageLabel: {
		heading: {
			label: 'Spin and Win',
			subHeading: 'Its your chance to Win bonus up to 2000 ',
			know: ' Know the ',
			rules: 'rules & regulations',
			beforeSpin: ', before spin.'
		},
		subHeading: {
			label:
				'It’s your chance to win a bonus up to $100. Know the rules & regulations before spinning.'
		},
		action: {
			label: 'TAP TO SPIN'
		},
		spinNumber: {
			url: '/assets/spin-number.png'
		},
		spinIndecator: {
			label: 'Tap the Bally Bucks token to spin the wheel!'
		},
		spinAudio: {
			url: '/assets/digital_prize_wheel.mp3'
		},
		congratsMsg: {
			msg: `Congratulations! You won 2000`,
			msgDesc: `. Don't forget to activate your account and collect your rewards at the property!`
		}
	},
	congratsPageLabel: {
		imgheading: {
			label: 'Congratulations !'
		},
		imgSubHeading: {
			defaultLabel: 'You have won a bonus!',
			verifiedLabel: 'Your profile has been Verified'
		},
		heading: {
			label: 'You Won $10'
		},
		subHeading: {
			defaultLabel: 'Congrats! The $10 that you won will be credited to your account',
			labelForIdScan: 'Almost there! Complete the photo selfie section to spin the wheel again!',
			labelForIdScanWithoutSelfie:
				'Almost there! Just a few more steps to complete your registration and spin the wheel again!',
			labelForProfileScan: 'Last step! Complete your registration to spin the wheel again!'
		}
	},
	IDScan: {
		heading: {
			label: 'ID scanning process'
		},
		frontCamera: {
			label: 'FRONT SIDE of your ID',
			subHeading: 'Scan the FRONT side'
		},
		backCamera: {
			label: 'BACK SIDE of your ID',
			subHeading: 'Scan the BACK side'
		},
		action: {
			continue: 'CONTINUE',
			cancel: 'CANCEL',
			continueProgress: 'Hang tight!'
		},
		errorPop: {
			title: 'Verification Failed !',
			img: '/assets/errorimage.png',
			desc_heading: 'Oops ..',
			subHeading: 'Well, shucks!',
			description: '',
			positiveButtonText: 'CONTINUE',
			desc_color: 'desc-color',
			something_Wrong: 'Something Went Wrong, Please Try Again',
			invalidIdImage_msg:
				'We had a little slip-up trying to grab your ID. Give it another whirl, and our apologies for the hiccup!'
		},
		camera: {
			headingLabelFront: 'Capture front side of your ID',
			headingLabelBack: 'Capture back side of your ID',
			subHeading: 'Position the ID within the green box and then capture',
			capturedSuccess: 'Captured Successfully !'
		}
	},
	profileVerification: {
		heading: {
			label: 'Capture a photo of yourself',
			subHeading: `Make sure to hold device at eye level to center your face and also ensure that you
			don't close your eyes while capturing the selfie !`
			// label: "Hey there! It's selfie time!",
			// subHeading: `Strike a pose and show us your dazzling smile. Just tap the button below and let the magic happen. Ready? Lights, camera, action!`
		},
		url_Selfie: '/assets/selfie.png',
		instructions: {
			brightness: 'Ensure bright surroundings',
			camera: 'Do not shake the camera',
			flash: 'Do not use flash'
		},
		checkbox: {
			label: 'By pressing “Capture Selfie” button, I agree to provide ',
			hightlighted: 'consent to biometrics screening'
		},
		action: {
			capture: 'CAPTURE SELFIE',
			reCapture: 'RECAPTURE',
			confirm: 'CONFIRM',
			cancel: 'CANCEL'
		},
		camera: {
			subHeading: `Strike a pose and show us your dazzling smile. Just tap the button below and let the magic happen. Ready? Lights, camera, action!`,
			label: `Hey there! It's selfie time!`,
			captured: 'Captured Successfully !',
			url_overlay: '/assets/overlay.png'
		}
	},
	selfieProcessingLabel: {
		heading: {
			label: 'Hang tight!',
			verifyingLabel: 'Hang tight! Summoning Sherlock Holmes for a quick investigation!'
		},
		subHeading: {
			label: 'Summoning Sherlock Holmes for a quick investigation!',
			verifyingLabel: `KYC in progress: Our Crew is validating at lightning speed! ${String.fromCharCode(
				9889
			)} Hang tight & get ready to roll!`
		},
		errorPop: {
			title: 'Verification Failed !',
			img: '/assets/errorimage.png',
			desc_heading: 'Oops ..',
			subHeading: 'We were unable to verify your identity',
			description: '',
			positiveButtonText: 'CONTINUE',
			desc_color: 'desc-color',
			something_Wrong: 'Something Went Wrong, Please Try Again',
			invalidIdImage_msg:
				'Please visit the Players Club/Cashier booth with a valid State ID to register for an account'
		}
	},
	confirmIdentity: {
		heading: {
			label: 'Confirm your identity'
		},
		subHeading: {
			label: `You have 10 minutes to answer these questions which are used only for verification purposes. If the question is not relevant to you, you can select the “None of the above” option`
		}
	},
	unlockAccountPopError: {
		title: 'Account Locked',
		description:
			'Sorry, too many failed login attempts. Please visit the Players Club/Cashier with a valid State ID to unlock your account.',
		sub_desc: {
			phone_no: '1-800-BE-LUCKY',
			addr_1: 'Hours of Operation - 24/7',
			loc: '55 Hwy 50, Stateline, NV 89449'
		},
		positiveButtonText: 'OK',
		desc_text_style: 'text-left'
	},
	unlockRewardPopError: {
		title: 'Unlock Rewards',
		description:
			'Please visit the Players Club/Cashier with a valid State ID to unlock additional rewards and redeem your bonus',
		sub_desc: {
			phone_no: '1-800-BE-LUCKY',
			addr_1: 'Hours of Operation - 24/7',
			loc: '55 Hwy 50, Stateline, NV 89449'
		},
		positiveButtonText: 'OK',
		desc_text_style: 'text-left'
	},
	unlockRewardLabel: {
		welcomeText: {
			label: 'Welcome to the BallyVerse !'
		},
		user_man_img: {
			url: '/assets/user-men-img.png'
		},
		bonus_heading: {
			label: 'BONUS RECEIVED'
		},
		reedeemLabel: {
			heading: 'Redeem Bonus -',
			QR_img_URL: '/assets/QR-code.png',
			subheading: 'Scan the QR to redeem'
		},
		unlock_link: {
			label: 'Unlock rewards'
		}
	},
	setPinCodeLabel: {
		heading: {
			label: 'Set your 4-digit PIN code'
		},
		subheading: {
			label: 'You will be prompted to enter this PIN whenever you login to this application'
		},
		inputlabel: {
			setPinlabel: 'Enter PIN Code',
			conFirmPinLabel: 'Confirm PIN Code',
			errorPinMsg: 'PIN and confirm PIN does not match.'
		},
		action_btn: {
			positive_btn: 'set pin',
			negative_btn: 'Cancel'
		}
	}
};

export const getformData = (data: IIdFormData | any) => {
	return {
		firstName: data?.firstName,
		lastName: data?.lastName,
		middleName: data?.middleName,
		address1: data?.address1,
		address2: data?.address2,
		city: data?.city,
		state: data?.state,
		zip: data?.zip,
		dob: data?.dob.replace(new RegExp(/-/g), '/'),
		idNumber: data?.idNumber,
		idIssDate: data?.idIssDate,
		idExpDate: data?.idExpDate
	};
};

export const isScanIdDataUnchanged = (currentData: IIdFormData, previousData: IIdFormData) => {
	if (
		currentData.firstName == previousData.firstName &&
		currentData.lastName == previousData.lastName &&
		currentData.address1 == previousData.address1 &&
		currentData.address2 == previousData.address2 &&
		currentData.city == previousData.city &&
		currentData.state == previousData.state &&
		currentData.zip == previousData.zip
	) {
		return true;
	}
	return false;
};
export const getIdVerificationPayload = (data: IIdFormData) => {
	return {
		firstName: data.firstName,
		lastName: data.lastName,
		middleName: data.middleName,
		address1: data.address1,
		address2: data.address2,
		city: data.city,
		state: data.state,
		zip: data.zip
	};
};

export const checkIDScanValidation = (data: IIDScanResponse) => {
	if (data.globalId && data.queryId && data.idNumber && data.idIssDate && data.idExpDate) {
		return true;
	}
	return false;
};

export const verifyPlayerIdPin = async (otpNumber: string, playerID: string, siteCode: string) => {
	// dummy api
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}users/verifypin`, {
		...getRequestOptions({ pin: otpNumber, playerID: playerID, siteCode: siteCode }),
		method: API_METHOD.POST
	})
		.then(async (res) => {
			if (res?.status) {
				const response = await res.json();
				return { ...response, currStatus: res.status };
			} else {
				const response = await res.json();
				return { ...response };
			}
		})
		.catch((error) => {
			// if in a loop can also log which url failed;
			console.log('error: ', error);
			throw new error(error);
		});
};

//login screen

export const serachUser = async (userName: string | number, siteCode: string) => {
	return fetch(
		`${REACT_APP_USER_SERVICE_BASE_URL}users/search?user=${userName}&siteCode=${siteCode}`,
		{
			...getRequestOptions(null)
		}
	).then(async (res) => {
		if (res?.status !== 200) return { statusCode: res.status, ...res.json() };
		else {
			const response = await res.json();
			return { ...response, statusCode: res.status };
		}
	});
};

export const uploadImage = async (data: {
	email: string;
	type: 'frontImage' | 'backImage' | 'faceImage';
	base64Image: string;
}) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}onboard/upload`, {
		...getRequestOptions(data),
		method: API_METHOD.POST
	}).then((res) => {
		return res.json();
	});
};

export const verifyId = async (data: {
	email: string;
	image: string;
	backImage: string;
	siteCode: string;
}) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}onboard/scanid`, {
		...getRequestOptions(data),
		method: API_METHOD.POST
	}).then((res) => {
		return res.json();
	});
};

export const confirmIdDetails = async (globalId: string, data: any) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}users/updateprofile/${globalId}`, {
		...getRequestOptions(data),
		method: API_METHOD.PUT
	}).then((res) => {
		if (!res.ok) {
			throw res;
		}
		return res.json();
	});
};

export const verifySelfie = async (payload: {
	globalId: string;
	queryId: string | number;
	faceImage: string;
}) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}onboard/verifyid`, {
		...getRequestOptions(payload),
		method: API_METHOD.POST
	}).then((res) => {
		return res.json();
	});
};

export const verifyQuestion = async (verifyPayload: { [keys: string]: string }) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}onboard/submitiq`, {
		...getRequestOptions(verifyPayload),
		method: API_METHOD.POST
	}).then((res) => {
		if (!res.ok) {
			throw res;
		}
		return res.json();
	});
};

export const setPinCodeAPI = async (globalId: string, pin: string, siteCode: string) => {
	// dummy api
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}/users/setpin`, {
		...getRequestOptions({
			globalId: globalId,
			pin: pin,
			siteCode: siteCode
		}),
		method: API_METHOD.POST
	})
		.then(async (res) => {
			if (res?.status !== 200) {
				return res.json().then((response) => {
					//handle errors here
					return response;
				});
			} else {
				return await res.json();
			}
		})
		.catch((error) => {
			// if in a loop can also log which url failed;
			console.log('error: ', error);
			throw new error(error);
		});
};

export const getKBAQuestion = async (globalId: string) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}onboard/kyc/${globalId}`, {
		...getRequestOptions(null)
	}).then((res) => {
		return res.json();
	});
};
