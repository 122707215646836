import { useCurrentProperty } from '../../hooks';
import './index.scss';

const BrandLogo = ({ isVertical }: { isVertical: boolean }) => {
	const currentProperty = useCurrentProperty();

	return (
		<span className={`brand-logo ${isVertical ? 'vertical' : 'horizontal'}`}>
			<i className="icon-logo"></i>
			<span className="logo-text">{currentProperty.siteName}</span>
		</span>
	);
};

export default BrandLogo;
