import { useCallback } from 'react';
import { NAME_REGEX } from '../../constants';
import TextInput from '../TextInput/TextInput';
import './UserDetailsForm.scss';

const UserDetailsForm = (props: any) => {
	const { idFormData, handleChange, isNameDisabled = false } = props;

	const handleWithValidation = useCallback(
		(evt: React.ChangeEvent<HTMLInputElement>) => {
			if (evt.target.value.match(NAME_REGEX) || evt.target.value == '') {
				handleChange(evt);
			}
		},
		[idFormData]
	);

	return (
		<div className="userform-container">
			<div className="medium">
				<TextInput
					id="firstName"
					value={idFormData?.firstName}
					type={'text'}
					placeholder={'First Name'}
					label={'First Name'}
					onChange={handleWithValidation}
					required
					datatestid="testinput-fname"
					disabled={isNameDisabled}
				/>
				<TextInput
					id={'lastName'}
					value={idFormData?.lastName}
					type={'text'}
					placeholder={'Last Name'}
					label={'Last Name'}
					onChange={handleWithValidation}
					required
					disabled={isNameDisabled}
				/>
			</div>
			<TextInput
				disabled
				type={'text'}
				placeholder={'ID Number'}
				label={'ID Number'}
				datatestid="testid-id"
				value={idFormData?.idNumber}
				required
			/>
			<div className="medium">
				<TextInput
					disabled
					value={idFormData?.idExpDate}
					type={'text'}
					placeholder={'Expiration Date'}
					label={'Expiration Date'}
					datatestid="testid-expdate"
					required
				/>
				<TextInput
					disabled
					type={'text'}
					placeholder={'Date of Birth'}
					label={'Date of Birth'}
					datatestid="testid-dob"
					value={idFormData?.dob}
					required
				/>
			</div>
			<TextInput
				id={'address1'}
				value={idFormData?.address1}
				type={'text'}
				placeholder={'Enter your address 1'}
				label={'Street 1'}
				onChange={handleChange}
				required
			/>
			<TextInput
				id={'address2'}
				value={idFormData?.address2}
				type={'text'}
				placeholder={'Enter your address 2'}
				label={'Street 2'}
				onChange={handleChange}
			/>
			<TextInput
				id={'city'}
				value={idFormData?.city}
				type={'text'}
				placeholder={'City'}
				label={'City'}
				onChange={handleChange}
				required
			/>
			<div className="medium">
				<TextInput
					id={'state'}
					value={idFormData?.state}
					type={'text'}
					placeholder={'State'}
					label={'State'}
					onChange={handleChange}
					required
				/>
				<TextInput
					id={'zip'}
					value={idFormData?.zip}
					type={'text'}
					placeholder={'Zip Code'}
					label={'Zip Code'}
					onChange={handleChange}
					required
				/>
			</div>
		</div>
	);
};

export default UserDetailsForm;
