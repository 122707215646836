const DateTimeDisplay = ({ value, type, isDanger }: any) => {
	return (
		<div className={isDanger ? 'countdown danger' : 'countdown'}>
			<p className="title-medium">
				{value.toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}
			</p>
			<span>{type}</span>
		</div>
	);
};

export default DateTimeDisplay;
