import './ProfileTierBanner.scss';
import { useGlobalContext } from '../../hooks';
import { LoaderType } from '../../types';
import withSkeltonLoader from '../../hoc/SkeltonLoader';

export const BucksBalance = () => {
	const { user } = useGlobalContext();
	return (
		<div className="balance-container">
			<i className="icon-coin active" />
			<p className="balance-text label-large">
				Bally Bucks Balance:
				<span className="balance-amount label-large prominant">{`${user?.comp ?? 0}`}</span>
			</p>
		</div>
	);
};

export default withSkeltonLoader(BucksBalance, LoaderType.title);
