import Grid from '../../components/Grid/Grid';
import RestaurantItem from '../../components/RestaurantItem/RestaurantItem';
import withSkeltonLoader from '../../hoc/SkeltonLoader';
import { LoaderType } from '../../types';
import './index.scss';

const Experience = (props: any) => {
	const { experienceDetails } = props;

	return (
		<>
			{experienceDetails?.map((experineceData: any, index: number) => {
				const k = `restaurant-item-${index}`;
				return (
					<div key={k}>
						<RestaurantItem
							external={true}
							img={experineceData.CTAImage.FullURL}
							title={experineceData.CTAHeading}
							redirectTo={experineceData.CTAImageLink}
						/>
						<div className="section-gap">
							<Grid
								links={experineceData.CTAButtons}
								icons={experineceData.CTASubheading.split(',')}
							/>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default withSkeltonLoader(Experience, LoaderType.experience);
