import { useLocation } from 'react-router';
import { getDropDownHeader } from '../../service/appService';
import './PropertyPopup.scss';
import { useGlobalContext } from '../../hooks';
import { useCallback, useState } from 'react';

const PropertyPopup = ({ handleToggleProperty }: { handleToggleProperty: () => void }) => {
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);

	const {
		global: { propertyList },
		global
	} = useGlobalContext();
	const isPathNameActive = (baseUrl: string) => {
		return window.location.pathname.startsWith(baseUrl);
	};
	const handleLoading = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			setIsLoading(!isLoading);
		},
		[isLoading]
	);

	console.log('propertyList', global);
	return (
		<div
			id="myModal"
			className="modal property-popup"
			data-testid="property-popup"
			onClick={handleToggleProperty}
		>
			{isLoading ? (
				<div className="loading-container-property">
					<div className="loading loading-property"></div>
				</div>
			) : (
				<div className="modal-content-property">
					<div className="heading-container">
						<p className="property-heading">{getDropDownHeader}</p>
						<button
							className="icon-plus cross-button"
							data-testid="close-popup-drawer"
							onClick={handleToggleProperty}
						/>
					</div>

					<ul>
						{propertyList?.map((property, index) => {
							const k = `propertylist-${index}`;
							return (
								<li className="property-list" key={k}>
									<a
										className={`property-list-item ${
											isPathNameActive(property.baseUri) ? 'active' : ''
										} `}
										href={`${property.baseUri}${location.pathname}`}
										onClick={handleLoading}
										data-testid="property-list-anchor"
									>
										<div className="property-details">
											<img src={property?.image} className="property-img" alt="" />
											<div>
												<div className="logo-container">
													<i className="icon-logo" />
													<p className="logo-text">{property.siteName.split("Bally's")[1]}</p>
												</div>
												<p className="address">{property.address}</p>
											</div>
										</div>
										{isPathNameActive(property.baseUri) ? (
											<i className="icon-tick selected" />
										) : (
											<i className="non-selected" />
										)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default PropertyPopup;
