import './App.scss';
import { GlobalContext } from './context/GlobalContext';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import {
	getCookieValue,
	getFaceIdPopup,
	getRefreshToken,
	logoutSession,
	saveFaceIdPopup,
	setSessionItem,
	useCongnitoUser,
	useCurrentProperty,
	useLDContext,
	useThemeContext,
	useRNContext
} from './hooks';
import Lobby from './page/Lobby';
import { getProfileData } from './service/profileService';
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { AppContract, GlobalData, IProfileDetails, Ipopup, Theme, USER } from './types';
import { PLATFORM_COOKIE, ROUTE_CONSTANTS, TOKENS } from './constants';
import { getOtp, loginVaribages, OTP_ACTION } from './service/loginService';
import PopupDrawer from './components/PopupDrawer';
import LoginCognito from './page/LoginCognito';
import VerifyOtp from './page/LoginCognito/VerifyOtp';
import { biometricPermission } from './service/lobbyService';
import Verification from './page/LoginCognito/Verification';
import LoginOptions from './page/LoginCognito/LoginOptions';
import KBAVerification from './page/LoginCognito/KBAVerification';
import SetPin from './page/LoginCognito/SetPin';
import VerifySsn from './page/LoginCognito/VerifySsn';
import PinVerification from './page/PinVerification';
import { getPropertyList, propertyFallbackList } from './service/appService';
import Experience from './page/Experience';
import Rewards from './page/Rewards';
import More from './page/More';

//#region lazyloaded files
const Profile = lazy(() => import('./page/Profile'));
const EditProfile = lazy(() => import('./page/Profile/EditProfile'));
const Register = lazy(() => import('./page/Register'));
const Spin = lazy(() => import('./page/SpinWheel/Spin'));
//#endregion

const App = () => {
	const history = useHistory();
	const currentProperty = useCurrentProperty();
	const {
		congnitoIsAuthenticated,
		congnitoUser,
		accessToken,
		isLoading: isCognitoLoading
	} = useCongnitoUser();
	const { verifyOtp } = loginVaribages;
	const [showModal, setShowModal] = useState(false);
	const currPlatform = getCookieValue(PLATFORM_COOKIE).toLowerCase();
	const [propertyList, setPropertyList] = useState(propertyFallbackList);

	//#region CONTEXT PSECIFIC STATES
	const [pageVisits, setPageVisits] = useState<{ [key: string]: number }>({});
	const [userState, setUserState] = useState<USER>({ isLoading: true });
	const { toggleTheme } = useThemeContext();
	const [globalState, setGlobalState] = useState<GlobalData>({
		propertyList: [...propertyFallbackList],
		pageVisitCount: {},
		isAuthenticated: false,
		token: '',
		currentProperty
	});
	const {
		isFaceIdAvailable,
		isFaceIdSetup,
		postMessagetoNative,
		globalId: globalIdFromNative
	} = useRNContext();
	const { isFaceIDFeatureAvailable, isLightThemeFeatureAvailable } = useLDContext();
	//#endregion

	// To set user specific data in Context
	const setUserDataInContext = useCallback(
		(data: USER) => {
			if (data) {
				setUserState({
					...userState,
					...data
				});
			}
		},
		[userState]
	);

	const clearContextData = () => {
		setUserState({
			isLoading: false
		});

		setGlobalState({
			...globalState,
			isAuthenticated: false,
			token: ''
		});
	};

	// On Authentication change
	useEffect(() => {
		if (!isCognitoLoading) {
			// IS USER AUTHENTICATED
			if (congnitoIsAuthenticated && congnitoUser['cognito:username']) {
				setGlobalState({
					...globalState,
					isAuthenticated: congnitoIsAuthenticated,
					token: accessToken
				});
				fetchProfileData(accessToken, congnitoUser['cognito:username']);
			} else {
				// Check if REFRESH_TOKEN exists
				getRefreshToken()
					.then((data: string) => {
						if (data) {
							// Try to RE-AUTHENTICATE USER
							renewAccessToken(data);
						} else {
							clearContextData();
						}
					})
					.catch(async () => {
						handleRefreshTokenError();
					});
			}
		}
	}, [
		isCognitoLoading,
		congnitoIsAuthenticated,
		isFaceIdAvailable,
		isFaceIDFeatureAvailable,
		isLightThemeFeatureAvailable
	]);

	const handleRefreshTokenError = async () => {
		clearContextData();
		postMessagetoNative('LOGGED_OUT');
		await logoutSession();
		history.push(ROUTE_CONSTANTS.LOGIN_CONGNITO.LANDING);
	};

	async function renewAccessToken(token: string) {
		try {
			const res = await getOtp(
				{
					action: OTP_ACTION.REFRESH_TOKEN,
					refreshToken: token
				},
				currentProperty.siteCode
			);
			if (res.currStatus == 200) {
				setSessionItem(TOKENS.ACCESS, res?.AuthenticationResult?.AccessToken);
				setSessionItem(TOKENS.ID, res?.AuthenticationResult?.IdToken);
				setSessionItem(TOKENS.USER_STATUS, res.userInfo?.status);
			} else if (res.currStatus == 400) {
				// If token renewal unsuccessful, do LOGOUT
				handleRefreshTokenError();
			}
		} catch (error) {
			console.log('error', error);
			handleRefreshTokenError();
		}
	}

	const handlePositiveButton_Exclusion = useCallback(async () => {
		clearContextData();
		postMessagetoNative('LOGGED_OUT');
		await logoutSession();
		setShowModal(false);
		history.push(ROUTE_CONSTANTS.LOBBY);
	}, [history]);

	const handlePositiveButton_FaceId = useCallback(async () => {
		setShowModal(false);
		history.push({
			pathname: ROUTE_CONSTANTS.VERIFY_PIN,
			state: { successCallbackURL: ROUTE_CONSTANTS.LOBBY }
		});
	}, [history]);

	const [errorPopupData, setErrorPopupData] = useState<Ipopup>({
		positiveButtonText: '',
		description: '',
		positiveCallback: () => {
			//
		}
	});

	// First time only
	useEffect(() => {
		setPageVisits({ [history.location.pathname]: 1 });
		fetchAppConfig();
	}, []);

	useEffect(() => {
		return history.listen(() => {
			const _pageVisits = { ...pageVisits };
			if (_pageVisits[history.location.pathname]) {
				_pageVisits[history.location.pathname]++;
			} else {
				_pageVisits[history.location.pathname] = 1;
			}
			setPageVisits({ ..._pageVisits });
		});
	}, [history, pageVisits]);

	const fetchAppConfig = async () => {
		const res = await getPropertyList();
		if (res?.propertyCodes) {
			setPropertyList([...res.propertyCodes]);
		}
	};

	const fetchProfileData = async (token: string, _globalId: any) => {
		try {
			const response: IProfileDetails = await getProfileData(
				token,
				_globalId,
				currentProperty.siteCode
			);
			if (response?.user) {
				const date: Date = new Date();
				console.log('context before user details: ', globalState, userState?.isLoading);
				if (isLightThemeFeatureAvailable && !!response?.user?.themePreference) {
					toggleTheme(response?.user?.themePreference as Theme);
				}
				postMessagetoNative('LOGGED_IN', {
					accessToken: token,
					trackData: response.user?.trackData
				});
				setUserState({
					...userState,
					userName: `${response.user?.firstName} ${response.user?.lastName}`,
					mobileNumber: response.user?.cellular,
					playerId: response.user?.playerId,
					lastUpdated: `Data updated on ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`,
					gender: response.user?.gender,
					isLoading: false,
					...response.user
				});
				if (isFaceIDFeatureAvailable) {
					getFaceIdPopup().then((data: string) => {
						if (
							isFaceIdAvailable &&
							((!data && !isFaceIdSetup) ||
								(globalIdFromNative != '' && globalIdFromNative != response?.user?.globalId))
						) {
							postMessagetoNative('UNREGISTER_FACEID');
							// Check and show FaceId popup
							setErrorPopupData({
								...(currPlatform == 'android'
									? biometricPermission.android
									: biometricPermission.ios),
								positiveCallback: handlePositiveButton_FaceId,
								negativeCallback: () => {
									setShowModal(false);
								}
							});
							saveFaceIdPopup();
							setShowModal(true);
						}
					});
				}
			} else if (response.status === 502) {
				// LOGGED OUT/ACCESS TOKEN EXPIRED case
				handleRefreshTokenError();
			} else if (response.status === 403) {
				// SELF EXCLUDED USER
				setErrorPopupData({
					...verifyOtp.exclusionPopup,
					positiveCallback: handlePositiveButton_Exclusion,
					negativeCallback: handlePositiveButton_Exclusion
				});
				setShowModal(true);
			}
		} catch (error) {
			console.log('Error while fething user details ', error);
			handleRefreshTokenError();
		}
	};

	const value = useMemo<AppContract>(() => {
		return {
			user: { ...userState },
			global: {
				...globalState,
				propertyList: [...propertyList],
				pageVisitCount: { ...pageVisits }
			},
			setUserDataInContext
		};
	}, [setUserDataInContext, propertyList, userState, globalState, pageVisits]);

	const renderOptIn = useCallback(() => {
		if (!isCognitoLoading && congnitoIsAuthenticated) {
			return <Rewards />;
		} else {
			return <Redirect to={ROUTE_CONSTANTS.LOGIN_CONGNITO.LANDING} />;
		}
	}, [congnitoIsAuthenticated]);

	return (
		<GlobalContext.Provider value={value}>
			{/* MAIN CONTAINER */}
			<Suspense>
				<Switch>
					<Route path={ROUTE_CONSTANTS.LOBBY} exact component={Lobby} />
					<Route path={ROUTE_CONSTANTS.EXPERIENCE_ROUTES.LANDING} exact component={Experience} />
					<Route path={ROUTE_CONSTANTS.PROFILE.LANDING} exact component={Profile} />
					<Route path={ROUTE_CONSTANTS.PROFILE.EDIT_PROFILE} component={EditProfile} />
					<Route path={ROUTE_CONSTANTS.REWARDS} exact component={Rewards} />
					<Route path={ROUTE_CONSTANTS.OPTIN} render={renderOptIn} />
					<Route path={ROUTE_CONSTANTS.REGISTER} component={Register} />
					<Route path={ROUTE_CONSTANTS.MORE} component={More} />
					<Route path={ROUTE_CONSTANTS.WIN_BONUS} component={Spin} />
					{/*LOGIN COGNITO */}
					<Route path={ROUTE_CONSTANTS.LOGIN_CONGNITO.LANDING} component={LoginCognito} />
					<Route path={ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_OTP} component={VerifyOtp} />
					<Route path={ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_INFO} component={Verification} />
					<Route path={ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_SSN} component={VerifySsn} />
					<Route path={ROUTE_CONSTANTS.VERIFY_PIN} component={PinVerification} />
					<Route path={ROUTE_CONSTANTS.LOGIN_CONGNITO.LOGIN_OPTION} component={LoginOptions} />
					<Route path={ROUTE_CONSTANTS.LOGIN_CONGNITO.KBA_QUESTIONS} component={KBAVerification} />
					<Route path={ROUTE_CONSTANTS.LOGIN_CONGNITO.SET_PIN} component={SetPin} />
					{/* end */}
					{/* Create a 404 page for this */}
					{/* <Route path={ROUTE_CONSTANTS.ERROR} component={Error} /> */}
					<Redirect to={ROUTE_CONSTANTS.LOBBY} />
				</Switch>
			</Suspense>

			{showModal && (
				<PopupDrawer
					errorImage={
						<div className="error-icon-box">
							<i
								className={`icon-modal-view`}
								style={{ backgroundImage: `url(${errorPopupData.icon})` }}
							/>
						</div>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">{errorPopupData.desc_heading}</h2>
					}
					data={errorPopupData}
					handleNegativeButton={errorPopupData.negativeCallback}
					handlePositiveButton={errorPopupData.positiveCallback}
				/>
			)}
		</GlobalContext.Provider>
	);
};

export default App;
