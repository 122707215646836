import { useState, useCallback, useEffect } from 'react';
import OtpInput from '../../components/InputField/OtpInput';
import InputButton from '../../components/InputField/InputButton';
import './index.scss';
import { useCurrentProperty, useGlobalContext, useRNContext } from '../../hooks';
import { loginVaribages } from '../../service/loginService';
import { ROUTE_CONSTANTS } from '../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import { verifyPlayerIdPin } from '../../service/registerService';
import Layout from '../../hoc/Layout';

// Will act as reusable page for authenticating user before any transactional activity
const PinVerification = () => {
	const { pinVerification } = loginVaribages;
	const { postMessagetoNative } = useRNContext();
	const [errorMsg, setErrorMsg] = useState('');
	const [PIN, setPINState] = useState('');
	const [otpAutoFocus, setOtpAutoFocus] = useState<boolean>(false);
	const currentProperty = useCurrentProperty();
	const { user, global } = useGlobalContext();
	const [isLoading, setIsLoading] = useState<boolean>(!user?.playerId);
	const history = useHistory();
	const { state } = useLocation();

	useEffect(() => {
		console.log('pageVisitCount: ', global?.pageVisitCount);
		if (
			(global?.pageVisitCount && Object.keys(global?.pageVisitCount).length == 1) ||
			!user?.playerId
		) {
			history.goBack();
		}
	}, [global?.pageVisitCount, user?.playerId]);

	const handleContinue = useCallback(
		async (event: any) => {
			event.preventDefault();
			if (PIN.length < 4) {
				setErrorMsg(pinVerification.errorMsg);
				return;
			}
			setIsLoading(true);
			setErrorMsg('');
			try {
				const res = await verifyPlayerIdPin(PIN, user?.playerId ?? '', currentProperty.siteCode);
				if (res.currStatus == 200 && res?.verified) {
					// SUCCESS SCENARIO
					const { successCallbackURL } = state as { successCallbackURL: string };
					postMessagetoNative('REGISTER_FACEID', { globalId: user?.globalId });
					history.replace(successCallbackURL ?? ROUTE_CONSTANTS.LOBBY);
				} else if (res?.message) {
					setPINState('');
					// INVALID PIN
					setErrorMsg(`${res?.message}.`);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setIsLoading(false);
			}
		},
		[errorMsg, PIN, setErrorMsg, user?.playerId, state]
	);

	const handleOnPlayerIdFocus = useCallback(() => {
		setErrorMsg('');
	}, [errorMsg]);

	const handleOnOTPFocus = useCallback(() => {
		setOtpAutoFocus(true);
	}, [errorMsg, handleOnPlayerIdFocus]);

	const handleChange = useCallback(
		(otp: string) => {
			setPINState(otp);
		},
		[PIN]
	);

	return (
		<>
			<Layout className="pin-verification" dataTestid="testid-pin-verification" noFooter={true}>
				<div className="verify-pin">
					<h1 className="headline-medium heading">{pinVerification.verify_heading}</h1>
					<p className="sub-heading body-text-medium">{`${pinVerification.enter_pin_subheading}`}</p>
					<form noValidate onSubmit={handleContinue} data-testid="form-verify">
						<div className={`otp-container`}>
							<OtpInput
								onFocus={handleOnOTPFocus}
								length={4}
								autoFocus={otpAutoFocus}
								inputClassName={`single-otpInput ${errorMsg ? 'error' : ''}`}
								onChangeOTP={handleChange}
								isInputRef={true}
								otp={PIN}
								isMasked={true}
							/>
						</div>
						{errorMsg && (
							<div className="error-container">
								<i className="icon-info" />
								<p data-testid="error-msg" className="error-msg body-text-medium">
									{errorMsg}
								</p>
							</div>
						)}
						<InputButton disabled={isLoading} varient="primary" datatestid="continue-btn">
							{pinVerification.btn.Continue}
						</InputButton>
					</form>
				</div>
			</Layout>
		</>
	);
};

export default PinVerification;
