import withSkeltonLoader from '../../hoc/SkeltonLoader';
import { rewardTierLabel } from '../../service/rewardService';
import { IProfilesDetailProps, LoaderType } from '../../types';
import { useGlobalContext } from '../../hooks';
const ProfileTierCard = () => {
	const context = useGlobalContext();

	return (
		<div className="tier-container">
			{rewardTierLabel(context)?.map((link: IProfilesDetailProps) => (
				<div
					key={link.title}
					data-testid="icon-button-link"
					className={`${link.icon} tier-icon-button item-center caption2`}
				>
					<span>
						<p className="tier-title">{link.title}</p>
						<p className="tier-subtitle"> {link.subtitle}</p>
					</span>
				</div>
			))}
		</div>
	);
};
export default withSkeltonLoader(ProfileTierCard, LoaderType.rewardTier);
