import { FC, memo, useCallback, useState } from 'react';
import './GroupListing.scss';
import { ILink, IRedirection, Ipopup } from '../../types';
import PopupDrawer from '../PopupDrawer';
import { deleteAccount, deleteAccountJson, logoutJson } from '../../service/profileService';
import ActionList from './ActionList';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '../../constants';
import {
	logoutSession,
	useCurrentProperty,
	useGlobalContext,
	useRNContext,
	useThemeContext
} from '../../hooks';
import { confirmIdDetails } from '../../service/loginService';

const GroupListing: FC<IRedirection> = ({ links }) => {
	const currentProperty = useCurrentProperty();
	const [showModal, setShowModal] = useState(false);
	const [modalState, setmodalState] = useState<Ipopup>({
		positiveButtonText: '',
		description: '',
		positiveCallback: () => {
			//
		}
	});
	const { theme, toggleTheme } = useThemeContext();
	const history = useHistory();
	const { user, global, setUserDataInContext: setData } = useGlobalContext();
	const { isFaceIdAvailable, isFaceIdSetup, postMessagetoNative } = useRNContext();

	const logout = useCallback(async () => {
		setData({});
		postMessagetoNative('LOGGED_OUT');
		await logoutSession();
		history.push(ROUTE_CONSTANTS.LOBBY);
	}, [history]);

	const toggleLogoutPopup = useCallback(() => {
		setmodalState({ ...logoutJson, positiveCallback: logout });
		setShowModal(true);
	}, [logout]);

	const deleteUser = useCallback(async () => {
		if (global.token && user?.playerId) {
			try {
				const response = await deleteAccount(
					global.token,
					user?.playerId,
					currentProperty.siteCode
				);
				if (response.status == 200) {
					postMessagetoNative('UNREGISTER_FACEID');
					setData({});
					postMessagetoNative('LOGGED_OUT');
					await logoutSession();
					history.push(ROUTE_CONSTANTS.LOBBY);
				}
			} catch (error) {
				console.log(error);
			}
		}
	}, [user?.playerId, global.token]);

	const toggleDeletePopup = useCallback(() => {
		setmodalState({ ...deleteAccountJson, positiveCallback: deleteUser });
		setShowModal(true);
	}, [deleteUser]);

	const toggleFaceId = useCallback(
		(e: any) => {
			if (e.target.checked === true) {
				history.push({
					pathname: ROUTE_CONSTANTS.VERIFY_PIN,
					state: { successCallbackURL: ROUTE_CONSTANTS.MORE }
				});
			} else if (e.target.checked === false) {
				postMessagetoNative('UNREGISTER_FACEID');
			}
		},
		[history]
	);

	const toggleAppTheme = useCallback(
		async (e: any) => {
			console.log(e);
			if (e.target.type == 'checkbox') {
				if (user?.globalId) {
					confirmIdDetails(
						global.token,
						user?.globalId,
						{
							themePreference: e.target.checked ? 'light' : 'dark'
						},
						currentProperty.siteCode
					);
				}
				toggleTheme();
			}
		},
		[theme, user?.globalId]
	);

	const generateLink = useCallback(
		(link: ILink) => {
			if (link.to == 'logout') {
				return <ActionList actions={{ ...link, action: toggleLogoutPopup }} />;
			} else if (link.to == 'delete') {
				return <ActionList actions={{ ...link, action: toggleDeletePopup }} />;
			} else if (link.to == 'faceid') {
				if (isFaceIdAvailable) {
					return (
						<ActionList actions={{ ...link, action: toggleFaceId, isChecked: isFaceIdSetup }} />
					);
				}
			} else if (link.to == 'theme') {
				return (
					<ActionList
						actions={{
							...link,
							action: toggleAppTheme,
							isChecked: theme == 'light' ? true : false
						}}
					/>
				);
			} else {
				return <ActionList actions={link} />;
			}
		},
		[
			isFaceIdAvailable,
			isFaceIdSetup,
			toggleFaceId,
			toggleAppTheme,
			toggleLogoutPopup,
			toggleDeletePopup
		]
	);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const generateList = useCallback(
		(linkList: ILink[] | undefined) => {
			return (
				<ul className={`list-group`} data-testid="testid-list-group">
					{linkList?.map((link: ILink, index) => {
						const k = `link-${index}`;
						return (
							<li className="list-group-item" key={k}>
								{generateLink(link)}
							</li>
						);
					})}
				</ul>
			);
		},
		[generateLink]
	);

	return (
		<>
			{generateList(links)}
			{showModal && (
				<PopupDrawer
					errorImage={
						<div className="error-icon-box">
							<i
								className={`icon-modal-view`}
								style={{ backgroundImage: `url(${modalState?.icon})` }}
							/>
						</div>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">{modalState?.desc_heading}</h2>
					}
					data={modalState}
					handleNegativeButton={handleCloseModal}
					handlePositiveButton={modalState?.positiveCallback}
				/>
			)}
		</>
	);
};

export default memo(GroupListing);
