import { useState, useEffect } from 'react';
import { getExperienceDetails } from '../../service/experienceService';
import './index.scss';
import Layout from '../../hoc/Layout';
import ExperienceCard from './ExperienceCard';

const Experience = () => {
	const [experienceDetails, setExperienceDetails] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (experienceDetails?.length > 0) setIsLoading(false);
	}, [experienceDetails]);

	useEffect(() => {
		getExperienceData();
	}, []);

	const getExperienceData = async () => {
		try {
			const response: any = await getExperienceDetails();
			setExperienceDetails(response.CTAs);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Layout className="page-experience" dataTestid="testid-experience">
			<ExperienceCard experienceDetails={experienceDetails} loading={isLoading} />
		</Layout>
	);
};

export default Experience;
