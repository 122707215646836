import { useCallback, useEffect, useMemo, useState } from 'react';
import withSkeltonLoader from '../../hoc/SkeltonLoader';
import { useCongnitoUser, useGlobalContext, useRNContext } from '../../hooks';
import { IRewardsCards, LoaderType } from '../../types';
import RewardCard from '../RewardCard/RewardCard';
import './ProfileTierBanner.scss';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '../../constants';
import { ConnectionCard } from './ConnectionCard';
import BrandLogo from '../BrandLogo';

const ProfileTierBanner = (props: { profileCard: IRewardsCards[] }) => {
	const { connectionStatus, postMessagetoNative, setConnectionValue } = useRNContext();
	const { profileCard } = props;
	const history = useHistory();
	const [isFlipped, setIsFlipped] = useState(
		connectionStatus == 'CONNECTING' || connectionStatus == 'DISCONNECTING' ? true : false
	);
	const { congnitoIsAuthenticated, isLoading } = useCongnitoUser();
	const context = useGlobalContext();

	useEffect(() => {
		if (connectionStatus == 'CONNECTING' || connectionStatus == 'DISCONNECTING') {
			setIsFlipped(true);
		}
	}, [connectionStatus]);

	const getTierData = useMemo(() => {
		return {
			comp: context.user?.comp,
			points: context.user?.points,
			tier: context.user?.tier?.toLocaleLowerCase() ?? 'pro',
			isUserLoading: context.user?.isLoading,
			image:
				profileCard.filter(
					(images: IRewardsCards) =>
						images.SlideHeading.toLocaleLowerCase() === context.user?.tier?.toLocaleLowerCase()
				)[0]?.SlideImage.FullURL ??
				profileCard.filter(
					(images: IRewardsCards) => images.SlideHeading.toLocaleLowerCase() === 'pro'
				)[0]?.SlideImage.FullURL
		};
	}, [isLoading, profileCard, context.user]);

	const handleLogin = useCallback(() => {
		if (!isLoading && !congnitoIsAuthenticated) {
			history.push(ROUTE_CONSTANTS.LOGIN_CONGNITO.LANDING);
		}
	}, [isLoading, congnitoIsAuthenticated, history]);

	const handleCardInOut = useCallback(() => {
		if (connectionStatus == 'CONNECTED') {
			setConnectionValue('DISCONNECTING');
			postMessagetoNative('DISCONNECT_SLOT');
			setIsFlipped(true);
		} else {
			postMessagetoNative('CONNECT_SLOT');
			setIsFlipped(true);
		}
	}, [connectionStatus]);

	const getCardButtonClass = useMemo(() => {
		if (connectionStatus == 'CONNECTED') {
			return {
				class: 'cardout-btn',
				text: 'Card Out',
				icon: 'icon-card-out',
				showConnected: true,
				noiseClass: 'connected'
			};
		}
		return {
			class: 'cardin-btn',
			text: 'Card In',
			icon: 'icon-card-in',
			showConnected: false,
			noiseClass: 'disconnected'
		};
	}, [connectionStatus]);
	return (
		<div className={`card-profile ${isFlipped ? 'is-flipped' : ''}`}>
			<div className={`card__face card__face--front`}>
				<div
					className={`player-tier-card ${getTierData.isUserLoading ? '' : getTierData.tier} ${
						getCardButtonClass.noiseClass
					}`}
					data-testid="testid-btn-login"
					onClick={!congnitoIsAuthenticated ? handleLogin : handleCardInOut}
				>
					<span className="tier-level">{getTierData.tier}</span>

					<BrandLogo isVertical={true} />
					{!isLoading &&
						(!congnitoIsAuthenticated ? (
							<></>
						) : (
							// <p className="login-text label-medium prominant">
							// 	Login / Sign Up to get your tier card
							// </p>
							<RewardCard />
						))}

					{!isLoading && (
						<div className="cardin-container">
							<div
								data-testid="cardin-out-btn"
								role="button"
								className={`${getCardButtonClass.class}`}
							>
								<i className={`icon-card-in_out ${getCardButtonClass.icon}`}></i>
								<span className="cardin-text label-medium">{getCardButtonClass.text}</span>
							</div>
							{getCardButtonClass.showConnected && (
								<div className="connected-check label-medium">
									<i className="icon-tick" />
									Connected
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<ConnectionCard setIsFlipped={setIsFlipped} />
		</div>
	);
};
export default withSkeltonLoader(ProfileTierBanner, LoaderType.profileBanner);
