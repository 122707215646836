import './GroupListing.scss';
import { ILink } from '../../types';
import { Link } from 'react-router-dom';
import { ComingSoonText } from '../../service/experienceService';

const ActionList = ({ actions }: { actions: ILink }) => {
	const generateLink = (link: ILink) => {
		if (link.external) {
			if (link.componentType == 'checkbox') {
				return (
					<div className={`listing-link ${link.className}`} key={link.label}>
						<label className="switch">
							<input
								type="checkbox"
								data-testid={`testid-action-button`}
								checked={link.isChecked}
								onChange={link.action}
							/>
							<span className="slider round"></span>
						</label>
						<i className={`icon-prefix ${link.icon} v-center`}>{link.label}</i>
					</div>
				);
			} else if (link.action) {
				return (
					<a
						key={link.label}
						data-testid={`testid-action-button`}
						onClick={link.action}
						className={`icon-arrow-head listing-link ${link.className}`}
					>
						<i className={`icon-prefix ${link.icon} v-center`}>{link.label}</i>
					</a>
				);
			} else {
				return (
					<a
						data-testid="testid-action-Link"
						key={link.label}
						target="_blank"
						rel="noreferrer"
						href={link.to}
						className={`icon-arrow-head listing-link icon ${!link.to ? 'coming-soon' : ''}`}
					>
						{!link.to ? <span className="coming-soon-badge">{ComingSoonText}</span> : null}
						<i className={`icon-prefix ${link.icon} v-center`}>{link.label}</i>
					</a>
				);
			}
		} else {
			return (
				<Link
					data-testid="testid-action-Link"
					to={link.to}
					className={`icon-arrow-head listing-link icon `}
				>
					<i className={`icon-prefix ${link.icon} v-center`}>{link.label}</i>
				</Link>
			);
		}
	};

	return <>{generateLink(actions)}</>;
};

export default ActionList;
