import jsonp from 'fetch-jsonp';
import { ROUTE_CONSTANTS } from '../constants';
import {
	GET_HOME_REWARDS,
	GET_HOME_RAILS,
	API_BASE_URL,
	GET_OPENING_DETAILS,
	GET_ACCOUNT_ACTIVATION_POPUP
} from '../constants/endPoints';
import { ILink, Promotions, Rails } from '../types';
import activationIcon from '../assets/activation-popup.svg';
import faceIdIcon from '../assets/faceid.svg';
import biometricIcon from '../assets/biometric.svg';
export const ballyRewardLogo = '/assets/Rewards.png';

export const promotionText = 'Promotions';
export const bottomNotificationText = 'Activate your account to get full access';
export const activationNotificationJson = {
	desc_heading: 'Account Activation',
	icon: activationIcon,
	description: '',
	positiveButtonText: 'OK',
	desc_text_style: 'body-text-medium'
};

export const biometricPermission = {
	android: {
		desc_heading: 'Enable Biometric?',
		icon: biometricIcon,
		description: 'Enabling the Biometrics allows you to log in quickly and securely.',
		positiveButtonText: 'Enable',
		negativeButtonText: 'Not Now',
		desc_text_style: 'body-text-medium'
	},
	ios: {
		desc_heading: 'Enable Face ID?',
		icon: faceIdIcon,
		description: 'Enabling the Face ID allows you to log in quickly and securely.',
		positiveButtonText: 'Enable',
		negativeButtonText: 'Not Now',
		desc_text_style: 'body-text-medium'
	}
};

export const getRewardsData = (): Promise<any> => {
	return jsonp(`${API_BASE_URL}/${GET_HOME_REWARDS}.js`, {
		jsonpCallbackFunction: `${GET_HOME_REWARDS}DataCallback`
	}).then((response) => {
		return response.json() as Promise<Promotions>;
	});
};
export const getHomeRails = (): Promise<Rails> => {
	return jsonp(`${API_BASE_URL}/${GET_HOME_RAILS}.js`, {
		jsonpCallbackFunction: `${GET_HOME_RAILS}DataCallback`
	}).then((response) => {
		return response.json() as Promise<Rails>;
	});
};

export const getOpeningData = (): Promise<any> => {
	return jsonp(`${API_BASE_URL}/${GET_OPENING_DETAILS}.js`, {
		jsonpCallbackFunction: `${GET_OPENING_DETAILS}DataCallback`
	}).then((response) => {
		return response.json() as Promise<Rails>;
	});
};

export const getLobbyGridItems = (): ILink[] => {
	return [
		// {
		// 	label: 'Games',
		// 	to: '',
		// 	icon: 'icon-games',
		// 	external: false
		// },
		{
			label: 'Offers',
			to: '',
			icon: 'icon-offers',
			external: false
		},
		{
			label: 'Benefits',
			to: 'https://casinos.ballys.com/chicago/rewards-club.htm',
			icon: 'icon-benefits',
			external: true
		}
	];
};

export const getTitleName = (): ILink[] => [
	{
		label: 'Parking',
		to: ROUTE_CONSTANTS.PARKING.PAYMENT_DETAIL
	},
	{
		label: 'Parking',
		to: ROUTE_CONSTANTS.PARKING.PAYMENT_STATUS
	},
	{
		label: 'Scan & Pay',
		to: ROUTE_CONSTANTS.PARKING.QRSCANNER
	}
];

export const getActivationPopupContent = (): Promise<any> => {
	return jsonp(`${API_BASE_URL}/${GET_ACCOUNT_ACTIVATION_POPUP}.js`, {
		jsonpCallbackFunction: `${GET_ACCOUNT_ACTIVATION_POPUP}DataCallback`
	}).then((response) => {
		return response.json() as Promise<Rails>;
	});
};

export const viewOfferLabel = 'VIEW YOUR OFFERS NOW';
export const lobbyVerbiage = {
	promotionHeading: 'Promotions'
};
