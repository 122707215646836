import { FC, useCallback } from 'react';
import './Carousel.scss';
import { LoaderType, ICardType, IPromotionDetail } from '../../types';
import withSkeltonLoader from '../../hoc/SkeltonLoader';

const Carousel: FC<ICardType> = ({ slides }) => {
	const getPathName = useCallback(
		(sliderItem: IPromotionDetail) => {
			if (sliderItem?.CTAButtons.length) {
				return sliderItem?.CTAButtons[0]?.CTAButtonButtonLink;
			}
			return '';
		},
		[slides]
	);

	return (
		<>
			{slides?.map((sliderItem: IPromotionDetail, index: number) => {
				const k = `card-${index}`;
				return (
					<a
						data-testid={`card-${index}`}
						key={k}
						href={getPathName(sliderItem)}
						target="_blank"
						rel="noreferrer"
					>
						<div className="card">
							<img
								className="card-media"
								src={sliderItem?.CTAImage?.FullURL}
								alt={sliderItem.CTAAltText}
							/>
							<div className="card-details">
								<h3 className="carousel-slider-title">{sliderItem.CTAHeading}</h3>
							</div>
						</div>
					</a>
				);
			})}
		</>
	);
};
export default withSkeltonLoader(Carousel, LoaderType.promo);
