import { TextInputInterface } from '../../types';
import './TextInput.scss';

const TextInput = (props: TextInputInterface) => {
	const { label, datatestid = 'testid-input', error = '', ref = null } = props;
	return (
		<div className="textinput">
			<label className="label-medium">{label}</label>
			<input
				ref={ref}
				{...props}
				className={`label-large ${error ? 'error' : ''}`}
				data-testid={datatestid}
			/>
		</div>
	);
};

export default TextInput;
