import { useCallback, useState, useEffect } from 'react';
import './InputField.scss';
import { OTPInputProps } from '../../types';
import SingleOTPInput from './SingleOTPInput';

const OtpInput = (props: OTPInputProps) => {
	const {
		length,
		autoFocus = true,
		onChangeOTP,
		inputClassName,
		isInputRef,
		onFocus,
		otp,
		isMasked = false
	} = props;

	const [activeInput, setActiveInput] = useState(0);
	const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''));

	useEffect(() => {
		if (otpValues.filter((value) => value == ' ').length == 0) {
			if (otp?.length == 4) {
				setOTPValues(otp.split(''));
			} else if (otp == '') {
				setOTPValues(Array<string>(length).fill(''));
			}
		}
	}, [otp]);

	//return OTP from inputs
	const handleOtpChange = useCallback(
		(otp: string[]) => {
			const otpValue = otp.join('');
			onChangeOTP(otpValue);
			onFocus();
		},
		[onChangeOTP]
	);

	// Change OTP value at focussing input
	const changeCodeAtFocus = useCallback(
		(str: string) => {
			const updatedOTPValues = [...otpValues];
			updatedOTPValues[activeInput] = str[0] || '';
			setOTPValues(updatedOTPValues);
			handleOtpChange(updatedOTPValues);
		},
		[activeInput, handleOtpChange, otpValues]
	);

	// Focus `inputIndex` input
	const focusInput = useCallback(
		(inputIndex: number) => {
			const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
			setActiveInput(selectedIndex);
		},
		[length]
	);

	const focusPrevInput = useCallback(() => {
		focusInput(activeInput - 1);
	}, [activeInput, focusInput]);

	const focusNextInput = useCallback(() => {
		focusInput(activeInput + 1);
	}, [activeInput, focusInput]);

	// handle onFocus input
	const handleOnFocus = useCallback(
		(index: number) => () => {
			focusInput(index);
		},
		[focusInput]
	);

	// handle onChange value for each input
	const handleOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.value.trim().length == 4) {
				onChangeOTP(e.target.value);
			} else if (e.currentTarget.value.length == 1) {
				changeCodeAtFocus(e.currentTarget.value);
				focusNextInput();
			}
		},
		[changeCodeAtFocus, focusNextInput]
	);

	// handle onKeyDown input (back)
	const handleOnKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const pressedKey = e.key;

			switch (pressedKey) {
				case 'Backspace':
				case 'Delete': {
					e.preventDefault();
					if (otpValues[activeInput]) {
						changeCodeAtFocus('');
					} else {
						focusPrevInput();
					}
					break;
				}
				case 'ArrowLeft': {
					e.preventDefault();
					focusPrevInput();
					break;
				}
				case 'ArrowRight': {
					e.preventDefault();
					focusNextInput();
					break;
				}
				default: {
					if (!pressedKey.match(/^\d$/) && pressedKey !== 'Enter') {
						e.preventDefault();
					}
					break;
				}
			}
		},
		[activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
	);

	return (
		<>
			{Array(length)
				.fill('')
				.map((_, index) => {
					const k = `SingleInput-${index}`;
					return (
						<SingleOTPInput
							key={k}
							focus={activeInput === index}
							value={otpValues[index]}
							autoFocus={autoFocus}
							onFocus={handleOnFocus(index)}
							onChange={handleOnChange}
							onKeyDown={handleOnKeyDown}
							className={inputClassName}
							isInputRef={isInputRef}
							data-testid={k}
							isMasked={isMasked}
						/>
					);
				})}
		</>
	);
};
export default OtpInput;
