import { FC } from 'react';
import withSkeltonLoader from '../../hoc/SkeltonLoader';
import { LoaderType } from '../../types';

const Heading: FC<{ label: string; loading: boolean; className?: string }> = ({
	label,
	className = 'section-heading'
}) => {
	return (
		<>
			<h3 data-testid="heading-test" className={className}>
				{label}
			</h3>
		</>
	);
};
export default withSkeltonLoader(Heading, LoaderType.title);
