import jsonp from 'fetch-jsonp';
import { API_METHOD, API_VERSION } from '../constants';
import { API_ROOT, GET_PROFILE_TIER_CARD } from '../constants/endPoints';
import { IProfileTierCardProps } from '../types';
import deleteIcon from '../assets/delete-popup.svg';
import logoutIcon from '../assets/logout-popup.svg';
export const profileBgImage = '/assets/Rewards-Page-Background.png';

const { REACT_APP_USER_SERVICE_BASE_URL } = process.env;

export const disclaimerText =
	'Please visit the Players Club booth if you wish to update your information.';
export const DeleteAccount = 'Delete Account';
export const LogOut = 'Log out';
export const deleteAccountJson = {
	desc_heading: 'Are you sure you want to DELETE YOUR ACCOUNT ?',
	description:
		'Deleting your account will permanently stop you from accessing your account from our mobile application. Your Players Club account will remain active. Are you sure to delete?',
	positiveButtonText: 'Delete',
	negativeButtonText: 'Cancel',
	icon: deleteIcon,
	desc_text_style: 'body-text-medium'
};

export const logoutJson = {
	desc_heading: 'Are you sure to Logout ?',
	description:
		'Logging out of the application will temporarily stop you from accessing your account. You can login back to your account at any time. Are you sure to logout ?',
	positiveButtonText: 'Logout',
	negativeButtonText: 'Cancel',
	icon: logoutIcon,
	desc_text_style: 'body-text-medium'
};
export const noDataOffer = {
	title: 'No OFFERS',
	description1:
		'Currently, you have no offers. Stay tuned for the dazzling rewards that await your exploration!',
	description2: 'In the meantime, please feel free to check out our',
	linkText: 'Rewards benefits.',
	link: 'https://casinos.ballys.com/lake-tahoe/rewards-club.htm'
};

export const LearnMore = 'Learn More';

export const getProfileTierCard = (): Promise<IProfileTierCardProps> => {
	return jsonp(`${API_ROOT}/${GET_PROFILE_TIER_CARD}.js`, {
		jsonpCallbackFunction: `${GET_PROFILE_TIER_CARD}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IProfileTierCardProps>;
	});
};

export const getProfileData = async (accessToken: string, globalId: string, siteCode: string) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}users/${globalId}?sitecode=${siteCode}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
			version: API_VERSION
		}
	}).then((res) => {
		if (res?.status !== 200) return { status: res.status, ...res.json() };
		else return res.json();
	});
};
export const getUserOffers = async (
	accessToken: string,
	playerId: string,
	mailersApiBaseUrl: string
) => {
	return fetch(`${mailersApiBaseUrl}/api/user-offers/${playerId}`, {
		method: 'GET'
	}).then(async (res) => {
		if (res?.status !== 200) return { status: res.status, ...res.json() };
		else return res.json();
	});
};

export const deleteAccount = async (accessToken: string, playerID: string, siteCode: string) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}users/${playerID}?siteCode=${siteCode}`, {
		method: API_METHOD.DELETE,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
			version: API_VERSION
		}
	}).then(async (res) => {
		if (res?.status !== 200) return { status: res.status, ...res.json() };
		else {
			const response = await res.json();
			return { status: res.status, ...response };
		}
	});
};
