import { BALLYS_THEME, TOKENS } from '../constants';
import { CacheStatus } from '../types';

export const getCookieValue = (cookieName: string) => {
	const cookieValue = document.cookie
		?.split(';')
		.find((x) => x.trim().startsWith(cookieName + '='));
	return cookieValue?.split('=')[1] ?? '';
};

export const saveRefreshToken = (token: string) => {
	return handleCacheOperation({
		type: 'SAVE_REFRESH_TOKEN',
		data: token
	});
};

export const deleteRefreshToken = () => {
	return handleCacheOperation({
		type: 'DELETE_REFRESH_TOKEN'
	});
};

export const getRefreshToken = async () => {
	return new Promise<any>(function (resolve) {
		(async () => {
			try {
				const data: string = await handleCacheOperation({ type: 'GET_REFRESH_TOKEN' });
				if (data) {
					resolve(data);
					return;
				}
			} catch (err) {
				console.log('EXCEPTION: GET_REFRESH_TOKEN KEY NOT FOUND');
				resolve(null);
			}
		})();
	});
};

export const saveFaceIdPopup = () => {
	return handleCacheOperation({
		type: 'SAVE_FACEID_POPUP'
	});
};

export const getFaceIdPopup = async () => {
	return new Promise<any>(function (resolve) {
		(async () => {
			try {
				const data: string = await handleCacheOperation({ type: 'GET_FACEID_POPUP' });
				if (data) {
					resolve(data);
					return;
				}
			} catch (err) {
				console.log('EXCEPTION: GET_FACEID_POPUP KEY NOT FOUND');
				resolve(null);
			}
		})();
	});
};

export const saveThemeValue = (value: string) => {
	return handleCacheOperation({
		type: 'SAVE_BALLYS_THEME',
		data: value
	});
};

export const checkThemeValue = async () => {
	return new Promise<any>(function (resolve) {
		(async () => {
			try {
				const data: string = await handleCacheOperation({ type: 'GET_BALLYS_THEME' });
				if (data) {
					resolve(data);
					return;
				}
			} catch (err) {
				console.log('EXCEPTION: THEME KEY NOT FOUND');
				resolve(null);
			}
		})();
	});
};

const handleCacheOperation = async (data: CacheStatus) => {
	try {
		if (data) {
			switch (data.type) {
				case 'SAVE_REFRESH_TOKEN':
					return saveInCache(data.data, TOKENS.REFRESH);
				case 'DELETE_REFRESH_TOKEN':
					return caches.delete(TOKENS.REFRESH);
				case 'GET_REFRESH_TOKEN': {
					const response = await caches.match(`/${TOKENS.REFRESH}`);
					if (response) {
						return Promise.resolve(response.json());
					}
					return Promise.reject('Cache:Unable to fetch');
				}
				case 'SAVE_FACEID_POPUP':
					return saveInCache('FACEID_POPUP', 'FACEID_POPUP');
				case 'GET_FACEID_POPUP': {
					const response = await caches.match(`/FACEID_POPUP`);
					console.log('response', response);
					if (response) {
						return Promise.resolve(response.json());
					}
					return Promise.reject('Cache:Unable to fetch');
				}
				case 'SAVE_BALLYS_THEME':
					return saveInCache(data.data, BALLYS_THEME);
				case 'GET_BALLYS_THEME': {
					const response = await caches.match(`/${BALLYS_THEME}`);
					if (response) {
						return Promise.resolve(response.json());
					}
					return Promise.reject('Cache:Unable to fetch');
				}
			}
		}
	} catch (error) {
		console.log('Cache: Some erroror ', error);
		return Promise.reject('Cache: Some error');
	}
};

const saveInCache = (data: any, cacheKey: string) => {
	const jsonResponse: Response = new Response(JSON.stringify(data), {
		headers: {
			'content-type': 'application/json'
		}
	});
	return caches.open(cacheKey).then((cache) => cache.put(`/${cacheKey}`, jsonResponse));
};

export const getSessionData = (key: string) => {
	const value = sessionStorage.getItem(key);
	return value;
};

export const setSessionItem = (key: string, value: any) => {
	sessionStorage.setItem(key, value);
	window.dispatchEvent(new Event('storage'));
};

export const getlocalStorageData = (key: string) => {
	const value = localStorage.getItem(key);
	return value;
};

export const setLocalStorageItem = (key: string, value: any) => {
	localStorage.setItem(key, value);
};

export const logoutSession = async () => {
	await deleteRefreshToken();
	sessionStorage.clear();
	window.dispatchEvent(new Event('storage'));
};
