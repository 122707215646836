import React, { FC, ReactNode } from 'react';
import { RouteProps } from 'react-router-dom';

export type Jurisdictions = 'UK' | 'ES' | 'US_NJ' | 'US_AZ' | 'US_NY' | 'CA_ON';
export type Locales = 'en-US' | 'en-UK' | 'es-ES' | 'fr-CA' | 'en-CA';
export type ImageSize = 'Small' | 'Medium' | 'Large';
export enum LoaderType {
	'grid',
	'banner',
	'promo',
	'bannerSecondary',
	'gallery',
	'restaurant',
	'experience',
	'title',
	'profileBanner',
	'rewardTier',
	'rewardCard',
	'default',
	'offerBanner',
	'offerTile'
}
export type Theme = 'light' | 'dark';

export type CardLessConnectionType =
	| 'GET_USER_DETAILS'
	| 'LOGGED_IN'
	| 'CONNECT_SLOT'
	| 'DISCONNECT_SLOT'
	| 'CONNECTION_STATUS'
	| 'CHECK_CONNECTION_STATUS'
	| 'LOGGED_OUT';

export type FaceIdConnectionType =
	| 'INIT_CONFIG'
	| 'CHECK_FACEID_STATUS'
	| 'FACEID_STATUS'
	| 'REGISTER_FACEID'
	| 'FACEID_REGISTRATION_STATUS'
	| 'FACEID_LOGIN'
	| 'FACEID_LOGIN_RESPONSE'
	| 'UNREGISTER_FACEID'
	| 'THEME_STATUS'
	| 'DUPLICATE_ACCOUNT';

export enum PAGESTATE {
	EXITING,
	EXITED
}

export enum Registraion_Stage {
	DEFAULT,
	LICENSE_SCAN,
	LICENSE_VERIFICATION,
	PROFILE_VERIFICATION,
	SET_PIN
}

export type GlobalData = {
	pageVisitCount: { [key: string]: number };
	propertyList?: PropertyList[];
	currentProperty: PropertyList;
	isAuthenticated: boolean;
	token: string;
};

export type USER = {
	userName?: string;
	email?: string;
	mobileNumber?: string;
	comp?: string;
	points?: string;
	tier?: string;
	playerId?: string;
	lastUpdated?: string;
	gender?: string;
	globalId?: string;
	isAuthenticated?: boolean;
	dob?: string;
	eventId?: string;
	preferredUsername?: string;
	sub?: string;
	tokenUse?: string;
	token?: string;
	firstName?: string;
	lastName?: string;
	pointsToNextTier?: string;
	idNumber?: string;
	idExpDate?: string;
	address1?: string;
	address2?: string;
	state?: string;
	zip?: string;
	isPlayerActive?: boolean;
	isLoading?: boolean;
};

export type UserStatusInCMS = 'NewUser' | 'RegisteredUser' | '';
export type UserNextStep = 'SetPin';

export interface AppContract {
	user?: USER;
	global: GlobalData;
	registration?: IRegistration;
	setUserDataInContext: (data: USER) => void;
}

export interface IRegistration {
	stage: Registraion_Stage;
	email: string;
	playerID: string;
	globalId: string;
	queryId: string | number;
	licenseScan?: {
		frontImage?: string;
		backImage?: string;
		formData?: IIdFormData;
	};
	profileScan?: {
		profileImage: string;
	};
	bonusState?: number[];
}

export interface IIdFormData {
	firstName: string;
	lastName: string;
	middleName?: string;
	idNumber: string;
	dob: string;
	idExpDate: string;
	address1: string;
	address2?: string;
	city: string;
	state: string;
	zip: string | number;
	idIssDate: string;
	globalId?: string;
}

export interface IIDScanResponse extends IIdFormData {
	email: string;
	globalId: string;
	queryId: string | number;
}

export interface PropertyList {
	siteCode: string;
	siteName: string;
	baseUri: string;
	customLanding: string;
	cvpsPropertyId?: string;
	mailersApiBaseUrl: string;
	image?: string;
	address?: string;
}
export interface ILoading {
	loading?: boolean;
}
export interface IBannerProps extends ILoading {
	banner_title: string;
	banner_subTitle: string;
	img: string;
	isSecondaryBanner?: boolean;
}
export interface IBannerCarouselProps {
	SlideHeading: string;
	SlideContent: string;
	SlideImage: CTAImage;
	isSecondaryBanner?: boolean;
}

export interface ICarouselProps {
	Slides: IBannerCarouselProps[];
	CTAs: IPromotionDetail[];
}
export interface ISlideProps extends ILoading {
	bannerSlides: ICarouselProps;
}
export interface ICasinoslProps {
	casinos: IBannerProps[];
}
export interface IHotelsProps {
	hotels: IBannerProps[];
}
export interface IDiningProps {
	dining: IBannerProps[];
}
export interface ICardType extends ILoading {
	slides: IPromotionDetail[];
}

export interface IRailsCardType extends ILoading {
	slides: RailsSlides[];
}
export interface CTAImage {
	ThumbURL: string;
	FullURL: string;
	RecordId: number;
}

export interface ListItemFeedImage {
	ThumbURL: string;
	FullURL: string;
	RecordId: number;
}

export interface CTAButton {
	CTAButtonButtonLink: string;
}
export interface IRewardsCards {
	SlideHeading: string;
	SlideImage: CTAImage;
}

export interface IOpeningData {
	SlideContent: string;
	SlideHeading: string;
	SlideImage: CTAImage;
	SlideOptionalSlideClass: string;
}
export interface IPromotionDetail {
	CTAImage: CTAImage;
	CTAAltText: string;
	CTAHeading: string;
	CTACopy: string;
	CTAButtons: CTAButton[];
}

export interface Promotions {
	CTAs: IPromotionDetail[];
}

export interface RailsSlides {
	SlideImage: CTAImage;
	SlideHeading: string;
	SlideImageLink: string;
}
export interface Rails {
	Slides: RailsSlides[];
}

export interface ILink {
	label: string;
	external?: boolean;
	className?: string;
	icon?: string;
	to: string;
	exact?: boolean;
	subLink?: ILink[];
	childRoutes?: string[];
	authenticationFlow?: boolean;
	checkedValue?: boolean;
	action?: (e?: any) => void;
	componentType?: 'link' | 'checkbox';
	isChecked?: boolean;
}
export interface IChildrenHoc {
	children: React.ReactNode;
}
export interface IRedirection extends ILoading {
	links: ILink[];
	blockCount?: number;
}
export interface IHeaderProps {
	title?: string;
	isAuthenticationFlowRoute?: boolean;
}
export interface IRestaurantItemProps extends ILoading {
	img: string;
	title: string;
	secondaryTitle?: string;
	redirectTo: string;
	external?: boolean;
}

export interface IRestaurant {
	ItemImage: ListItemFeedImage;
	ItemHeading: string;
	ItemSubheading: string;
	ItemButtons: {
		ItemButtonLink: string;
	}[];
}

export interface IExperiencesBannerProps {
	Image: CTAImage;
	Intros: {
		IntroHeadingText: string;
		IntroIntroParagraph: string;
	}[];
}
export interface IImageGallery {
	CTAs: {
		CTAImage: CTAImage;
	}[];
}
export interface Disclaimer {
	disclaimerImg: DisclaimerImg[];
	disclaimertext: string;
}
export interface DisclaimerImg {
	ColumnResponsiveImage: CTAImage;
	ColumnResponsiveImageAltText?: string;
}
export interface IDisclaimerProps {
	RichText: string;
	Columns: DisclaimerImg[];
}
export interface IRewardsProps {
	Columns: {
		ColumnResponsiveImage: CTAImage;
		ColumnResponsiveImageAltText?: string;
	}[];

	Intros: {
		IntroHeadingText: string;
		IntroIntroParagraph: string;
	}[];
}

export interface IProfileTierCardProps {
	Columns: {
		ColumnResponsiveImage: CTAImage;
		ColumnResponsiveImageAltText?: string;
	}[];
}
export interface IPopupDrawer {
	data: {
		icon?: string;
		title?: string;
		img?: string;
		desc_heading?: string;
		desc_text_style?: string;
		description: string;
		sub_desc?: { phone_no: string; addr_1?: string; loc: string };
		negativeButtonText?: string;
		positiveButtonText: string;
	};
	closeOnOverlay?: boolean;
	errorImage?: ReactNode;
	descHeading?: ReactNode;
	subDesc?: ReactNode;
	handleNegativeButton?: () => void;
	handlePositiveButton: () => void;
}
export interface IProfilesDetailProps {
	title: string;
	subtitle?: string;
	icon: string;
}

export interface IProfileDetails {
	user: {
		firstName: string;
		lastName: string;
		comp: string;
		email: string;
		globalId: string;
		points: string;
		pointsToNextTier: string;
		tier: string;
		user_id: string;
		username: string;
		cellular?: string;
		gender?: string;
		playerId?: string;
		themePreference: string;
		trackData: string;
	};
	status: number;
}

export type CacheStatus = {
	type:
		| 'LOGGED_IN'
		| 'LOGGED_OUT'
		| 'CHECK_STATUS'
		| 'LOGIN_STATUS'
		| 'SAVE_REGISTRATION'
		| 'CHECK_REGISTRATION'
		| 'REGISTRATION_DETAILS'
		| 'REMOVE_REGISTRATION'
		| 'SAVE_REFRESH_TOKEN'
		| 'DELETE_REFRESH_TOKEN'
		| 'GET_REFRESH_TOKEN'
		| 'SAVE_FACEID_POPUP'
		| 'GET_FACEID_POPUP'
		| 'SAVE_BALLYS_THEME'
		| 'GET_BALLYS_THEME';

	data?: string;
};

export type CameraInterface = {
	setImgSrc: (image: string) => void;
	toggleCamera: () => void;
	handleConfirm?: () => void;
};

export interface IdScanInterface extends CameraInterface {
	headingText: string;
}
export interface OTPInputProps {
	length: number;
	onChangeOTP: (otp: string) => void;
	autoFocus?: boolean;
	isNumberInput?: boolean;
	className?: string;
	inputClassName?: string;
	focus?: boolean;
	isInputRef?: boolean;
	onFocus: () => void;
	otp: string;
	isMasked?: boolean;
}

export type UserStatusProps = {
	blocked: boolean;
	email: string;
	nextState: string;
	playerID: string;
	status: string;
	globalId?: string;
};
export interface TextInputInterface extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string;
	datatestid?: string;
	error?: string;
	ref?: any;
}

export interface ReadioButtonInterface extends TextInputInterface {
	handleSelectAnswer: (data: { quesId: string | undefined; label: string }) => void;
	payload: { [keys: string]: string };
}
export interface IScanCardProps {
	heading: string;
	cardImage: string | null;
	handleDeleteImage: () => void;
	openCamera: (e: React.MouseEvent<HTMLButtonElement>) => void;
	buttonText: string;
	buttonId: string;
}

export interface IQuestionsProps {
	handleSelectAnswer: (data: { quesId: string | undefined; label: string }) => void;
	payload: { [keys: string]: string };
}
export interface IQuestionInfo {
	prompt: string;
	type: string;
	answer: string[];
}

export interface IVQuestionsProps extends IQuestionsProps {
	questionData: IQuestionInfo[];
}

export interface IQuesAnsProps extends IQuestionsProps {
	data: IQuestionInfo;
	questionIndex: number;
}

export interface ProtectedRouteProps extends RouteProps {
	component: FC;
	currentProperty: PropertyList;
}

export enum TABS {
	BALNCES,
	OFFERS
}

export interface TabProps {
	icon: string;
	label: string;
	active?: boolean;
	onTabClick?: (e: any) => void;
}

export interface TabsProps {
	tabs: {
		icon: string;
		label: string;
	}[];
	onChange: (evt: any) => void;
	defaultTab?: number;
	children: React.ReactNode;
}

export interface PortraitTileProps {
	images: string[];
	scrollable: boolean;
}

export interface ParkingPaymentDetails {
	BriefErrorMessage: string;
	ErrorMessage: string;
	IsSuccess: boolean;
	MessageCode: number;
	Discounts: any[];
	FolioNumber: string;
	FreeDays: number;
	FullTaxes: any[];
	HostedPartyAmount: number;
	IsEventParking: boolean;
	PaidDays: number;
	PaidUntil: string;
	ParkingFee: number;
	PlayerCardInfo: null | any;
	PrePaidAmount: number;
	RateId: number;
	Taxes: any[];
	Ticket: {
		ArrivalByEmployeeFullName: string;
		ArrivalDateTime: string;
		ArrivalLocationID: number;
		DepartureDateTime: string | null;
		Discounts: any[];
		Driver: {
			CanExpressPay: boolean;
			CardLast4digits: string;
			DriverId: number;
			Email: string;
			Firstname: string;
			Lastname: string;
			Phone: string;
		};
		GuestType: {
			Id: number;
			IsSelfParking: boolean;
			Name: string;
			SystemGuestTypeId: number;
		};
		HotelGuestName: string;
		HotelRegistrationNumber: string;
		HotelRoomNumber: string;
		Id: number;
		IsCallInRequest: boolean;
		ParkingFee: number;
		Rate: {
			Description: string;
			Id: number;
		};
		RequestByEmployeeFullName: string;
		RequestDateTime: string | null;
		RequestLocationID: number;
		ReturnDateTime: string | null;
		Services: null | any;
		SystemGuestType: number;
		Taxes: null | any;
		TicketNumber: string;
		TotalAmountPaid: number;
		Vehicle: {
			Color: {
				Description: string;
				Id: number;
			};
			IsRental: boolean;
			LastVisitDateTime: string;
			License: string;
			LicenseNormalized: string;
			LicenseStateAbbrev: string;
			Make: {
				Description: string;
				Id: number;
			};
			Model: {
				Description: string;
				Id: number;
			};
			Notes: string;
			VIN: string;
			VehicleId: number;
			VisitCount: number;
		};
	};
	TotalBilled: number;
	TotalDiscounts: number;
	TotalDiscountsCount: number;
	TotalFee: number;
	TotalFullTaxes: number;
	TotalPlayerCardCharges: number;
	TotalRoomCharges: number;
	TotalServices: number;
	TotalServicesCount: number;
	TotalTaxes: number;
}

export interface IMobileNumber {
	errorMsg: string;
	setErrorMsg: (value: string) => void;
	isLoading: boolean;
}

export interface IGetVerifyOtp {
	action: string;
	phoneNumber?: string;
	otp?: string;
	refreshToken?: string;
	metaData?: {
		ChallengeParameters: {
			USERNAME: string;
			email: string;
		};
		Session: string;
	};
}

export interface IGetVerifyPin {
	phoneNumber: string;
	pin: string;
	playerId: string;
	siteCode: string;
}

export interface ICountryCode {
	name: string;
	dial_code: string;
	code: string;
}

export type FaceIdPayloadType = {
	type: string | number | any;
	data?: {
		xApiKey?: string;
		endpoint?: string;
		globalId?: string;
	};
};

export type CardlessConnectionStatus = {
	connectionStatus: 'SCANNING' | 'CONNECTED' | 'DISCONNECTED' | 'CONNECTING' | 'DISCONNECTING';
};
export type setConnectionValueType = {
	setConnectionValue: (value: any) => void;
};

export type BiometricStatus = {
	isFaceIdAvailable: false;
	isFaceIdSetup: false;
	globalId: string;
	postMessagetoNative: (type: FaceIdConnectionType | CardLessConnectionType, data?: any) => void;
};

export type Ipopup = {
	icon?: string;
	title?: string;
	desc_heading?: string;
	description: string;
	sub_desc?: { phone_no: string; addr_1?: string; loc: string };
	positiveButtonText: string;
	negativeButtonText?: string;
	positiveCallback: () => void;
	negativeCallback?: () => void;
};

export interface ILDFeatureList {
	isFaceIDFeatureAvailable: boolean;
	isLightThemeFeatureAvailable: boolean;
	isCountrySwitchFeatureAvailable: boolean;
	isCardlessFeatureAvailable: boolean;
}
