import { HTMLProps, useCallback, useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import { useCongnitoUser, useGlobalContext, usePageTitle } from '../hooks';
import Footer from '../components/Footer/Footer';
import { getFooterLinks } from '../service/appService';
import BottomNotification from '../components/BottomNotification/BottomNotification';

interface ILayoutProps extends HTMLProps<HTMLDivElement> {
	noHeader?: boolean;
	noFooter?: boolean;
	dataTestid?: string;
}
const Layout = ({ className, children, noHeader, noFooter, dataTestid }: ILayoutProps) => {
	const titleData = usePageTitle();
	const [isFooterLoading, setFooterLoading] = useState<boolean>(true);
	const { user } = useGlobalContext();
	const { congnitoIsAuthenticated, congnitoUser, accessToken } = useCongnitoUser();

	useEffect(() => {
		//make footer enable
		if (!(congnitoIsAuthenticated && !congnitoUser?.globalId)) {
			setFooterLoading(true);
		}
		setFooterLoading(false);
	}, [congnitoIsAuthenticated, congnitoUser, accessToken]);

	const shouldBeVisible = useCallback(() => {
		return (
			!noFooter &&
			congnitoIsAuthenticated &&
			!!Object.keys(user ?? {}).length &&
			user?.isPlayerActive === false
		);
	}, [congnitoUser, congnitoIsAuthenticated, user?.isPlayerActive]);

	return (
		<div
			className={`ballys-app ${className} ${noHeader ? 'no-header' : ''} ${
				noFooter ? 'no-footer' : ''
			} ${shouldBeVisible() ? 'notification-visible' : ''}`}
			data-testid={dataTestid}
		>
			{noHeader ? null : (
				<Header
					title={titleData?.label}
					isAuthenticationFlowRoute={titleData?.isAuthenticationFlowRoute}
				/>
			)}
			<main id="" className="container" data-testid="main">
				{children}
				{shouldBeVisible() && <BottomNotification></BottomNotification>}
			</main>
			{noFooter ? null : <Footer loading={isFooterLoading} links={getFooterLinks()} />}
		</div>
	);
};

export default Layout;
