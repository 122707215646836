import React from 'react';
import Loader from '../../components/Loader';
import { GlobalContext } from '../../context/GlobalContext';
import { LoaderType } from '../../types';
import './SkeltonLoader.scss';

const withSkeltonLoader = <P extends object>(
	Component: React.ComponentType<P>,
	loaderType: LoaderType
) => {
	/* eslint-disable */
	return class extends React.Component<
		P & { loading?: boolean; isSecondaryBanner?: boolean; blockCount?: number },
		{ showSecondaryBanner: boolean; loadingState: boolean }
	> {
		/* eslint-enable */
		static contextType = GlobalContext;
		context: React.ContextType<typeof GlobalContext> | undefined;

		constructor(props: P) {
			super(props);
			this.state = { showSecondaryBanner: false, loadingState: false };
		}

		componentDidMount(): void {
			const pageVisits = this.context?.global.pageVisitCount;
			const currPath = location.pathname.replace(
				this.context?.global.currentProperty.baseUri ?? '',
				''
			);

			if (this.props.isSecondaryBanner) this.setState({ showSecondaryBanner: true });
			if (this.props.loading) this.setState({ loadingState: this.props.loading });
			if (pageVisits && pageVisits[currPath] > 1) {
				this.setState({ loadingState: false });
			}

			// Loader fallback timeout
			setTimeout(() => {
				this.setState({ loadingState: false });
			}, 5000);
		}

		componentDidUpdate(
			prevProps: Readonly<
				P & {
					loading?: boolean;
					isSecondaryBanner?: boolean;
					blockCount?: number;
				}
			>
		): void {
			if (prevProps.loading !== this.props.loading) {
				this.setState({ loadingState: false });
			}
		}

		getLoaderType = () => {
			if (this.state.showSecondaryBanner) return LoaderType.bannerSecondary;
			return loaderType;
		};

		render() {
			return this.state.loadingState ? (
				<Loader
					loaderType={this.getLoaderType()}
					blockCount={this.props.blockCount}
					data-testid="testid-loader"
				/>
			) : (
				<Component {...(this.props as P)} />
			);
		}
	};
};

export default withSkeltonLoader;
