import { LDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { IChildrenHoc, ILDFeatureList } from '../../types';
import { localLDContext } from '../../context/GlobalContext';
import { useMemo } from 'react';
import { getCookieValue, useCurrentProperty } from '../../hooks';
import { PLATFORM_COOKIE, VERSION_COOKIE } from '../../constants';

export const LaunchDarkly_Local = ({ children }: IChildrenHoc) => {
	const { rewardsOneappFeatures } = useFlags();
	const { siteCode } = useCurrentProperty();
	const currVersion = parseFloat(getCookieValue(VERSION_COOKIE).split('(')[0]);
	const currPlatform = getCookieValue(PLATFORM_COOKIE).toLowerCase();

	console.log('LD: rewardsChicagoFeatures', siteCode, rewardsOneappFeatures);

	const allFeatures = useMemo<ILDFeatureList>(() => {
		if (currVersion && currPlatform) {
			return {
				isFaceIDFeatureAvailable:
					currVersion >= rewardsOneappFeatures?.[siteCode]?.faceId?.minimumVersion?.[currPlatform],
				isLightThemeFeatureAvailable:
					currVersion >=
					rewardsOneappFeatures?.[siteCode]?.lightTheme?.minimumVersion?.[currPlatform],
				isCardlessFeatureAvailable:
					currVersion >=
					rewardsOneappFeatures?.[siteCode]?.cardless?.minimumVersion?.[currPlatform],
				isCountrySwitchFeatureAvailable: true
			};
		} else {
			return {
				isFaceIDFeatureAvailable: false,
				isLightThemeFeatureAvailable: false,
				isCardlessFeatureAvailable: false,
				isCountrySwitchFeatureAvailable: true
			};
		}
	}, [currVersion, currPlatform, rewardsOneappFeatures]);

	return <localLDContext.Provider value={allFeatures}>{children}</localLDContext.Provider>;
};

const LaunchDarkley = ({ children }: IChildrenHoc) => {
	const { REACT_APP_LAUNCH_DARKLEY_CLIENT_ID } = process.env;
	if (!REACT_APP_LAUNCH_DARKLEY_CLIENT_ID) {
		return <>{children}</>;
	}

	return (
		<LDProvider clientSideID={REACT_APP_LAUNCH_DARKLEY_CLIENT_ID}>
			<LaunchDarkly_Local>{children}</LaunchDarkly_Local>
		</LDProvider>
	);
};

export default LaunchDarkley;
