import jsonp from 'fetch-jsonp';
import { API_ROOT, GET_REWARD_IMAGE, GET_REWARD_DESCRIPTION } from '../constants/endPoints';
import { AppContract, IProfilesDetailProps, IRewardsProps } from '../types';

export const getRewardImage = (): Promise<IRewardsProps> => {
	return jsonp(`${API_ROOT}/${GET_REWARD_IMAGE}.js`, {
		jsonpCallbackFunction: `${GET_REWARD_IMAGE}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IRewardsProps>;
	});
};
export const getRewardInformation = (): Promise<IRewardsProps> => {
	return jsonp(`${API_ROOT}/${GET_REWARD_DESCRIPTION}.js`, {
		jsonpCallbackFunction: `${GET_REWARD_DESCRIPTION}DataCallback`
	}).then((response) => {
		return response.json();
	});
};

export const rewardLabel = {
	loginLabel: {
		label: 'LOG IN'
	},
	learnLabel: {
		label: 'Learn More'
	},
	linkReward: {
		to: 'https://casinos.ballys.com/lake-tahoe/rewards-club.htm'
	}
};

//logged in user

export const rewardLoggedLabel = {
	wlcLabel: {
		label: 'Welcome,'
	},
	learnLabel: {
		label: 'Learn More',
		link: 'https://casinos.ballys.com/chicago/rewards-club.htm'
	}
};

export const registerLabel = {
	registerYourSelf: 'Register Yourself',
	description: `Link your Bally's Lake Tahoe account or register at our property to earn Bally Bucks and other exclusive perks!`,
	register: {
		label: 'Register'
	},
	learnLabel: {
		label: 'Learn More'
	},
	linkReward: {
		to: 'https://casinos.ballys.com/lake-tahoe/rewards-club.htm'
	}
};

export const rewardTierLabel = ({ user }: AppContract): IProfilesDetailProps[] => {
	let rewardTierLabelList = [
		{
			title: 'Tier Level',
			subtitle: user?.tier,
			icon: 'icon-tier'
		}
	];

	if (user?.points) {
		rewardTierLabelList = [
			...rewardTierLabelList,
			{
				title: 'Tier Score',
				subtitle: user?.points,
				icon: 'icon-score'
			}
		];
	}
	return rewardTierLabelList;
};
