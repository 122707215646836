import { useEffect, useState, useMemo, useCallback } from 'react';
import { IIdFormData, UserStatusInCMS } from '../../types';
import './index.scss';
import Layout from '../../hoc/Layout';
import { getformData, patronRegVerbiage } from '../../service/registerService';
import {
	getCookieValue,
	getSessionData,
	saveRefreshToken,
	setSessionItem,
	useGlobalContext
} from '../../hooks';
import { ONBOARDING, PLATFORM_COOKIE, ROUTE_CONSTANTS, TOKENS } from '../../constants';

import spin_coin_img from '../../assets/bally_buck_coin.svg';
import spin_arrow_img from '../../assets/spin-arrow.svg';
import VerifyInfo from './VerifyInfo';
import VerifyPin from './VerifyPin';
import { useHistory, useLocation } from 'react-router';

const Verification = () => {
	const { regiesterPageImage, winBonusPageLabel } = patronRegVerbiage;

	const spin_img = `url(${process.env.PUBLIC_URL + winBonusPageLabel.spinNumber.url})`;
	const ring_img = `url(${process.env.PUBLIC_URL + regiesterPageImage.spinWheel.url_Ring})`;
	const win_bonus_bg = `url(${process.env.PUBLIC_URL + regiesterPageImage.spinWheel.url_bg})`;

	const audioPath = `${process.env.PUBLIC_URL + winBonusPageLabel.spinAudio.url}`;

	const { user, setUserDataInContext: setData, global } = useGlobalContext();
	const history = useHistory();
	const location: {
		state: {
			mobileNumber: string;
			showVerifyPin: boolean;
		};
	} = useLocation();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showVerifyPin, setShowVerifyPin] = useState<boolean>(false);
	const [playerData, setPlayerData] = useState<{ playerId: string }>({
		playerId: ''
	});
	const [isPlayerIDAvailable, setIsPlayerIDAvailable] = useState<boolean>(true);
	const [eventMsgData, setEventMsgData] = useState({
		globalId: '',
		authResponse: {
			AccessToken: null,
			IdToken: null,
			RefreshToken: ''
		}
	});
	const [idFormData, setIdFormData] = useState<IIdFormData>({
		firstName: '',
		lastName: '',
		middleName: '',
		idNumber: '',
		dob: '',
		idExpDate: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		idIssDate: ''
	});

	useEffect(() => {
		if (location?.state?.showVerifyPin) {
			setIsPlayerIDAvailable(false);
			setIsLoading(false);
			setShowVerifyPin(true);
		}
	}, [location?.state?.showVerifyPin]);

	const handleEvent = async (evntMsg: any) => {
		console.log('evntMsg', evntMsg);
		try {
			if (typeof evntMsg?.data == 'string') {
				const message = JSON.parse(evntMsg.data);
				console.log('message', message);
				const { data, type } = message;
				if (type === ONBOARDING.ONBOARDING_SCAN_COMPLETED) {
					setIsLoading(false);
					setEventMsgData(data?.data);
					setIdFormData(getformData(data?.data));
				} else if (type === ONBOARDING.DUPLICATE_ACCOUNT) {
					console.log('>>>>>>>>>>>>>>>>>>>>', data?.errorType);
					// If not an EXCLUDED user from SCAN API
					if (!data?.errorType) {
						setPlayerData(data?.data);
						if (!data?.data?.playerId) {
							setIsPlayerIDAvailable(false);
						}
						setIsLoading(false);
						setShowVerifyPin(true);
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		const currPlatform = getCookieValue(PLATFORM_COOKIE).toLowerCase();
		let target: any = window;
		if (currPlatform === 'android') {
			target = document;
		}
		target.addEventListener('message', handleEvent);
		return () => target.removeEventListener('message', handleEvent);
	}, []);

	useEffect(() => {
		console.log('pageVisitCount: ', global?.pageVisitCount);
		if (global?.pageVisitCount && Object.keys(global?.pageVisitCount).length == 1) {
			history.goBack();
		}
	}, [global?.pageVisitCount]);

	const onVerificationSuccess = useCallback(async () => {
		setData({
			...user,
			isLoading: true
		});
		//>> Mark current user as Logged in user and move refresh token from SESSION to CACHE
		setSessionItem(TOKENS.USER_STATUS, 'RegisteredUser' as UserStatusInCMS);
		await saveRefreshToken(getSessionData(TOKENS.REFRESH) ?? '');

		history.replace(ROUTE_CONSTANTS.LOBBY);
	}, [eventMsgData, history, useCallback]);

	const getVeificationComponent = useMemo(() => {
		if (showVerifyPin) {
			return (
				<VerifyPin
					mobileNumber={location.state.mobileNumber}
					playerData={playerData}
					onVerificationSuccess={onVerificationSuccess}
					setPlayerData={setPlayerData}
					isPlayerIDAvailable={isPlayerIDAvailable}
				/>
			);
		} else {
			return (
				<VerifyInfo
					// eventMsgData={eventMsgData}
					idFormData={idFormData}
					setIdFormData={setIdFormData}
					// onVerificationSuccess={onVerificationSuccess}
				/>
			);
		}
	}, [playerData?.playerId, showVerifyPin, idFormData, eventMsgData]);

	return (
		<>
			<audio
				preload="auto"
				src={`${audioPath}`}
				className="is-hidden"
				hidden
				muted={true}
				autoPlay={true}
				loop={false}
			></audio>
			<Layout
				className="profile-verification"
				dataTestid="testid-verification"
				noHeader={true}
				noFooter={true}
			>
				{isLoading ? <div className="loading-container loading" /> : getVeificationComponent}
			</Layout>
			<div className="visibility-hidden">
				<div style={{ backgroundImage: spin_coin_img }}></div>
				<span style={{ backgroundImage: ring_img }}></span>
				<span style={{ backgroundImage: spin_img }}></span>
				<span style={{ backgroundImage: spin_arrow_img }}></span>
				<span style={{ backgroundImage: win_bonus_bg }}></span>
			</div>
		</>
	);
};

export default Verification;
