import { MouseEvent, useCallback, useState } from 'react';
import Layout from '../../hoc/Layout';
import './LoginOptions.scss';
import InputButton from '../../components/InputField/InputButton';
import { useHistory, useLocation } from 'react-router-dom';
import { ONBOARDING, ROUTE_CONSTANTS, TOKENS } from '../../constants';
import { setSessionItem, useCongnitoUser, useCurrentProperty } from '../../hooks';
import { loginVaribages } from '../../service/loginService';
declare const window: any;

const LoginOptions = () => {
	const { loginOption } = loginVaribages;
	const [isRegisterOptionSelected, setIsRegisterOptionSelected] = useState<boolean | undefined>();
	const currentProperty = useCurrentProperty();
	const { congnitoUser } = useCongnitoUser();
	const history = useHistory();
	const location: {
		state: {
			mobileNumber: string;
			refreshToken: string;
		};
	} = useLocation();

	const handleRegisterOption = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		setIsRegisterOptionSelected(true);
	}, []);

	const handleLinkAccountOption = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		setIsRegisterOptionSelected(false);
	}, []);

	const handleContinue = useCallback(() => {
		if (isRegisterOptionSelected == undefined) return;
		if (isRegisterOptionSelected) {
			setSessionItem(TOKENS.REFRESH, location.state?.refreshToken);
			const _data = {
				type: ONBOARDING.ONBOARDING_JOURNEY,
				data: {
					globalId: congnitoUser['cognito:username'],
					siteCode: currentProperty.siteCode
				}
			};
			window?.ReactNativeWebView?.postMessage(JSON.stringify(_data));
			console.log('data sent to Native ', JSON.stringify(_data));

			history.replace({
				pathname: ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_INFO,
				state: {
					mobileNumber: location.state.mobileNumber,
					showVerifyPin: false
				}
			});
		} else {
			history.push({
				pathname: ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_INFO,
				state: {
					mobileNumber: location.state.mobileNumber,
					showVerifyPin: true
				}
			});
		}
	}, [isRegisterOptionSelected, congnitoUser]);
	return (
		<Layout className="login-choose-option" dataTestid="testid-login-option" noFooter={true}>
			<div>
				<div className="form-section">
					<h2 className="form-heading headline-medium">{loginOption.heading}</h2>
					<p className="form-sub-heading body-text-medium">{loginOption.description}</p>
				</div>
				<a
					className={`option-container ${isRegisterOptionSelected === true ? 'active' : ''}`}
					href=""
					id={'option-register-container'}
					data-testid="test-option-register"
					onClick={handleRegisterOption}
				>
					<i
						id={'option-register-check'}
						className={`select ${isRegisterOptionSelected === true ? 'icon-tick' : ''}`}
					/>
					<i id={'option-register-icon'} className="icon-preview"></i>
					<p id={'option-register-text'} className="option-text body-text-medium">
						{loginOption.registerOption}
					</p>
				</a>
				<a
					className={`option-container ${isRegisterOptionSelected === false ? 'active' : ''}`}
					href=""
					id={'option-link-container'}
					data-testid="test-option-link"
					onClick={handleLinkAccountOption}
				>
					<i
						id={'option-link-check'}
						className={`select ${isRegisterOptionSelected === false ? 'icon-tick' : ''}`}
					/>
					<i id={'option-link-icon'} className="icon-link"></i>
					<p id={'option-link-text'} className="option-text body-text-medium">
						{loginOption.linkAccOption}
					</p>
				</a>
			</div>
			<InputButton varient="primary" datatestid="continue-btn" onClick={handleContinue}>
				{'Continue'}
			</InputButton>
		</Layout>
	);
};

export default LoginOptions;
