import { useCallback, useState } from 'react';
import './index.scss';
import UserDetailsForm from '../../components/UserDetailsForm/UserDetailsForm';
import { getIdVerificationPayload } from '../../service/registerService';
import { confirmIdDetails, loginVaribages } from '../../service/loginService';
import { useCongnitoUser, useCurrentProperty } from '../../hooks';
import PopupDrawer from '../../components/PopupDrawer';
import { useHistory } from 'react-router';
import { ROUTE_CONSTANTS } from '../../constants';
import InputButton from '../../components/InputField/InputButton';

const VerifyInfo = (props: any) => {
	const { setIdFormData, idFormData } = props;
	const history = useHistory();
	const currentProperty = useCurrentProperty();
	const { accessToken, congnitoUser } = useCongnitoUser();
	const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);
	const [errorMessage, setShowErrorMessage] = useState<string>('');

	const handleContinue = async (e: any) => {
		e.preventDefault();
		setIsConfirmDisabled(true);
		try {
			const _globalId = congnitoUser['cognito:username'];
			if (_globalId) {
				const res = await confirmIdDetails(
					accessToken,
					_globalId,
					{
						...getIdVerificationPayload(idFormData)
					},
					currentProperty.siteCode
				);
				if (res?.currStatus == 200) {
					console.log('Update profie success', res);
					// IN CASE SSN REQUIRED  BELOW LINE REDIRECT TO SSN PAGE
					history.replace({
						pathname: ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_SSN,
						state: { globalId: _globalId }
					});
					// IN CASE SSN NOT REQUIRED COMMENT ABOVE LINE AND UNCOMMENT BELOW LINE
					// props.onVerificationSuccess(eventMsgData?.authResponse);
				} else if (res?.currStatus == 400) {
					setShowErrorMessage(res.response);
				}
			}
		} catch (err: any) {
			console.error('Update Profile error: ', err);
		} finally {
			setIsConfirmDisabled(false);
		}
	};

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setIdFormData({
				...idFormData,
				...{ [e.target.id]: e.target.value }
			});
		},
		[idFormData]
	);

	const handlePositiveButton = useCallback(() => {
		setShowErrorMessage('');
	}, []);

	return (
		<>
			<form
				className="verification-form"
				onSubmit={handleContinue}
				data-testid="testid-verification-form"
			>
				<div className="userform-container">
					<h2 className="heading-text headline-medium">{loginVaribages.verifyInfo.heading}</h2>
					<label className="secondary-heading-text body-text-medium">
						{loginVaribages.verifyInfo.description}
					</label>
				</div>
				<UserDetailsForm
					isNameDisabled={true}
					idFormData={idFormData}
					handleChange={handleChange}
				/>
				<div className="continue-action">
					<InputButton disabled={isConfirmDisabled} varient="primary" datatestid="testid-confirm">
						{loginVaribages.verifyInfo.btn.continue}
					</InputButton>
				</div>
			</form>
			{errorMessage && (
				<PopupDrawer
					errorImage={
						<div className="error-icon-box">
							<i
								className={`icon-modal-view`}
								style={{
									backgroundImage: `url(${loginVaribages.verifyOtp.errorPopup.icon})`
								}}
							></i>
						</div>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">
							{loginVaribages.verifyOtp.errorPopup.desc_heading}
						</h2>
					}
					data={{ ...loginVaribages.verifyOtp.errorPopup, description: errorMessage }}
					handleNegativeButton={handlePositiveButton}
					handlePositiveButton={handlePositiveButton}
				/>
			)}
		</>
	);
};

export default VerifyInfo;
