import Layout from '../../../hoc/Layout';
import './SetPin.scss';
import { loginVaribages } from '../../../service/loginService';
import OtpInput from '../../../components/InputField/OtpInput';
import { useCallback, useEffect, useState } from 'react';
import InputButton from '../../../components/InputField/InputButton';
import { useHistory, useLocation } from 'react-router-dom';
import { patronRegVerbiage, setPinCodeAPI } from '../../../service/registerService';
import {
	getSessionData,
	saveRefreshToken,
	setSessionItem,
	useCurrentProperty
} from '../../../hooks';
import { ROUTE_CONSTANTS, TOKENS } from '../../../constants';
import { UserStatusInCMS } from '../../../types';
import { errorPageVerbiage } from '../../../service/appService';
const SetPin = () => {
	const { setPin } = loginVaribages;
	const { setPinCodeLabel } = patronRegVerbiage;
	const history = useHistory();
	const [isSetPINButtonDisable, setIsSetPINButtonDisable] = useState(false);
	const [errorMSG, setErrorMSG] = useState<string>('');
	const currentProperty = useCurrentProperty();
	const location: {
		state: {
			globalId: '';
		};
	} = useLocation();
	const [PIN, setPINState] = useState('');
	const [confirmPIN, setConfirmPINState] = useState('');
	const [otpAutoFocus, setOtpAutoFocus] = useState<boolean>(true);
	const [errorMsg, setErrorMsg] = useState('');
	useEffect(() => {
		if (PIN.length === 4 && confirmPIN.length === 4) {
			console.log(errorMSG);
			if (PIN !== confirmPIN) {
				setErrorMSG(setPinCodeLabel.inputlabel.errorPinMsg);
			}
		} else {
			setErrorMSG('');
		}
	}, [PIN, confirmPIN]);
	const handleOnOTPFocus = useCallback(() => {
		setOtpAutoFocus(true);
	}, []);
	const handlePinChange = useCallback(
		(pin: string) => {
			setErrorMsg('');
			setPINState(pin);
		},
		[PIN]
	);
	const handleConfirmPinChange = useCallback(
		(confirmPIN: string) => {
			setErrorMsg('');
			setConfirmPINState(confirmPIN);
		},
		[confirmPIN]
	);
	const handleContinue = useCallback(async () => {
		if (PIN.length < 4 && confirmPIN.length < 4) {
			return;
		}
		if (location?.state?.globalId) {
			try {
				setIsSetPINButtonDisable(true);
				const res: any = await setPinCodeAPI(
					location?.state?.globalId,
					PIN,
					currentProperty.siteCode
				);
				if (res?.playerId) {
					//>> Mark current user as Logged in user and move refresh token from SESSION to CACHE
					setSessionItem(TOKENS.USER_STATUS, 'RegisteredUser' as UserStatusInCMS);
					await saveRefreshToken(getSessionData(TOKENS.REFRESH) ?? '');
					history.replace(ROUTE_CONSTANTS.LOBBY);
				}
				setIsSetPINButtonDisable(false);
			} catch (e) {
				setIsSetPINButtonDisable(false);
				setErrorMSG(errorPageVerbiage.title);
				console.log(e);
			}
		}
	}, [PIN, confirmPIN]);
	const handleConfirmFocus = useCallback(() => {
		setOtpAutoFocus(false);
	}, []);
	return (
		<Layout className="login-setpin" dataTestid="testid-login-option" noFooter={true}>
			<div>
				<div className="form-section">
					<h2 className="form-heading headline-medium">{setPin.heading}</h2>
					<p className="form-sub-heading body-text-medium">{setPin.subHeading}</p>
				</div>
			</div>
			<p className="input-heading label-medium">{setPin.enterPin}</p>
			<div className={`otp-container heading`}>
				<OtpInput
					onFocus={handleOnOTPFocus}
					length={4}
					autoFocus={otpAutoFocus}
					inputClassName={`single-otpInput ${errorMsg ? 'error' : ''}`}
					onChangeOTP={handlePinChange}
					isInputRef={true}
					otp={PIN}
					isMasked={true}
				/>
			</div>
			<p className="input-heading label-medium">{setPin.confirmPin}</p>
			<div className={`otp-container heading`}>
				<OtpInput
					onFocus={handleConfirmFocus}
					length={4}
					autoFocus={otpAutoFocus}
					inputClassName={`single-otpInput ${errorMsg ? 'error' : ''}`}
					onChangeOTP={handleConfirmPinChange}
					isInputRef={false}
					otp={confirmPIN}
					isMasked={true}
				/>
			</div>
			{errorMSG && (
				<div className="pin-error-msg">
					<p className="error-msg-text error-msg" data-testid="testid-error-msg">
						{errorMSG}
					</p>
				</div>
			)}
			<InputButton
				className="btn-continue"
				varient="primary"
				type="submit"
				datatestid="setPin-link"
				disabled={isSetPINButtonDisable}
				onClick={handleContinue}
			>
				{setPin.btn.setPin}
			</InputButton>
		</Layout>
	);
};
export default SetPin;
