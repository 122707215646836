import { FC } from 'react';
import { Link } from 'react-router-dom';
import withSkeltonLoader from '../../hoc/SkeltonLoader';
import { IRestaurantItemProps, LoaderType } from '../../types';
import './RestaurantItem.scss';
import { ComingSoonText } from '../../service/experienceService';
const RestaurantItem: FC<IRestaurantItemProps> = ({
	img,
	title,
	secondaryTitle,
	redirectTo,
	external = false
}) => {
	const imageURL = img && `url(${img})`;

	const getItem = () => {
		return (
			<div
				className="restaurant-item"
				style={{
					backgroundImage: imageURL
				}}
			>
				<div className="restaurant-item_text_content">
					{!redirectTo ? (
						<span className="coming-soon icon-ribbon">
							<i className="icon-timer"></i>
							{ComingSoonText}
						</span>
					) : null}
					<div className="restaurant-item_title_link">
						<h2 className="headline-medium">{title}</h2>
					</div>
					{secondaryTitle && (
						<p className="restaurant-item_seconday_title small1">{secondaryTitle}</p>
					)}
				</div>
			</div>
		);
	};
	return (
		<div data-testid="testid-restaurant-item" className="restaurant-parent">
			{external ? (
				<a href={redirectTo} target="_blank" rel="noreferrer" data-testid="icon-button-link">
					{getItem()}
				</a>
			) : (
				<Link to={redirectTo} data-testid="icon-button-link">
					{getItem()}
				</Link>
			)}
		</div>
	);
};
export default withSkeltonLoader(RestaurantItem, LoaderType.restaurant);
