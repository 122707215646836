import { ReactNode } from 'react';
import { LoaderType } from '../../types';
import './Loader.scss';

const Loader = ({
	loaderType,
	blockCount = 3
}: {
	loaderType?: LoaderType;
	blockCount?: number;
}) => {
	const generateBlocks = (n: number) => {
		const content: ReactNode[] = [];
		for (let i = 0; i < n; i++) {
			content.push(<a className="skeleton grid-block" key={i}></a>);
		}
		return content;
	};

	const renderLoader = () => {
		switch (loaderType) {
			case LoaderType.grid:
				return (
					<div data-testid="testid-loader" className="grid">
						{generateBlocks(blockCount)}
					</div>
				);
			case LoaderType.banner:
				return <div data-testid="testid-loader" className="banner skeleton"></div>;
			case LoaderType.bannerSecondary:
				return <div data-testid="testid-loader" className="banner banner-secondary skeleton"></div>;
			case LoaderType.promo:
				return (
					<div data-testid="testid-loader" className="loading-container">
						<div className="promo skeleton"></div>
						<div className="promo skeleton"></div>
					</div>
				);
			case LoaderType.gallery:
				return <div data-testid="testid-loader" className="hotel_gallery skeleton"></div>;
			case LoaderType.profileBanner:
				return <div data-testid="testid-loader" className="profile-banner skeleton"></div>;
			case LoaderType.offerBanner:
				return (
					<div data-testid="testid-loader" className="offer-banner section-heading skeleton"></div>
				);
			case LoaderType.title:
				return <div data-testid="testid-loader" className="title section-heading skeleton"></div>;
			case LoaderType.restaurant:
				return <div data-testid="testid-loader" className="restaurant skeleton"></div>;
			case LoaderType.experience:
				return (
					<>
						<div data-testid="testid-loader" className="restaurant skeleton"></div>
						<div data-testid="testid-loader" className="grid">
							{generateBlocks(blockCount)}
						</div>
						<div data-testid="testid-loader" className="restaurant skeleton"></div>
						<div data-testid="testid-loader" className="grid">
							{generateBlocks(blockCount)}
						</div>
						<div data-testid="testid-loader" className="restaurant skeleton"></div>
						<div data-testid="testid-loader" className="grid">
							{generateBlocks(blockCount)}
						</div>
					</>
				);
			case LoaderType.rewardTier:
				return (
					<div data-testid="testid-loader">
						<div className="reward-tier skeleton"></div>
						<div className="reward-tier skeleton"></div>
						<div className="reward-tier skeleton"></div>
					</div>
				);
			case LoaderType.rewardCard:
				return <div className="reward-card skeleton" data-testid="testid-loader"></div>;
			case LoaderType.offerTile:
				return (
					<div data-testid="testid-loader" className="offers-skeleton">
						<div className="title section-heading skeleton"></div>
						<div className="portait-tile-row">
							<div className="portait-tile skeleton"></div>
							<div className="portait-tile skeleton"></div>
							<div className="portait-tile skeleton"></div>
						</div>
					</div>
				);
			default:
				return <div data-testid="testid-loader" className="banner skeleton"></div>;
		}
	};

	return <>{renderLoader()}</>;
};

export default Loader;
