import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '../../constants';
import { exclusiveOfferImage } from '../../service/appService';
import { viewOfferLabel } from '../../service/lobbyService';
import { LoaderType } from '../../types';
import withSkeltonLoader from '../../hoc/SkeltonLoader';
import './OfferBanner.scss';
import { useCallback, useEffect, useState } from 'react';
import { useCongnitoUser, useGlobalContext } from '../../hooks';

const OfferBanner = () => {
	const { congnitoIsAuthenticated } = useCongnitoUser();
	const { user } = useGlobalContext();
	const history = useHistory();
	const [isDisabled, setDisabled] = useState<boolean>(true);

	useEffect(() => {
		if (!user?.isLoading) {
			if (congnitoIsAuthenticated && !user?.globalId) {
				setDisabled(true);
			} else {
				setDisabled(false);
			}
		} else {
			setDisabled(true);
		}
	}, [user?.isLoading, user?.globalId]);

	const handleButtonClick = useCallback(() => {
		if (!user?.isLoading) {
			if (congnitoIsAuthenticated && user?.globalId) {
				return history.push(`${ROUTE_CONSTANTS.PROFILE.LANDING}?target=offers`);
			} else {
				return history.push(ROUTE_CONSTANTS.OPTIN);
			}
		}
	}, [user?.isLoading, user?.globalId]);

	return (
		<button
			className={`offer-button caption2 ${isDisabled ? 'disabled' : ''}`}
			onClick={handleButtonClick}
			data-testid="offer-banner"
		>
			<img src={`${process.env.PUBLIC_URL + exclusiveOfferImage}`} alt={viewOfferLabel} />
			{viewOfferLabel}
			<i className="icon-arrow-head" />
		</button>
	);
};
export default withSkeltonLoader(OfferBanner, LoaderType.offerBanner);
