import { API_METHOD, API_VERSION, getRequestOptions } from '../constants';
import { IGetVerifyOtp, IGetVerifyPin } from '../types';
import errorNote from '../assets/ico_Error.svg';
import accountLocked from '../assets/ico_Account_Locked.svg';
import accountNotFoundAndExcluded from '../assets/ico_Acount_Not_Found.svg';
import faceIdIcon from '../assets/faceid.svg';
import biometricIcon from '../assets/biometric.svg';

const { REACT_APP_USER_SERVICE_BASE_URL, REACT_APP_X_API_KEY } = process.env;

export const PRIVACY_LINK = 'https://casinos.ballys.com/chicago/privacy-policy.htm';
export const T_AND_C_LINK = 'https://casinos.ballys.com/chicago/terms-of-use.htm';
export const playerIdMaxlength = 11;
export const OTP_ACTION = {
	SEND_OTP: 'SEND_OTP',
	VERIFY_OTP: 'VERIFY_OTP',
	REFRESH_TOKEN: 'REFRESH_TOKEN'
};

export const loginVaribages = {
	login: {
		errorMsgEnterno: 'Please enter mobile number.',
		invalidno: 'Please enter a valid mobile number.',
		welcomeBack: 'Welcome Back!',
		subHeading: 'Enter the details to get curated rewards and personalised recommendations',
		disclaimer: 'By using Bally’s Rewards App, you agree to our ',
		TCtext: 'Terms & Conditions',
		and: ' and ',
		privacy: 'Privacy Policy',
		continueWith: 'Or continue with',
		btn: {
			Continue: 'Continue',
			Skip: 'Skip for now',
			faceID: {
				android: {
					text: 'Use Biometrics',
					icon: 'icon-biometric'
				},
				ios: {
					text: 'Use Face ID',
					icon: 'icon-faceid'
				}
			}
		}
	},
	verifySsn: {
		heading: 'SSN Verification',
		subHeading:
			'The last four digits are required for verification purposes only. This information is not stored.',
		inputHeading: 'Enter Last 4 digits of SSN',
		maskingValue: 'XXX - XX - ',
		btn: {
			Continue: 'Continue'
		},
		validation_error: {
			required: 'Required Field',
			invalid: 'Invalid SSN'
		},
		errorPopup: {
			desc_heading: 'SSN Validation Error',
			icon: errorNote,
			description: 'Not eligible for mobile registration',
			positiveButtonText: 'OK',
			desc_text_style: 'body-text-medium'
		}
	},
	verifyOtp: {
		otpVerification: 'OTP Verification',
		didntrecevied: 'Didn’t recieve a code?',
		resendIn: 'Resend in ',
		resendOtp: 'Resend SMS',
		enterFourDigit: 'Please enter the 4 digit code sent to ',
		throughSMS: 'through SMS',
		errorMsg: 'Please enter valid OTP.',
		invalidOtpRetry: 'The OTP entered is incorrect. Please try again.',
		btn: {
			Continue: 'Continue'
		},
		exclusionPopup: {
			desc_heading: 'Account Conflict - Access Denied',
			description:
				'This account information has been flagged as an Illinois Self Excluded Patron which restricts access to all Gaming communications. If you believe this is a mistake or have any questions please contact Illinois Gaming Board (IGB) for assistance.',
			positiveButtonText: 'OK',
			icon: accountNotFoundAndExcluded,
			desc_text_style: 'body-text-medium'
		},
		errorPopup: {
			desc_heading: 'Error: Incorrect Information',
			icon: errorNote,
			description: '',
			positiveButtonText: 'OK',
			desc_text_style: 'body-text-medium'
		}
	},
	verifyInfo: {
		heading: 'Verify your information',
		description: 'You can edit the details, so that the information is updated.',
		btn: {
			continue: 'Confirm'
		}
	},
	pinVerification: {
		verify_heading: 'Enter your PIN',
		enter_pin_subheading: 'Verify with your PIN before making any changes.',
		heading: 'Link your account',
		subHeading: `Enter PIN for Player ID `,
		subHeadingVerify: `to proceed with linking your account.`,
		multipleIdHeading: `Multiple player IDs found for your driver's license. Enter the 'correct' Player ID to link your account.`,
		dont_know_id: `Don't know Player ID? `,
		clickHere: `Get Player ID`,
		accountPin: 'Account PIN',
		btn: {
			Continue: 'Verify'
		},
		playerIDError: 'Please enter Player ID.',
		errorMsg: 'Please enter valid PIN.',
		remainingAttemptMsg: 'more attempt(s) remaining before the account is locked.',
		errorPopup: {
			desc_heading: 'Account Locked',
			icon: accountLocked,
			description:
				'Your account has been temporarily locked. Please visit the Players Club to reset your PIN.',
			positiveButtonText: 'OK',
			desc_text_style: 'body-text-medium',
			sub_desc: {
				phone_no: '(888) 822-2559',
				loc: '600 N Wabash Ave, Chicago, IL 60611'
			}
		},
		getPlayerIdPopup: {
			desc_heading: 'Get Player ID',
			icon: accountNotFoundAndExcluded,
			description: 'Please visit the Player Club to get your Player ID',
			positiveButtonText: 'OK',
			desc_text_style: 'body-text-medium',
			sub_desc: {
				phone_no: '(888) 822-2559',
				loc: '600 N Wabash Ave, Chicago, IL 60611'
			}
		}
	},
	faceIdSetupError: {
		android: {
			desc_heading: 'Interested in using Biometric?',
			icon: biometricIcon,
			description:
				'To get started, please log in to your account and enable this feature in the “More” section.',
			positiveButtonText: 'Ok',
			desc_text_style: 'body-text-medium'
		},
		ios: {
			desc_heading: 'Interested in using Face ID?',
			icon: faceIdIcon,
			description:
				'To get started, please log in to your account and enable this feature in the “More” section.',
			positiveButtonText: 'Ok',
			desc_text_style: 'body-text-medium'
		}
	},
	loginOption: {
		heading: 'Choose an Option',
		description:
			"Decide whether you'd like to register for a new account or link your existing one.",
		registerOption: 'Register for a new account',
		linkAccOption: 'Link an existing account'
	},
	KBAQuestion: {
		heading: 'Confirm your identity',
		subHeading: `You have 10 minutes to answer these questions which are used only for verification purposes. If the question is not relevant to you, you can select "None of the above" option.`
	},
	setPin: {
		heading: 'Set your 4-digit PIN code',
		subHeading: 'You will be prompted to enter this PIN whenever you login to this application',
		enterPin: 'Enter PIN',
		confirmPin: 'Confirm PIN',
		btn: {
			setPin: 'Set PIN'
		}
	}
};
export const getOtp = async (data: IGetVerifyOtp, siteCode: string) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}otp?siteCode=${siteCode}`, {
		...getRequestOptions(data),
		method: API_METHOD.POST
	})
		.then(async (res) => {
			if (!(res?.status == 200 || res?.status == 403)) {
				const response = await res.json();
				return { message: response, currStatus: res.status };
			} else {
				const response = await res.json();
				// >> whether it is getOTP response >> ChallengeParameters
				// >> Or it is verifyOTP response >> userInfo
				if (response.userInfo || response.ChallengeParameters) {
					return { ...response, currStatus: res.status };
				}
				return { currStatus: res.status };
			}
		})
		.catch((error) => {
			console.log('error: ', error);
			throw new error(error);
		});
};

export const verifyPinAndLinkAccount = async (data: IGetVerifyPin, accessToken: string) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}onboard/link-player-account`, {
		method: API_METHOD.POST,
		headers: {
			'Content-Type': 'application/json',
			'x-api-key': REACT_APP_X_API_KEY ?? '',
			Authorization: `Bearer ${accessToken}`
		},
		body: data ? JSON.stringify(data) : null
	})
		.then(async (res) => {
			const response: any = await res.json();
			return { currStatus: res.status, ...response };
		})
		.catch((error) => {
			console.log('error: ', error);
			throw new error(error);
		});
};

export const parseJwt = (token: string) => {
	const base64Url = token?.split('.')[1];
	const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);
	return JSON.parse(jsonPayload);
};

export const confirmIdDetails = async (
	accessToken: string,
	globalId: string,
	data: any,
	siteCode: string
) => {
	return fetch(
		`${REACT_APP_USER_SERVICE_BASE_URL}users/updateprofile/${globalId}?siteCode=${siteCode}`,
		{
			method: API_METHOD.PUT,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				version: API_VERSION,
				'x-api-key': REACT_APP_X_API_KEY ?? ''
			},
			body: data ? JSON.stringify(data) : null
		}
	)
		.then(async (res) => {
			if (res?.status == 200 || res?.status == 400) {
				const response = await res.json();
				return { response: response, currStatus: res.status };
			}
			return res.json();
		})
		.catch((error) => {
			console.log('error: ', error);
			throw new error(error);
		});
};

export const VerifySSN = async (data: any, accessToken: string) => {
	return fetch(`${REACT_APP_USER_SERVICE_BASE_URL}onboard/submit-additional-info`, {
		...getRequestOptions(data),
		method: API_METHOD.POST,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
			version: API_VERSION,
			'x-api-key': REACT_APP_X_API_KEY ?? ''
		},
		body: data ? JSON.stringify(data) : null
	})
		.then(async (res) => {
			if (res?.status == 200) {
				const response = await res.json();
				return { ...response, currStatus: res.status };
			} else {
				const response = await res.json();
				return { message: response, currStatus: res.status };
			}
		})
		.catch((error) => {
			console.log('error: ', error);
			throw new error(error);
		});
};
