import './ProfileTierBanner.scss';
import { useGlobalContext, useRNContext } from '../../hooks';
import { useEffect, useMemo } from 'react';

export const ConnectionCard = ({ setIsFlipped }: { setIsFlipped: (value: boolean) => void }) => {
	const { connectionStatus } = useRNContext();
	const { user } = useGlobalContext();
	useEffect(() => {
		if (connectionStatus == 'DISCONNECTED') {
			setIsFlipped(false);
		} else if (connectionStatus == 'CONNECTED') {
			setTimeout(() => {
				setIsFlipped(false);
			}, 1000);
		}
	}, [connectionStatus]);

	const getConnectionClass = (value: string) => {
		switch (value) {
			case 'CONNECTING':
				return { connectionClass: 'connecting', icon: 'icon-bluetooth', text: 'Connecting...' };
			case 'DISCONNECTING':
				return { connectionClass: 'disconnecting', icon: 'icon-bluetooth', text: 'Disconnecting' };
			case 'CONNECTED':
				return { connectionClass: 'connected', icon: 'icon-tick', text: 'Connected' };
			default:
				return { connectionClass: 'connecting', icon: 'icon-bluetooth', text: 'Connecting...' };
		}
	};
	const getCardConnectionStatus = useMemo(() => {
		return getConnectionClass(connectionStatus);
	}, [connectionStatus]);

	const rows = [];
	for (let i = 0; i < 12; i++) {
		const k = `circle-${i}`;
		rows.push(<span key={k} className={`${getCardConnectionStatus.connectionClass}`} />);
	}

	return (
		<div
			className="card__face card__face--back"
			data-testid={`test-${getCardConnectionStatus.connectionClass}`}
		>
			<div className={`player-tier-card ${user?.isLoading ? '' : user?.tier?.toLowerCase()}`}>
				<div className={`box-connection ${getCardConnectionStatus.connectionClass}`}>
					<i
						className={`icon-connection ${getCardConnectionStatus.icon} ${getCardConnectionStatus.connectionClass}`}
					></i>
					<p className="connection-status-text body-text-medium">{getCardConnectionStatus.text}</p>
					{rows}
				</div>
			</div>
		</div>
	);
};
