import { IDisclaimerProps, ILink } from '../types';
import jsonp from 'fetch-jsonp';
import {
	API_BASE_URL,
	GET_DISCLAIMERTEXT,
	GET_DISCLAIMER_LOGO,
	GET_DISCLAIMER_LOGO_LIGHT_THEME
} from '../constants/endPoints';
import { getCookieValue } from '../hooks';
import { PLATFORM_COOKIE } from '../constants';

const currPlatform = getCookieValue(PLATFORM_COOKIE).toLowerCase();
export const getDisclaimerInformation = (): Promise<IDisclaimerProps> => {
	return jsonp(`${API_BASE_URL}/${GET_DISCLAIMERTEXT}.js`, {
		jsonpCallbackFunction: `${GET_DISCLAIMERTEXT}DataCallback`
	}).then((response) => {
		return response.json();
	});
};
export const getDisclaimerImage = (): Promise<IDisclaimerProps> => {
	return jsonp(`${API_BASE_URL}/${GET_DISCLAIMER_LOGO}.js`, {
		jsonpCallbackFunction: `${GET_DISCLAIMER_LOGO}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IDisclaimerProps>;
	});
};
export const getDisclaimerImage_LightTheme = (): Promise<IDisclaimerProps> => {
	return jsonp(`${API_BASE_URL}/${GET_DISCLAIMER_LOGO_LIGHT_THEME}.js`, {
		jsonpCallbackFunction: `${GET_DISCLAIMER_LOGO_LIGHT_THEME}DataCallback`
	}).then((response) => {
		return response.json() as Promise<IDisclaimerProps>;
	});
};
export const getHelpLinks = (
	isAuthenticated = false,
	showFaceIDFeature = false,
	showLightThemeFeature = false
): ILink[] => {
	const helpArray: ILink[] = [
		{
			to: 'https://casinos.ballys.com/chicago/contact.htm',
			external: true,
			icon: 'icon-phone-email',
			label: 'Contact'
		},
		{
			to: 'https://casinos.ballys.com/chicago/responsible-gaming.htm',
			external: true,
			icon: 'icon-casino-chip',
			label: 'Responsible Gaming'
		},
		{
			to: 'https://casinos.ballys.com/chicago/faqs.htm',
			external: true,
			icon: 'icon-faq',
			label: 'FAQs'
		},
		{
			to: 'https://casinos.ballys.com/chicago/careers.htm',
			external: true,
			icon: 'icon-career',
			label: 'Careers'
		},
		{
			to: 'https://casinos.ballys.com/chicago/about-ballys.htm',
			external: true,
			icon: 'icon-about-bally',
			label: 'About Bally’s'
		}
	];

	showLightThemeFeature &&
		helpArray?.splice(helpArray.length, 0, {
			to: 'theme',
			external: true,
			icon: 'icon-theme',
			label: 'Use Light Mode',
			componentType: 'checkbox'
		});

	isAuthenticated &&
		helpArray?.splice(helpArray.length, 0, {
			to: 'logout',
			external: true,
			icon: 'icon-logout',
			label: 'Logout'
		});

	isAuthenticated &&
		showFaceIDFeature &&
		helpArray?.splice(helpArray.length - 1, 0, {
			to: 'faceid',
			external: true,
			icon: currPlatform == 'android' ? 'icon-biometric' : 'icon-faceid',
			label: currPlatform == 'android' ? 'Biometrics' : 'Face ID',
			componentType: 'checkbox'
		});

	return helpArray;
};

export const getLegalLinks = (): ILink[] => [
	{
		to: 'https://casinos.ballys.com/chicago/cookie-policy.htm',
		external: true,
		icon: 'icon-cookie',
		label: 'Cookie Policy'
	},
	{
		to: 'https://casinos.ballys.com/chicago/privacy-policy.htm',
		external: true,
		icon: 'icon-privacy',
		label: 'Privacy Policy'
	},
	{
		to: 'https://casinos.ballys.com/chicago/terms-of-use.htm',
		external: true,
		icon: 'icon-terms',
		label: 'Terms & Conditions'
	}
];

export const getOtherLinks = (isAuthenticated = false) => {
	return isAuthenticated
		? [
				{
					to: 'delete',
					external: true,
					icon: 'icon-delete',
					className: 'listing-red',
					label: 'Delete Account'
				}
		  ]
		: [];
};

export const moreLogoData = {
	logo: {
		url: '/assets/logo.png'
	},
	metaData: {
		label: 'Formerly MontBleu Resort Casino & Spa'
	},
	appVersion: {
		label: 'App Version'
	},
	appVersionLogo: {
		url: '/assets/logo-white.png'
	}
};
export const moreHeadingData = {
	help: {
		label: 'Help'
	},
	legal: {
		label: 'Legal'
	},
	other: {
		label: 'Other'
	}
};

export const emailLabel = {
	title: {
		label: 'Sign up for emails'
	},
	subtitle: {
		label: 'Join our email list to receive special offers.'
	},
	submitIcon: {
		label: 'icon-tick'
	}
};
