import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ILink } from '../../types';
import './IconButton.scss';

const IconButton: FC<ILink> = ({ label, className = '', icon, external = false, to }) => {
	const getLink = () => {
		if (!to) {
			return (
				<span
					data-testid="icon-button-link"
					className={`${icon} icon-button caption2 ${className}`}
				>
					<div className="icon-label">{label}</div>
				</span>
			);
		}

		if (external) {
			return (
				<a
					target="_blank"
					rel="noreferrer"
					data-testid="icon-button-anchor"
					href={to}
					className={`${icon} icon-button caption2 ${className}`}
				>
					<div className="icon-label">{label}</div>
				</a>
			);
		} else {
			return (
				<Link
					data-testid="icon-button-link"
					to={to || '/'}
					className={`${icon} icon-button caption2 ${className}`}
				>
					<div className="icon-label">{label}</div>
				</Link>
			);
		}
	};

	return (
		<div className="grid-icon">
			{!to && <span className="coming-soon-badge">Coming Soon</span>}
			{getLink()}
		</div>
	);
};
export default IconButton;
