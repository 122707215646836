import { MobileNumber } from '../../components/InputField/MobileNumber';
import { EmailInput } from '../../components/InputField/EmailInput';
import InputButton from '../../components/InputField/InputButton';
import { useHistory } from 'react-router-dom';
import { MinMobileDigit, PLATFORM_COOKIE, ROUTE_CONSTANTS } from '../../constants';
import { OTP_ACTION, getOtp, loginVaribages } from '../../service/loginService';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
	getCookieValue,
	useCurrentProperty,
	useGlobalContext,
	useLDContext,
	useRNContext
} from '../../hooks';
import PopupDrawer from '../../components/PopupDrawer';
import { Ipopup } from '../../types';

const LoginForm = ({ IsLoginWithEmail }: { IsLoginWithEmail: boolean }) => {
	const { isFaceIDFeatureAvailable } = useLDContext();
	const { setUserDataInContext, user } = useGlobalContext();
	const { login, faceIdSetupError } = loginVaribages;
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const currentProperty = useCurrentProperty();
	const { isFaceIdAvailable, isFaceIdSetup, postMessagetoNative } = useRNContext();
	const [showFaceIdModal, setShowFaceIdModal] = useState(false);
	const [modalState, setmodalState] = useState<Ipopup>({
		positiveButtonText: '',
		description: '',
		positiveCallback: () => {
			//
		}
	});
	const currPlatform = getCookieValue(PLATFORM_COOKIE).toLowerCase();
	const formInput = useRef<HTMLFormElement>(null);

	const handleCloseModal = useCallback(() => {
		setShowFaceIdModal(false);
		formInput.current?.querySelector('input')?.focus();
	}, [formInput.current]);

	useEffect(() => {
		setmodalState({
			...(currPlatform == 'android' ? faceIdSetupError.android : faceIdSetupError.ios),
			positiveCallback: handleCloseModal,
			negativeCallback: handleCloseModal
		});
	}, []);

	const handleContinue = async (event: any) => {
		event.preventDefault();

		if (!event?.target?.elements?.identifier?.value) {
			setErrorMsg(login.errorMsgEnterno);
			return;
		} else if (event?.target?.elements?.identifier?.value?.length < MinMobileDigit) {
			setErrorMsg(login.invalidno);
			return;
		}
		setIsLoading(true);
		try {
			const res = await getOtp(
				{
					action: OTP_ACTION.SEND_OTP,
					phoneNumber: `${event?.target?.countryCode?.id}${event.target.elements.identifier.value}`
				},
				currentProperty.siteCode
			);
			setIsLoading(false);
			if (res.currStatus == 200) {
				history.push({
					pathname: ROUTE_CONSTANTS.LOGIN_CONGNITO.VERIFY_OTP,
					state: {
						mobileNumber: `${event.target.countryCode.id} ${event.target.elements.identifier.value}`,
						ChallengeParameters: res?.ChallengeParameters,
						Session: res?.Session
					}
				});
			} else {
				setErrorMsg(res.message || res);
			}
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	const handleSkipClick = useCallback(() => {
		history.push(ROUTE_CONSTANTS.LOBBY);
	}, [history]);

	const handleLoginFaceIdClick = useCallback(() => {
		if (isFaceIdSetup) {
			//Post msg to native for login via faceid
			postMessagetoNative('FACEID_LOGIN');
			setUserDataInContext({
				...user,
				isLoading: true
			});
		} else {
			setShowFaceIdModal(true);
		}
	}, [isFaceIdSetup, postMessagetoNative]);

	useEffect(() => {
		//auto login face id or biometric
		if (isFaceIdSetup && isFaceIdAvailable && isFaceIDFeatureAvailable) {
			setUserDataInContext({
				...user,
				isLoading: true
			});
			postMessagetoNative('FACEID_LOGIN');
		}
	}, [isFaceIdSetup, isFaceIdAvailable, isFaceIDFeatureAvailable]);

	const generateFaceIdButton = useCallback(() => {
		if (isFaceIdAvailable) {
			return (
				<>
					<div className="seperator">
						<div className="seperator-line" />
						<p className="seperator-text body-text-small prominant">{login.continueWith}</p>
						<div className="seperator-line" />
					</div>
					<InputButton varient="secondary" type="button" onClick={handleLoginFaceIdClick}>
						<i
							className={
								currPlatform == 'android'
									? login.btn.faceID.android.icon
									: login.btn.faceID.ios.icon
							}
						></i>
						<span className="face-id-text">
							{currPlatform == 'android'
								? login.btn.faceID.android.text
								: login.btn.faceID.ios.text}
						</span>
					</InputButton>
				</>
			);
		}
	}, [isFaceIdAvailable, handleLoginFaceIdClick]);

	return (
		<>
			<div className="form-section" data-testid="login-form">
				<h2 className="form-heading headline-medium">{login.welcomeBack}</h2>
				<p className="form-sub-heading body-text-medium">{login.subHeading}</p>
				<form noValidate onSubmit={handleContinue} ref={formInput}>
					{IsLoginWithEmail ? (
						<EmailInput />
					) : (
						<MobileNumber errorMsg={errorMsg} setErrorMsg={setErrorMsg} isLoading={isLoading} />
					)}
					<p className="error-msg" data-testid="error-msg">
						{errorMsg}
					</p>
					<InputButton varient="primary" type="submit" disabled={isLoading} datatestid="submit">
						{login.btn.Continue}
					</InputButton>
					{isFaceIDFeatureAvailable && generateFaceIdButton()}
					{/* <div className="login-options">
						{IsLoginWithEmail ? (
							<InputButton varient="secondary">Mobile</InputButton>
						) : (
							<InputButton varient="secondary">Player/Email ID</InputButton>
						)}
					</div> */}
				</form>
				<div className="skip-container">
					<span onClick={handleSkipClick} className="skip-login" data-testid="skip-btn">
						{login.btn.Skip}
					</span>
				</div>
			</div>
			{showFaceIdModal && (
				<PopupDrawer
					errorImage={
						<div className="error-icon-box">
							<i
								className={`icon-modal-view ${modalState.icon}`}
								style={{ backgroundImage: `url(${modalState.icon})` }}
							/>
						</div>
					}
					descHeading={
						<h2 className="popup-sub-header-text headline-small">{modalState.desc_heading}</h2>
					}
					data={modalState}
					handleNegativeButton={modalState.negativeCallback}
					handlePositiveButton={modalState.positiveCallback}
				/>
			)}
		</>
	);
};
export default LoginForm;
