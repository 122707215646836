import { useGlobalContext } from '../../hooks';
import './RewardCard.scss';
import { useMemo } from 'react';
import { getNextTierData } from '../../constants';

const RewardCard = () => {
	const { user } = useGlobalContext();
	const dataToDisplay = useMemo(() => {
		const points = parseInt(user?.points ?? '0');
		const targetPoints = points + parseInt(user?.pointsToNextTier ?? '1');
		const currentTier = user?.tier?.trim().toLowerCase();
		const nextTierData = getNextTierData[currentTier ?? ''];

		return {
			playerId: user?.playerId,
			points,
			targetPoints,
			circularBar: `radial-gradient(closest-side,var(--black) 89%,transparent 90% 100%),conic-gradient(${
				nextTierData?.color
			} ${(points / targetPoints) * 100}%,transparent 0)`,
			icon: nextTierData?.icon
		};
	}, [user]);

	return !user?.isLoading ? (
		<>
			<span className="card-number">{dataToDisplay.playerId}</span>
			<div className="tier-container-card" data-testid="rewards-testid">
				<div className="circle progress-bar">
					{dataToDisplay.icon ? (
						<i className="tierBadges" style={{ backgroundImage: `url(${dataToDisplay.icon})` }} />
					) : null}
				</div>
				<div className="tier-details h-center">
					<p>Next Tier</p>
					<p className="tier-value">
						{dataToDisplay.points ? (
							<>
								{dataToDisplay.points}
								<span> {`/${dataToDisplay.targetPoints}`}</span>
							</>
						) : null}
					</p>
				</div>
			</div>
		</>
	) : null;
};
export default RewardCard;
