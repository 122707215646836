import { useEffect, useMemo, useState } from 'react';
import {
	getLobbyGridItems,
	getRewardsData,
	getHomeRails,
	getOpeningData,
	lobbyVerbiage
} from '../../service/lobbyService';
import { ILink, IOpeningData, IPromotionDetail, IRewardsCards, Rails } from '../../types';
import './Lobby.scss';
import Grid from '../../components/Grid/Grid';
import Carousel from '../../components/Carousel/Carousel';
import Layout from '../../hoc/Layout';
import CountdownTimer from '../../components/CountDown';
import ProfileTierBanner from '../../components/ProfileTierBanner/ProfileTierBanner';
import BucksBalance from '../../components/ProfileTierBanner/BucksBalance';
import { useCongnitoUser, useGlobalContext } from '../../hooks';
import OfferBanner from '../../components/OfferBanner/OfferBanner';

const Lobby = () => {
	const { promotionHeading } = lobbyVerbiage;
	const [homeRails, setHomeRails] = useState<IPromotionDetail[]>([]);
	const [rewardsCards, setRewardsCard] = useState<IRewardsCards[]>([]);
	const [openingDetails, setOpeningDetails] = useState<IOpeningData>();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const gridItems: ILink[] = useMemo<ILink[]>(() => getLobbyGridItems(), []);
	const { user } = useGlobalContext();
	const { congnitoIsAuthenticated } = useCongnitoUser();
	useEffect(() => {
		getHomeRailsData();
		getRewards();
		getOpeningDetails();
	}, []);

	useEffect(() => {
		if (homeRails.length > 0 && gridItems.length > 0 && rewardsCards.length > 0)
			setIsLoading(false);
	}, [homeRails, gridItems, rewardsCards]);

	const getOpeningDetails = async () => {
		try {
			const response: { Slides: IOpeningData[] } = await getOpeningData();
			if (response?.Slides?.length) {
				setOpeningDetails(response?.Slides[0]);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getRewards = async () => {
		try {
			const response: { Slides: IRewardsCards[] } = await getRewardsData();
			if (response?.Slides?.length) {
				setRewardsCard(response?.Slides);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getHomeRailsData = async () => {
		try {
			const response: Rails = await getHomeRails();
			if (response?.Slides?.length) {
				setHomeRails(
					response?.Slides.map((item) => {
						return {
							CTAHeading: item.SlideHeading,
							CTAButtons: [
								{
									CTAButtonButtonLink: item.SlideImageLink
								}
							],
							CTAAltText: item.SlideHeading,
							CTAImage: {
								FullURL: item.SlideImage.FullURL,
								ThumbURL: item.SlideImage.ThumbURL,
								RecordId: item.SlideImage.RecordId
							},
							CTACopy: item.SlideHeading
						};
					})
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// page level class on div
	return (
		<Layout className="page-home" dataTestid="testid-lobby">
			{congnitoIsAuthenticated && <BucksBalance loading={user?.isLoading} />}
			<div>
				<ProfileTierBanner profileCard={rewardsCards} loading={user?.isLoading} />
			</div>
			<div className="middle-container">
				<div className="section-gap">
					<Grid variant="secondary" links={gridItems} loading={isLoading} blockCount={3} />
				</div>
			</div>

			<div className="offers section-gap">
				<OfferBanner loading={isLoading} />
			</div>

			{/* WE ARE USING SlideOptionalSlideClass FOR FEEDING DATE TO COMPONENT */}
			{openingDetails && (
				<CountdownTimer
					targetDate={openingDetails.SlideOptionalSlideClass}
					openingDetails={openingDetails}
				/>
			)}

			<div className="section-gap">
				<h3 className="title-large">{promotionHeading}</h3>
				<div className="carousel-container">
					<Carousel slides={homeRails} loading={isLoading} />
				</div>
			</div>
		</Layout>
	);
};

export default Lobby;
