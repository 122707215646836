import { useState, useLayoutEffect, useEffect, useMemo } from 'react';
import { IChildrenHoc, Theme } from '../../types';
import { ThemeContext } from '../../context/GlobalContext';
import { checkThemeValue, saveThemeValue, useRNContext } from '../../hooks';

const ThemeProvider = ({ children }: IChildrenHoc) => {
	const { postMessagetoNative } = useRNContext();
	const [theme, setTheme] = useState<Theme>('dark');
	const getThemeValue = (theme: Theme) => {
		if (theme === 'light') {
			return 'dark';
		}
		return 'light';
	};

	const toggleTheme = (value?: Theme) => {
		console.log('Changed theme: ', value);
		if (!value) {
			value = getThemeValue(theme);
		}
		setTheme(value);
		saveThemeValue(value);
		postMessagetoNative('THEME_STATUS', {
			isDarkTheme: value != 'light'
		});
	};

	useEffect(() => {
		(() => {
			checkThemeValue().then((data) => {
				console.log('TP - Saved theme: ', data);
				if (data) {
					setTheme(data);
				}
			});
		})();
	}, []);

	useLayoutEffect(() => {
		console.log('TP - useLayoutEffect: ', theme);
		if (theme) {
			if (theme === 'light') {
				document.body.className = 'light-mode';
			} else if (theme === 'dark') {
				document.body.className = '';
			}
		}
	}, [theme]);

	const value = useMemo(() => {
		console.log('TP - useMemo: ', theme);
		return {
			theme,
			toggleTheme
		};
	}, [theme]);

	return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
