import { useCallback, useEffect, useState } from 'react';
import ProfileTierBanner from '../../components/ProfileTierBanner/ProfileTierBanner';
import ProfileTierCard from '../../components/ProfileTierCard/ProfileTierCard';
import { useCongnitoUser, useGlobalContext } from '../../hooks';
import { IRewardsCards } from '../../types';
import './Reward.scss';
import Layout from '../../hoc/Layout';
import { rewardLoggedLabel } from '../../service/rewardService';
import { getRewardsData } from '../../service/lobbyService';
import { ROUTE_CONSTANTS } from '../../constants';
import { Redirect } from 'react-router-dom';

const Rewards = () => {
	const [profileCard, setProfileCard] = useState<IRewardsCards[]>([]);
	const { user } = useGlobalContext();
	const [loading, setLoading] = useState<boolean>(true);
	const { isLoading, congnitoIsAuthenticated } = useCongnitoUser();
	useEffect(() => {
		if (profileCard.length > 0 && user?.tier) setLoading(false);
	}, [user?.tier, profileCard]);

	const getProfileTierCardlist = async () => {
		try {
			const response: { Slides: IRewardsCards[] } = await getRewardsData();
			if (response?.Slides?.length) {
				setProfileCard(response?.Slides);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const renderRewards = useCallback(() => {
		if (!isLoading) {
			if (congnitoIsAuthenticated) {
				return (
					<div className="reward-page">
						<div>
							<ProfileTierBanner profileCard={profileCard} loading={loading} />
						</div>
						<ProfileTierCard loading={loading} />
						<a
							className="learn-more label-medium"
							data-testid="testid-learn-more"
							target="_blank"
							rel="noreferrer"
							href={rewardLoggedLabel?.learnLabel?.link}
						>
							<p className="icon-info">{rewardLoggedLabel.learnLabel.label}</p>
							<i className="icon-arrow-head" />
						</a>
					</div>
				);
			} else {
				return <Redirect to={ROUTE_CONSTANTS.LOGIN_CONGNITO.LANDING} />;
			}
		} else {
			return <></>;
		}
	}, [isLoading, profileCard]);

	useEffect(() => {
		getProfileTierCardlist();
	}, []);

	return (
		<Layout className="page-reward" dataTestid="testid-profile">
			{renderRewards()}
		</Layout>
	);
};

export default Rewards;
