import Layout from '../../../hoc/Layout';
import './index.scss';
import InputButton from '../../../components/InputField/InputButton';
import { loginVaribages } from '../../../service/loginService';
import { useCallback, useEffect, useState } from 'react';
import { getKBAQuestion, verifyQuestion } from '../../../service/registerService';
import { useHistory, useLocation } from 'react-router';
import { RenderQuestionAndOption } from './RenderQuestionAndOption';
import { useCurrentProperty } from '../../../hooks';
import { ROUTE_CONSTANTS } from '../../../constants';
const KBAVerification = () => {
	const { KBAQuestion } = loginVaribages;
	const currentProperty = useCurrentProperty();
	const [payload, setPayload] = useState<{ [keys: string]: string }>({});
	const [quesTodisplay, setQuesToDisplay] = useState<any[]>([]);
	const [responseIQ, setResponseIQ] = useState<any>({});
	const history = useHistory();
	const location: {
		state: {
			globalId: '';
		};
	} = useLocation();
	useEffect(() => {
		handleKBAQuestion();
	}, []);
	const handleContinue = useCallback(async () => {
		try {
			const res = await verifyQuestion({
				globalId: location?.state?.globalId,
				idNumber: responseIQ.response['id-number'],
				...payload,
				siteCode: currentProperty.siteCode
			});
			if (res.userProfile) {
				const kycCompletionStatus = res?.userProfile.kycComplete as boolean;
				if (kycCompletionStatus) {
					history.push({
						pathname: ROUTE_CONSTANTS.LOGIN_CONGNITO.SET_PIN,
						state: {
							globalId: location.state.globalId
						}
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, [payload, responseIQ, currentProperty]);
	const setpayloadAndQuestion = (data: any) => {
		let newPayload = {};
		data.forEach((item: any, index: number) => {
			newPayload = {
				...newPayload,
				[`${'question' + (index + 1) + 'Type'}`]: item.type,
				[`${'question' + (index + 1) + 'Answer'}`]: ''
			};
		});
		setPayload(newPayload);
	};
	const handleKBAQuestion = async () => {
		if (location?.state?.globalId) {
			try {
				const res = await getKBAQuestion(location?.state?.globalId);
				setResponseIQ(res);
				setQuesToDisplay(res.response.questions.question);
				setpayloadAndQuestion(res.response.questions.question);
			} catch (err) {
				console.log(err);
			}
		}
	};
	const handleSelectAnswer = useCallback(
		(data: { quesId: string | undefined; label: string }) => {
			if (data.quesId) {
				setPayload({
					...payload,
					...{ [data.quesId]: data.label }
				});
			}
		},
		[payload]
	);
	return (
		<Layout className="login-choose-option" dataTestid="testid-login-option" noFooter={true}>
			<div>
				<div className="form-section">
					<h2 className="form-heading headline-medium">{KBAQuestion.heading}</h2>
					<p className="form-sub-heading body-text-medium">{KBAQuestion.subHeading}</p>
				</div>
			</div>
			<div className="question-container">
				<form>
					{quesTodisplay.map((item: any, index: number) => {
						return (
							<RenderQuestionAndOption
								data={item}
								key={'question' + item.type}
								handleSelectAnswer={handleSelectAnswer}
								payload={payload}
								questionIndex={index}
							/>
						);
					})}
				</form>
			</div>
			<InputButton varient="primary" datatestid="continue-btn" onClick={handleContinue}>
				{'Continue'}
			</InputButton>
		</Layout>
	);
};
export default KBAVerification;
